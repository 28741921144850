import { Injectable } from '@angular/core';
import { ToastController, ModalController} from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { ToastOptions } from '@ionic/core';
import { APP_CONFIG } from '../../../config/app.config';
import { ToastComponent } from '../../../app/components/toast/toast.component';
import { UtilsService } from '../utils/utils.service';
import { Router } from '@angular/router';
import { MODAL_ID_LIST } from 'src/config/enum.config';

@Injectable({
  providedIn: 'root'
})
export class ToastService {


  constructor(private toastCtrl: ToastController,
    private logger: NGXLogger,
    private modalCtrl: ModalController,
    private utilService: UtilsService,
    private router: Router ) {
    this.logger.debug('Hello ToastService Provider');
  }

  complementaryOptions = ['cssClass', 'showCloseButton', 'closeButtonText', 'dismissOnPageChange'];

  async presentToast(msg: string, infoType: string, options?) {

    this.logger.debug('ToastService: presentToast()');

    const durationParam = options && options.duration ? options.duration : APP_CONFIG.DEFAULT_TOAST_DURATION;
    const positionParam = options && options.position ? options.position :  APP_CONFIG.DEFAULT_TOAST_POSITION;
    const toastOptions: any = {
      message: msg,
      duration: durationParam,
      position: positionParam,
    };

    // set complementary options if any
    for (let ii = 0, jj = this.complementaryOptions.length; ii < jj; ii++) {
      const complOption = this.complementaryOptions[ii];
      if (options && options[complOption]) {
        toastOptions[complOption] = options[complOption];
      }
    }

    const toast = await this.modalCtrl.create({
      component: ToastComponent,
      componentProps: {message: msg,infoType},
      showBackdrop: false,
      backdropDismiss: true,
      cssClass: 'toast-modal',
      id: MODAL_ID_LIST[MODAL_ID_LIST.TOAST_ALERT_CREATE_PAGE_SUCCESS],
    });

    await toast.present().catch(err => this.logger.error(err));

    setTimeout(async () => await toast.dismiss().catch(err => this.logger.error(err)), durationParam);
  }

async presentToasts(msg: string[], infoType: string, options?: ToastOptions) {

  const durationParam = options && options.duration ? options.duration : APP_CONFIG.DEFAULT_TOAST_DURATION;
  const positionParam = options && options.position ? options.position :  APP_CONFIG.DEFAULT_TOAST_POSITION;
  const toastOptions: any = {
    message: msg,
    duration: durationParam,
    position: positionParam,
  };

  // set complementary options if any
  for (let ii = 0, jj = this.complementaryOptions.length; ii < jj; ii++) {
    const complOption = this.complementaryOptions[ii];
    if (options && options[complOption]) {
      toastOptions[complOption] = options[complOption];
    }
  }
  for (const i of (Object.keys(msg))) {
    toastOptions.message = msg[i];
    const next = Number(i) + 1;
    const nextMessage = msg[next];
    await this.present(toastOptions, infoType, nextMessage);
  }
}

async present(toastOptions: ToastOptions, infoType, nextToastMessage?) {
  return new Promise<any>(async (resolve, reject) => {

    const toast = await this.modalCtrl.create({
      component: ToastComponent,
      componentProps: {message: toastOptions.message, infoType},
      showBackdrop: false,
      backdropDismiss: true,
      cssClass: 'toast-modal'
    });

    await toast.present().catch(err => this.logger.error(err));

    setTimeout(async () => await toast.dismiss().catch(err => this.logger.error(err)), toastOptions.duration);

    await toast.onDidDismiss();
    toastOptions.message = nextToastMessage;
    if (nextToastMessage) {
      this.present(toastOptions, infoType).catch(err => this.logger.error(err));
    }
    resolve();

  });

  }

}
