import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MetadataService } from '../metadata/metadata.service';
import { Subject } from 'rxjs';
import { FilterSettings } from './model/filterSettings';
import { FilterStatus } from './model/filterStatuts';
import { SearchBarService } from '../search-bar/search-bar.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private _filterSettings: FilterSettings;
  private _filterStatus: FilterStatus;
  private _filterStatusDefault: FilterStatus;
  private _filterSettingsDefault: FilterSettings;
  private _alertFilterToggleStatus: boolean = undefined;

  constructor(public http: HttpClient,
              private logger: NGXLogger,
              private metadataService: MetadataService,
              private searchBarService: SearchBarService) {

   this.logger.debug('Hello FilterService Service');

   

  }

  _filterSettingsSource: Subject<FilterSettings> = new Subject();
  get filterSettingsSource(): Subject<FilterSettings> {
    return this._filterSettingsSource;
  }
  set filterSettingsSource(src: Subject<FilterSettings>) {
    this.filterSettingsSource = src;
  }

  setFilterSettings(filterSettings: FilterSettings) {
    this._filterSettings = filterSettings;
    this._filterSettingsSource.next(filterSettings);
  }

  getFilterSettings() {
    return this._filterSettings;
  }

  setFilterStatus(filterStatus: FilterStatus) {
    this._filterStatus = filterStatus;
  }

  getFilterStatus() {
    return this._filterStatus;
  }

  getFilterStatusDefault() {
    return this._filterStatusDefault;
  }

  getFilterSettingsDefault() {

    return this. _filterSettingsDefault;
  }
  getIsFilterDefault() {
    return JSON.stringify(this._filterStatusDefault) === JSON.stringify(this._filterStatus);
  }

  setDefaultFilterOptions() {
    this._filterStatus = this._filterStatusDefault;
    this._filterSettings = this._filterSettingsDefault;
  }

  initFilterService() {
    // Recuperer la configuration des filtres par default
    this._filterSettingsDefault = this.metadataService.getProviderVariableData('FilterService', '_filterSettingsDefault').value;
    this.logger.log("this._filterSettingsDefault", this._filterSettingsDefault);
    this._filterStatusDefault = this.metadataService.getProviderVariableData('FilterService', '_filterStatusDefault').value;

    // reset filter indicator
    this.searchBarService.updateShowFilterIndicator(false);
    
    this.setDefaultFilterOptions();
  }

  setAlertFilterToggle(status){
    this._alertFilterToggleStatus = status;
  }

  getAlertFilterToggle(){
    return this._alertFilterToggleStatus;
  }
}
