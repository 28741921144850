import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {

  title: string;
  message: string;
  typeAlert: string;
  iconName: string;
  customIconName: string;
  buttonOK: string;
  buttonKO: string;
  okHandler: Function;
  koHandler: Function;

  constructor(public navParams: NavParams,
              private logger: NGXLogger,
              public modalCtrl: ModalController
              ) {
    this.title = this.navParams.get('title');
    this.message = this.navParams.get('message');
    this.typeAlert = this.navParams.get('typeAlert');
    this.iconName = this.navParams.get('iconName');
    this.customIconName = this.navParams.get('customIconName');
    this.buttonOK = this.navParams.get('buttonOK');
    this.buttonKO = this.navParams.get('buttonKO');
    this.okHandler = this.navParams.get('okHandler');
    this.koHandler = this.navParams.get('koHandler');
  }

  ngOnInit(): void {
    this.logger.debug('AlertComponent#ngOnInit()');
  }

  clickKoHandler() {
    this.logger.log('AlertComponent >> Click Button KO');
    if (this.koHandler) {
      this.koHandler();
    } else {
      this.dismiss();
    }
  }

  clickOkHandler() {
    this.logger.log('AlertComponent >> Click Button OK');
    if (this.okHandler) {
      this.okHandler();
    } else {
      this.dismiss();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this.logger.debug('AlertComponent#ngOnDestroy()');
  }
}
