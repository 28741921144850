import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { ViewController } from '@ionic/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  message: string;
  infoType: string;

  constructor(public navParams: NavParams, public modalCtrl: ModalController, private logger: NGXLogger) {
    this.message = this.navParams.get('message');
    this.infoType = this.navParams.get('infoType');
    this.logger.log('infotype ', this.infoType);
  }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
