import { Directive, ElementRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import Hammer from 'hammerjs';

@Directive({
    selector: '[zoom-pan]',
})
export class ZoomPanDirective {
    private element: any;

    isZoomed: boolean;

    constructor(el: ElementRef,
                private logger: NGXLogger) {
        this.element = el.nativeElement;
        this.setZoomed(false);
        this.hammerIt(this.element);

    }

    private setZoomed(zoomed) {
        this.isZoomed = zoomed;
        this.element.setAttribute('zoomed', this.isZoomed);
    }

    private hammerIt(elm) {
        const hammertime = new Hammer(elm, {});
        hammertime.get('pinch').set({
            enable: true,
        });
        let posX = 0;
        let posY = 0;
        let scale = 1;
        let last_scale = 1;
        let last_posX = 0;
        let last_posY = 0;
        let max_pos_x = 0;
        let max_pos_y = 0;
        let transform = '';
        const el = elm;

        hammertime.on('doubletap pan pinch panend pinchend', ev => {
            if (ev.type === 'doubletap') {
                transform =
                    'translate3d(0, 0, 0) ' +
                    'scale3d(2, 2, 1) ';
                scale = 2;
                last_scale = 2;
                try {
                    if (window.getComputedStyle(el, undefined).getPropertyValue('-webkit-transform').toString() !== 'matrix(1, 0, 0, 1, 0, 0)') {
                        transform =
                            'translate3d(0, 0, 0) ' +
                            'scale3d(1, 1, 1) ';
                        scale = 1;
                        last_scale = 1;
                    }
                } catch (err) {
                    this.logger.error(err);
                 }
                el.style.webkitTransform = transform;
                transform = '';
            }

            // pan
            if (scale !== 1) {
                posX = last_posX + ev.deltaX;
                posY = last_posY + ev.deltaY;
                max_pos_x = Math.ceil((scale - 1) * el.clientWidth / 2);
                max_pos_y = Math.ceil((scale - 1) * el.clientHeight / 2);
                if (posX > max_pos_x) {
                    posX = max_pos_x;
                }
                if (posX < -max_pos_x) {
                    posX = -max_pos_x;
                }
                if (posY > max_pos_y) {
                    posY = max_pos_y;
                }
                if (posY < -max_pos_y) {
                    posY = -max_pos_y;
                }
            }

            // pinch
            if (ev.type === 'pinch') {
                scale = Math.max(.999, Math.min(last_scale * (ev.scale), 4));
            }
            if (ev.type === 'pinchend') { last_scale = scale; }

            // panend
            if (ev.type === 'panend') {
                last_posX = posX < max_pos_x ? posX : max_pos_x;
                last_posY = posY < max_pos_y ? posY : max_pos_y;
            }

            if (scale !== 1) {
                transform =
                    'translate3d(' + posX + 'px,' + posY + 'px, 0) ' +
                    'scale3d(' + scale + ', ' + scale + ', 1)';
            }

            if (transform) {
                el.style.webkitTransform = transform;
            }

            if (scale <= 1) {
                this.setZoomed(false);
            } else {
                this.setZoomed(true);
            }
        });
    }
}
