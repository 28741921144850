import { NgxLoggerLevel } from 'ngx-logger';

export const ENV = {
    DATA_GATEWAY_URL: 'https://fr-ist-vedif-jimi-uat.appspot.com/_ah/api/jimi/v1/',
    INO_CLIENT_ID: '279178686712-8l015csalcfm1bj1l9vlt3f8u88kim6c.apps.googleusercontent.com',
    IOS_LOGIN_CLIENT_ID: '',
    MAPS_API_KEY:  'AIzaSyD2XWphubky5RbOJdhWRFA8KtaATfp0FH8',
    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyAC-fGD2gx-QLVGlvONY1W-1kFJ1Q0Nb8E',
        authDomain: 'jimi-uat.app.vedif.net',
        databaseURL: 'https://fr-ist-vedif-jimi-uat.firebaseio.com',
        projectId: 'fr-ist-vedif-jimi-uat',
        storageBucket: 'fr-ist-vedif-jimi-uat.appspot.com',
        messagingSenderId: '279178686712',
    },
    SIGMA_URL: 'https://sigmav2-rec01.app-test.vedif.net/maximo/ui/?skin=tablette&event=loadapp&value=ved_pwo&additionalevent=useqbe&additionaleventvalue=SITEID=VEDIF1%7CWONUM={0}&forcereload=true',
    SIGMA_URL_CANA: 'https://sigmav2-rec01.app-test.vedif.net/maximo/ui/?skin=tablette&event=loadapp&value=VED_PWOCAN&additionalevent=useqbe&additionaleventvalue=SITEID=VEDIF1%7CWONUM={0}&forcereload=true',
    ATLAS_URL: 'https://sig2-atlasweb-rec.app-test.vedif.net/VedifAtlasSecurity/sig/Index.aspx?ServiceName=CouchesApplicatives&ServiceLayer=14&FieldName=NUM_CHANTIER&FieldValue={0}',
    URGENCE_FUITE_PHONE: '0772222337',
    LOG_LEVEL: NgxLoggerLevel.DEBUG,
    HUB_API_URL: 'https://api.veolia.com',
    HUB_API_EP: '/hub-api/v1',
    EMAIL_SUPPORT: 'manon.heyere@veolia.com',
    ESB_GATEWAY_URL: 'https://esb-client-dot-fr-ist-vedif-jimi-uat.appspot.com/api/',
    ROCK_URL: 'https://rock-vedif--uat.lightning.force.com/lightning/r/Case/',
    VISIBLE_CAPTCHA_URL: '6Ld5JrkUAAAAAJWkF8lG3WETYaJTJcsXvOF5hGT7',
    INVISIBLE_CAPTCHA_URL: '6LeGJrkUAAAAADYjFhhajULh2sxy4iGrPWg_XJjY',
    APIGEE_KEY: '5qplHGBlWeFUSVXBT1jA3D3q09RZspW5uBfYA0YS1u5vUf1Z',
    APIGEE_SECRET: 'PY0Uy4mXVMKTFh5NitIpK2GMQFfrBY1xzIe7LbvGV386qYC7pDYMqE3GtdO3IMui',
};
