import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { APP_CONFIG } from '../../../config/app.config';
import { CheckMarkersResponse } from '../../../app/pages/list/model/checkMarkersResponse';

@Injectable({
  providedIn: 'root'
})
export class MapElementService {


  private _place: any;
  private _lastCalculatedPos: any;
  private _lastVisitedPos: any;
  private _lastVisitedContext: any;
  private _clusterMarkersWithZoom: any[];  // used to save markers in a cluster when zoom is >= 22
  private _markerClusterer: any;

  markers: any[] = [];
  // Objet pour gerer le cluster

  constructor(private logger: NGXLogger) {
    this.logger.debug('Hello mapElementsProvider Provider');
  }
  /**
   * GETTERS - SETTERS
   */
  getPlace() {
     return this._place;
  }

  setPlace(place: any) {
    this._place = place;
  }

  getLastCalculatedPos() {
    return this._lastCalculatedPos;
  }

  setLastCalculatedPos(pos: any) {
    this._lastCalculatedPos = pos;
  }

  getLastVisitedPos() {
    return this._lastVisitedPos;
  }

  setLastVisitedPos(pos: any) {
    this._lastVisitedPos = pos;
  }

  getLastVisitedContext() {
    return this._lastVisitedContext;
  }

  setLastVisitedContext(context: any) {
    this._lastVisitedContext = context;
  }

  setClusterMarkers(list) {
    this._clusterMarkersWithZoom = list;
  }

  getClusterMarkers() {
    return this._clusterMarkersWithZoom;
  }

  setMarkerClusterer(value) {
    this._markerClusterer = value;
  }

  getMarkerClusterer() {
    return this._markerClusterer;
  }

  /**
   * ACTIONS
   */

  deleteMarkers() {
    // delete markers
    if (this.markers && this.markers.length > 0) {
      for (let ii = 0, jj = this.markers.length; ii < jj; ii++) {
        this.markers[ii].setMap(undefined);
      }
    }
    this.markers = [];

    // clear clusterer
    if (this._markerClusterer) {
      this._markerClusterer.clearMarkers();
    }
  }

  getMarkersForList() {
    const clusterMarkers = this._clusterMarkersWithZoom;
    return clusterMarkers && clusterMarkers.length > 0 ? clusterMarkers : this.getMarkersInVisibleArea();
  }

  getCheckMarkersResponse(): CheckMarkersResponse {
    const response: CheckMarkersResponse = new CheckMarkersResponse();
    const visibleMarkers = this.getMarkersForList();
    response.visibleMarkers = visibleMarkers;
    response.hasVisibleMarkers = visibleMarkers && visibleMarkers.length > 0;
    response.hasInvalidMarkersNumber = response.hasVisibleMarkers && visibleMarkers.length > APP_CONFIG.LIST_MAX;
    return response;
  }

  private getMarkersInVisibleArea() {

    const bounds = this.getMapBounds();

    if (bounds === undefined) {
      return undefined;
    }

    //this.logger.debug('\n');
    //this.logger.debug('bounds' + JSON.stringify(bounds));
    //this.logger.debug('Markers length >> ' + this.markers.length);

    const visibleMarkers = [];

    for (let ii = 0, jj = this.markers.length; ii < jj; ii++) {
      const marker = this.markers[ii];
      if (bounds.contains(marker.position)) {
        visibleMarkers.push(marker);
      }
    }

    this.logger.debug('Markers length >> ' + visibleMarkers.length);
    this.logger.debug('\n');
    return visibleMarkers;
  }

  private getMapBounds() {
    this.logger.debug('getMapBounds() call to getMapBounds()');
    if (this._lastVisitedContext === null || this._lastVisitedContext === undefined) {
      return undefined;
    }
    return this._lastVisitedContext.bounds;
  }

}
