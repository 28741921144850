import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { ToastService } from '../toast/toast.service';
import { LocationData } from './model/locationData';
import { APP_CONFIG } from '../../../config/app.config';
import { LABELS } from '../../../config/labels.config';
import { MapElementService } from './map-element.service';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Platform, ToastController } from '@ionic/angular';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  interventionResume: boolean;

  constructor(
    public http: HttpClient,
    private logger: NGXLogger,
    private toastService: ToastService,
    private mapElementsService: MapElementService,
    private geolocation: Geolocation,
    private diagnostic: Diagnostic,
    private platform: Platform,
    private toastController: ToastController) {
    this.logger.debug('Hello MapProvider Provider');
  }

  getLocationFromPosition(location): Promise<LocationData> {
    return new Promise<LocationData>((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'location': location }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const arrAddress = results[0].address_components;
            let name = '';
            let vicinity = '';
            let codePostal = '';
            let country = '';
            const lat = location.lat();
            const lng = location.lng();
            for (const address of arrAddress) {
              if (address.types[0] === 'street_number') {
                name = address.long_name;
              }
              if (address.types[0] === 'route') {
                name = name + ' ' + address.long_name;
              }
              if (address.types[0] === 'locality') {
                vicinity = address.long_name;
              }
              if (address.types[0] === 'postal_code') {
                codePostal = address.long_name;
              }
              if (address.types[0] === 'country'){
                country = address.long_name;
              }
            }
            if (!codePostal && lat && lng) {
              this.getPostalCodeFromLatLng(lat, lng)
                .then(cp => {
                  resolve(this.buildLocationData(lat, lng, name, vicinity, cp, country));
                })
                .catch(err => {
                  this.logger.debug(err);
                });
            } else {
              resolve(this.buildLocationData(lat, lng, name, vicinity, codePostal, country));
            }

          } else {
            reject('No results found');
            this.logger.debug('No results found');
          }
        } else {
          reject('Geocoder failed due to: ' + status);
          this.logger.debug('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  getUserLocation() {
    return new Promise<Geoposition>((resolve, reject) => {

      this.geolocation.getCurrentPosition(APP_CONFIG.GEOLOC_DEFAULT_OPTIONS)
        .then(position => {
          this.logger.debug('getUserLocation() succes getting position');
          this.setLastCalculatedPos(position);
          resolve(position);
        })
        // run different scenarios if we couldn't get user current position
        .catch(err => {
          this.logger.error('Erreur calculating the position ' + err.code, err.message);

          // handle case of native app. maybe geolocation is not enabled.
          if (this.platform.is('cordova')) {

            // define success callback method for diagnostic.isGpsLocationEnabled()
            const successCallback = (isAvailable) => { 
              this.logger.debug('Is available? ' + isAvailable); 
              if (isAvailable) {
                this.logger.debug('GPS Activé !!! ');
                resolve(this.getDefaultPosObj());
              } else {
                this.logger.debug('GPS Désactivé !!! ');
                this.presentGPSToast(LABELS.app_toast_activate_localisation_head, LABELS.app_toast_activate_localisation_message, 'water', () => {
                  return this.platform.is('android') ? this.diagnostic.switchToLocationSettings() : this.diagnostic.switchToSettings();
                });
                resolve(this.getDefaultPosObj());
              }
            }
            // define error callback method for diagnostic.isGpsLocationEnabled()
            const errorCallback = (e) => {
              this.presentGPSToast(LABELS.app_toast_activate_localisation_head, LABELS.app_toast_activate_localisation_message, 'water', () => {
                return this.platform.is('android') ? this.diagnostic.switchToLocationSettings() : this.diagnostic.switchToSettings();
              });
              resolve(this.getDefaultPosObj());
            };

            // call to diagnostic isGpsLocationEnabled method
            this.diagnostic.isGpsLocationEnabled()
            .then(successCallback)
            .catch(errorCallback);

          } 
          // handle other cases
          else {
            if (err.code === 1 || err.message === 'User denied Geolocation') {
              this.logger.debug('User denied Geolocation');
              // toast message to inform the user that he didn't activate geolocation
              this.toastService.presentToast(LABELS.map_toast_geolocation_denied, APP_CONFIG.TOAST_TYPE_ALERT, {
                position: 'middle',
              });
              // return  default position object
              resolve(this.getDefaultPosObj());
            } else {
  
              // On a pas arrivé à calculer la position dans le temps donc on laisse tomber
              // et on réenvoie la derniere position
              if (this.getLastCalculatedPos() !== undefined) {
                this.logger.debug('getUserLocation() problem getting position - returnig lastCalcPos');
                resolve(this.getLastCalculatedPos());
              } else {
                this.logger.debug('getUserLocation() problem getting position - returning defaultPos');
                resolve(this.getDefaultPosObj());
              }
  
              this.toastService.presentToast(LABELS.map_toast_geolocation_not_calculated, APP_CONFIG.TOAST_TYPE_ALERT, {
                position: 'middle',
              });
  
            }
          }

        });

    });
  }

  async presentGPSToast(header, message, icon, callback) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      duration: 5000,
      buttons: [
        {
          side: 'start',
          icon,
        },
        {
          text: 'Activer',
          handler: () => {
            callback(); 
          }
        }
      ]
    });
    toast.present();
    toast.onDidDismiss().then(()=>{this.logger.debug('GPS toast dismissed')});
  }

  async getLocationFromPlace(place): Promise<LocationData> {
    const arrAddress = place.address_components;
    let name = '';
    let vicinity = '';
    let codePostal: any = '';
    let country = '';
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    for (const address of arrAddress) {
      if (address.types[0] === 'street_number') {
        name = address.long_name;
      }
      if (address.types[0] === 'route') {
        name = name + ' ' + address.long_name;
      }
      if (address.types[0] === 'locality') {
        vicinity = address.long_name;
      }
      if (address.types[0] === 'postal_code') {
        codePostal = address.long_name;
      }
      if (address.types[0] === 'country'){
        country = address.long_name;
      }
    }

    if (!codePostal && lat && lng) {
      codePostal = await this.getPostalCodeFromLatLng(lat, lng);
    }

    return (this.buildLocationData(lat, lng, name, vicinity, codePostal, country));
  }

  hasInterventionResume() {
    return this.interventionResume;
  }

  setInterventionResume(interventionResume) {
    this.interventionResume = interventionResume;
  }

  private getPostalCodeFromLatLng(lat, lng) {
    return new Promise(resolve => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(lat, lng);
      let postalCode = '';
      geocoder.geocode({ 'latLng': latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const elt = results[0].address_components;
            elt.forEach(element => {
              if (element.types[0] === 'postal_code') {
                postalCode = element.long_name;
              }
            });
            resolve(postalCode);
          }
        } else {
          this.logger.error('Geocoder failed due to: ' + status);
          resolve();
        }
      });
    });
  }

  private buildLocationData(latitude, longitude, name, vicinity, codePostal, country): LocationData {
    const locationData = new LocationData();
    locationData.latitude = latitude;
    locationData.longitude = longitude;
    locationData.name = name;
    locationData.vicinity = vicinity;
    locationData.codePostal = codePostal;
    locationData.country = country;
    return locationData;
  }

  // paris
  private getDefaultPosObj(): Geoposition {
    return {
      coords: {
        accuracy: undefined,
        altitude: undefined,
        altitudeAccuracy: undefined,
        heading: undefined,
        latitude: 48.8566,
        longitude: 2.3522,
        speed: undefined,
      },
      timestamp: undefined,
    };
  }

  private getLastCalculatedPos() {
    return this.mapElementsService.getLastCalculatedPos();
  }

  private setLastCalculatedPos(position) {
    this.mapElementsService.setLastCalculatedPos(position);
  }

}
