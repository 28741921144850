import { Component, OnInit, Input } from '@angular/core';
import { AlerteResumeData } from '../../../app/services/rest-alerte/model/alerteResumeData';
import { InterventionResumeData } from '../intervention-resume/model/interventionResumeData';
import { NavController, Events } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { APP_CONFIG } from '../../../config/app.config';
import { PAGES_CONFIG } from '../../../config/pages.config';
import { Router, ActivatedRoute } from '@angular/router';
import { PATHS_CONFIG } from '../../../config/paths.config';
import { NavExtrasService } from '../../../app/services/nav-extra/nav-extras.service';

@Component({
  selector: 'app-alerte-resume',
  templateUrl: './alerte-resume.component.html',
  styleUrls: ['./alerte-resume.component.scss'],
})
export class AlerteResumeComponent implements OnInit {

    // Annotation pour recuperer des informations depuis un component père
    @Input() alerteResumeData: AlerteResumeData;
    @Input() interventionResumeData: InterventionResumeData;

    constructor(public navCtrl: NavController,
                private logger: NGXLogger,
                private navExtras: NavExtrasService,
                private router: Router,
                private route: ActivatedRoute) {

     this.logger.debug('Hello AlerteResumeComponent Component');
  }

  ngOnInit() {}

  goToAlerteDetailPage(alerteResumeData: AlerteResumeData) {
    this.logger.debug('call to goToAlerteDetailPage()');
    const navigationExtras = {
      idAlerte: alerteResumeData.id,
      idIntervention: this.interventionResumeData
    };
    this.navExtras.setExtras(navigationExtras);
    this.router.navigate(['.' + PATHS_CONFIG.ALERTE_DETAIL_PATH], { relativeTo: this.route });
}

}
