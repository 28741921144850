import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { Injector, Injectable } from '@angular/core';
import { AlertService } from '../services/alert/alert.service';
import { CacheService } from '../services/cache/cache.service';
import { Observable } from 'rxjs';
import { of } from "rxjs";
import { NGXLogger } from 'ngx-logger';
import { LABELS } from 'src/config/labels.config';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

    reqsTotal = 0;
    reqsCompleted = 0;
    loading: HTMLIonLoadingElement;
    spinnerTimeout: any;

    constructor(public loadingCtrl: LoadingController,
                private injector: Injector,
                private alertService: AlertService,
                private cache: CacheService,
                private logger: NGXLogger
                ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // A remettre quand l'on aura des url à mettre en cache
        // Si la requette http n'est pas configurée pour être mise en cache
        /*if (!this.cache.isCachable(req.url)) {
            return this.sendRequest(req, next);
        }*/
        // On récupére la réponse de la requête contenue dans le cache
        // const cachedResponse = this.cache.get(req);
        // return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
        return this.sendRequest(req, next);
    }

    sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const self = this;

        if (this.reqsTotal === 0) {
            this.updateLoading(true);
            this.reqsTotal++;
        }

        return next.handle(req).pipe(
            map((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                    // A remettre quand l'on aura des url à mettre en cache
                    /*if (this.cache.isCachable(req.url)) {
                        // On insère dans le cache la requête http.
                        this.cache.put(req, evt);
                    }*/
                    self.reqsCompleted++;
                    if (self.reqsCompleted >= self.reqsTotal) {
                        self.onRequestsCompleted();
                        return evt;
                    }
                }
            }),
            catchError((err: HttpErrorResponse) => {
                return self.handleReqError(err);
            }));
      }

    private async updateLoading(show?) {
        clearTimeout(this.spinnerTimeout);

        // dismiss loading by default
        await this.dismissLoading();

        this.spinnerTimeout = setTimeout(async () => {
            if (show) {
                await this.showLoading();
            } else {
                await this.dismissLoading();
            }
        }, 1000);
    }

    private async showLoading() {
        if (!this.loading) {
            this.loading = await this.loadingCtrl.create({
                spinner: 'bubbles',
            });
            return await this.loading.present().catch(err => this.injector.get(NGXLogger).error(err));
        }
    }

    private async dismissLoading() {
        if (this.loading) {
            await this.loading.dismiss().catch(err => this.injector.get(NGXLogger).error(err));
            this.loading = undefined;
            return Promise.resolve();
        }
    }

    onRequestsCompleted() {
        this.reqsCompleted = 0;
        this.reqsTotal = 0;
        this.updateLoading();
    }

    private handleReqError(err: HttpErrorResponse): Observable<any> {
        // handle your auth error or rethrow
        this.onRequestsCompleted();
        const alrtMsg = err.status === 401 ? LABELS.error_401_unauthorized : LABELS.error_global_http_interceptor ;
        this.alertService.presentErrorAlert('ERREUR', alrtMsg);
        this.logger.error('request error', err);
        return of(err);
    }

}
