import { StatusItem } from './statusItem';

export class InterventionResumeBlockOptions {
    hasData: boolean;
    isInsideList: boolean;
    isSearchResult: boolean;
    row1: {
        label: string;
        leftItems: StatusItem[];
        rightItems: StatusItem[];
        isLocation: boolean;
        isAlerte: boolean;
    };
    row2: {
        labels: string[],
    };
    row3: {
        clickAction: () => void;
    };
}
