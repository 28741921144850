import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoggerModule } from 'ngx-logger';
import { ENV } from '../config/env.config';
import { PipesModule } from './pipes/pipes.module';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule, MatIconRegistry } from '@angular/material';
import { ComponentsModule } from './components/components.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { IonicStorageModule } from '@ionic/storage';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { ToastComponent } from './components/toast/toast.component';
import { AlertComponent } from './components/alert/alert.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { Firebase } from '@ionic-native/firebase/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { InterceptorModule } from './interceptor/interceptor.module';
import { RoutingService } from './services/routing/routing.service';
import { AppInitService } from './services/app-init/app-init.service';


export function initApp(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.init();
  }
}


@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    ToastComponent,
    AlertComponent,
  ],
  imports: [BrowserModule, 
            IonicModule.forRoot(),
            AppRoutingModule,
            PipesModule,
            IonicStorageModule.forRoot(),
            LoggerModule.forRoot({ level: ENV.LOG_LEVEL }),
            HttpClientModule, // for angular material design
            MatIconModule,  // for angular material design
            ComponentsModule,
            AngularFireModule.initializeApp(ENV.FIREBASE_CONFIG),
            AngularFireAuthModule,
            ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
            // ServiceWorkerModule.register('firebase-messaging-sw.js', {enabled: environment.production}),
            BrowserAnimationsModule,
            InterceptorModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ AppInitService ],
      multi: true
    },
    StatusBar,
    SplashScreen,
    GooglePlus,
    Geolocation,
    Camera,
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Firebase,
    FirebaseX,
    Deeplinks,
    InAppBrowser,
    Diagnostic,
    RoutingService,
    Badge,
    AppInitService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    // to use material design icons
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
      registerLocaleData(localeFr);
      matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')); // path where mdi.svg is placed
    }
}
