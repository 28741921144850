import { Component, OnInit, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MenuItem } from '../../../app/pages/global-menu/model/menuItem';

@Component({
  selector: 'app-menu-items-list',
  templateUrl: './menu-items-list.component.html',
  styleUrls: ['./menu-items-list.component.scss'],
})
export class MenuItemsListComponent implements OnInit {

  @Input() items: MenuItem[];
  idx;

  constructor(
    private logger: NGXLogger
  ) {
    this.logger.log('Hello MenuItemsListComponent Component');
  }

  ngOnInit() {}

  onMenuItemClick(idx) {
    this.items[idx].clickCb();
  }

}
