import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavExtrasService {
  extras: any;

  constructor() { }

  public setExtras(data, mergeObject?){
    if (mergeObject) {
      this.extras = { ...this.extras, ...data};
    } else {
      this.extras = data;
    }
  }

  public getExtras(){
    return this.extras;
  }
}
