export const DEFAULT_METADATA = {
  "pages": [
    {
      "name": "FilterPage",
      "variables": [
        {
          "name": "filterTypeBtnList",
          "value": [
            {
              "id": "trvx",
              "filterOption": "trvx",
              "btnLabel": "Travaux",
              "btnStatus": true,
              "btnClass": "ic-filter-button type",
              "btnIcon": "ic-custom-filter-btn-csed"
            },
            {
              "id": "fuit",
              "filterOption": "fuit",
              "btnLabel": "Fuites",
              "btnStatus": true,
              "btnClass": "ic-filter-button type",
              "btnIcon": "ic-custom-filter-btn-fuit"
            }
          ]
        },
        {
          "name": "filterStatutBtnList",
          "value": [
            {
              "id": "programme",
              "filterOption": 1,
              "btnLabel": "Programmé",
              "btnStatus": true,
              "btnClass": "ic-filter-button programme"
            },
            {
              "id": "cours",
              "filterOption": 2,
              "btnLabel": "En cours",
              "btnStatus": true,
              "btnClass": "ic-filter-button en_cours"
            },
            {
              "id": "termine",
              "filterOption": 3,
              "btnLabel": "Terminé",
              "btnStatus": false,
              "btnClass": "ic-filter-button termine"
            }
          ]
        },
      ]
    },
    {
      "name": "BandeauPage",
      "variables": [
        {
          "name": "tabs",
          "value": [
            {
              "value": "detail",
              "label": "TRAVAUX"
            },
            {
              "value": "arret-eau",
              "label": "ARRÊT D'EAU"
            }
          ]
        }
      ]
    },
    {
      "name": "DetailPage",
      "variables": [
        {
          "name": "displayLinks",
          "value": false
        },
        {
          "name": "detailDateOT",
          "value": ""
        },
        {
          "name": "detailDateTP",
          "value": ""
        }
      ]
    }
  ],
  "providers": [
    {
      "name": "FilterService",
      "variables": [
        {
          "name": "_filterSettingsDefault",
          "value": {
            "statutInter": [1,2],
            "typeInter": ["fuit","trvx"],
            "typeAlert": [0,1,2],
            "statutAlert": [0,1,2,3],
            "ae": false
          }
        },
        {
          "name": "_filterStatusDefault",
          "value": {
            "planifie": false,
            "cours": true,
            "termine": false,
            "programme": true,
            "trvx": true,
            "fuit": true,
            "ae": false,
            "non_traite": true,
            "prise": true,
            "traite": true,
            "non_traitable": true,
            "aler_fuit": true,
            "aler_envi": true,
            "aler_bipi": true
          }
        }
      ]
    }
  ]
}