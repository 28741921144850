import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Platform } from '@ionic/angular';
import { USER_MANAGEMENT_URL_PARAMETERS_NAMES, USER_MANAGEMENT_ACTIONS } from 'src/config/enum.config';
import { LABELS } from 'src/config/labels.config';
import { Router } from '@angular/router';
import { PATHS_CONFIG } from 'src/config/paths.config';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { RoutingService } from 'src/app/services/routing/routing.service';

@Component({
  selector: 'app-usr-mgmt',
  templateUrl: './usr-mgmt.page.html',
  styleUrls: ['./usr-mgmt.page.scss'],
})
export class UsrMgmtPage implements OnInit {

  mode: string;
  actionCode: string;
  email: string;

  constructor(
    private logger: NGXLogger,
    private alertService: AlertService,
    private platform: Platform,
    private router: Router,
    private routingService: RoutingService
  ) { }

  ngOnInit() {
    this.logger.debug('UsrManagementPage. ngOnInit');
    
    this.actionCode = this.platform.getQueryParam(USER_MANAGEMENT_URL_PARAMETERS_NAMES.ACTION_CODE);
    this.email = this.platform.getQueryParam(USER_MANAGEMENT_URL_PARAMETERS_NAMES.EMAIL);
    this.mode = this.platform.getQueryParam(USER_MANAGEMENT_URL_PARAMETERS_NAMES.MODE);
    this.logger.debug(`UsrManagementPage in ngOnInit.\nmode : ${this.mode} \nactionCode : ${this.actionCode} \nemail : ${this.email}`);
    this.checkUrlParameters()
    .then(() => {
      this.handleAction();
    })
    .catch(err => {
      this.logger.error('UsrMmgtPage ngOnInit', err);
    });
  }

  /**
   * This method checks the url parameters that we need (oobCode, mode and email) to proceed
   */
  private checkUrlParameters(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!(this.actionCode && this.email && this.mode)) {
        this.alertService.presentAlert(LABELS.usr_mgmt_error_link_title, LABELS.usr_mgmt_error_link_missing_parameters, undefined, () => {
          this.routingService.globalGoToHomePage();
          reject();
        });
      } else {
        resolve();
      }
    });
  }

  /**
   * This methods redirects to the appropriate page depending on mode parameter value (verifyEmail, resetPassword )
   */
  private handleAction() {
    this.logger.debug('UsrMgmtPage. handleAction()');
    let path = '';

    switch (this.mode) {
      case USER_MANAGEMENT_ACTIONS.RESET_PASSWORD:
        path = PATHS_CONFIG.RESET_PASSWORD_PATH;
        break;
      case USER_MANAGEMENT_ACTIONS.VERIFY_EMAIL:
          path = PATHS_CONFIG.VERIFY_EMAIL_PATH;
        break;
    }

    return this.router.navigate([path], { queryParamsHandling: "merge", replaceUrl: true })
    .catch((err) => this.logger.error(err));

  }

}
