import { Component, OnInit, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {

  @Input() header;

  constructor(private logger: NGXLogger) {
    this.logger.debug('Hello NavBarComponent Component');
  }

  ngOnInit() {}

}
