/**
 * UTILS METHODS FOR alert-files-selector COMPONENT
 * Inspired from library image-compressor utils https://raw.githubusercontent.com/xkeshi/image-compressor/761d0fa735d8274c11ceae588e392681c2bff122/src/utils.js
 */
const REGEXP_IMAGE_TYPE = /^image\/.+$/;

/**
 * Check if the given value is a mime type of image.
 * @param  value - The value to check.
 * @returns  Returns `true` if the given is a mime type of image, else `false`.
 */
export function isImageType(value) {
  return REGEXP_IMAGE_TYPE.test(value);
}

/**
 * Convert image type to extension.
 * @param  value - The image type to convert.
 * @param  [includeDot=true] - Include a leading dot or not.
 * @returns Returns the image extension.
 */
export function imageTypeToExtension(value, includeDot = true) {
  let extension = isImageType(value) ? value.substr(6) : '';

  if (extension === 'jpeg') {
    extension = 'jpg';
  }

  if (extension && includeDot) {
    extension = `.${extension}`;
  }

  return extension;
}

const { fromCharCode } = String;

/**
 * Get string from char code in data view.
 * @param dataView - The data view for read.
 * @param  start - The start index.
 * @param  length - The read length.
 * @returns  The read result.
 */
export function getStringFromCharCode(dataView, start, length) {
  let str = '';
  let i;

  length += start;

  for (i = start; i < length; i += 1) {
    str += fromCharCode(dataView.getUint8(i));
  }

  return str;
}

const { btoa } = window;

/**
 * Transform array buffer to Data URL.
 * @param  arrayBuffer - The array buffer to transform.
 * @param mimeType - The mime type of the Data URL.
 * @returns The result Data URL.
 */
export function arrayBufferToDataURL(arrayBuffer, mimeType) {
  const uint8 = new Uint8Array(arrayBuffer);
  const { length } = uint8;
  let data = '';
  let i;

  // TypedArray.prototype.forEach is not supported in some browsers.
  for (i = 0; i < length; i += 1) {
    data += fromCharCode(uint8[i]);
  }

  return `data:${mimeType};base64,${btoa(data)}`;
}

/**
 * Get orientation value from given array buffer.
 * @param  arrayBuffer - The array buffer to read.
 * @returns  The read orientation value.
 */
export function getOrientation(arrayBuffer) {
  const dataView = new DataView(arrayBuffer);
  let orientation;
  let littleEndian;
  let app1Start;
  let ifdStart;

  // Only handle JPEG image (start by 0xFFD8)
  if (dataView.getUint8(0) === 0xFF && dataView.getUint8(1) === 0xD8) {
    const length = dataView.byteLength;
    let offset = 2;

    while (offset < length) {
      if (dataView.getUint8(offset) === 0xFF && dataView.getUint8(offset + 1) === 0xE1) {
        app1Start = offset;
        break;
      }

      offset += 1;
    }
  }

  if (app1Start) {
    const exifIDCode = app1Start + 4;
    const tiffOffset = app1Start + 10;

    if (getStringFromCharCode(dataView, exifIDCode, 4) === 'Exif') {
      const endianness = dataView.getUint16(tiffOffset);

      littleEndian = endianness === 0x4949;

      if (littleEndian || endianness === 0x4D4D /* bigEndian */) {
        if (dataView.getUint16(tiffOffset + 2, littleEndian) === 0x002A) {
          const firstIFDOffset = dataView.getUint32(tiffOffset + 4, littleEndian);

          if (firstIFDOffset >= 0x00000008) {
            ifdStart = tiffOffset + firstIFDOffset;
          }
        }
      }
    }
  }

  if (ifdStart) {
    const length = dataView.getUint16(ifdStart, littleEndian);
    let offset;
    let i;

    for (i = 0; i < length; i += 1) {
      offset = ifdStart + (i * 12) + 2;

      if (dataView.getUint16(offset, littleEndian) === 0x0112 /* Orientation */) {
        // 8 is the offset of the current tag's value
        offset += 8;

        // Get the original orientation value
        orientation = dataView.getUint16(offset, littleEndian);

        // Override the orientation with its default value
        dataView.setUint16(offset, 1, littleEndian);
        break;
      }
    }
  }

  return orientation;
}

/**
 * Parse Exif Orientation value.
 * @param  orientation - The orientation to parse.
 * @returns  The parsed result.
 */
export function parseOrientation(orientation) {
  let rotate = 0;
  let scaleX = 1;
  let scaleY = 1;

  switch (orientation) {
    // Flip horizontal
    case 2:
      scaleX = -1;
      break;

    // Rotate left 180°
    case 3:
      rotate = -180;
      break;

    // Flip vertical
    case 4:
      scaleY = -1;
      break;

    // Flip vertical and rotate right 90°
    case 5:
      rotate = 90;
      scaleY = -1;
      break;

    // Rotate right 90°
    case 6:
      rotate = 90;
      break;

    // Flip horizontal and rotate right 90°
    case 7:
      rotate = 90;
      scaleX = -1;
      break;

    // Rotate left 90°
    case 8:
      rotate = -90;
      break;

    default:
  }

  return {
    rotate,
    scaleX,
    scaleY,
  };
}

const REGEXP_DECIMALS = /\.\d*(?:0|9){12}\d*$/i;

/**
 * Normalize decimal number.
 * Check out {@link http://0.30000000000000004.com/}
 * @param value - The value to normalize.
 * @param  [times=100000000000] - The times for normalizing.
 * @returns  Returns the normalized number.
 */
export function normalizeDecimalNumber(value, times = 100000000000) {
  return REGEXP_DECIMALS.test(value) ? (Math.round(value * times) / times) : value;
}

/**
 * Convert a url data from camera to a JavaScript file
 * @param url 
 * @param filename 
 * @param mimeType 
 */
export function urltoFile(url, filename, mimeType){
  return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename, {type:mimeType});})
  );
}
