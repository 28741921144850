import { Component, OnInit, NgZone, Input } from '@angular/core';
import { LABELS } from '../../../config/labels.config';
import { APP_CONFIG } from '../../../config/app.config';
import { ToastService } from '../../../app/services/toast/toast.service';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from '../../../app/services/utils/utils.service';
import { TabsBarOptions } from './model/tabsBarOptions';

@Component({
  selector: 'app-tabs-bar',
  templateUrl: './tabs-bar.component.html',
  styleUrls: ['./tabs-bar.component.scss'],
})
export class TabsBarComponent implements OnInit {


  @Input() options: TabsBarOptions;

  // Specifier le nom de l'icone material design
  // Le label pour chaque element
  tabs = [{
    icon: 'google-maps',
    label: LABELS.tabsbar_label_map,
    active: true,
    callback: () => {
      return this.options.mapTabCb();
    },
  },
          {
    icon: 'format-list-bulleted',
    label: LABELS.tabsbar_label_list,
    active: false,
    callback: () => {
      return this.options.listTabCb();
    },
  }];

  selectedTabIndex = 0;
  tabClickedIndex: number;

  constructor(private toastService: ToastService,
              private zone: NgZone,
              private utilsService: UtilsService,
              private logger: NGXLogger) {
    this.logger.debug('Hello TabsBarComponent Component');
    // On s'ensert du service pour specifier la methode qui va etre appellée
    // set clickTab method in utils
    this.utilsService.clickTab = idx => {
      this.logger.debug('call to clickTab() with index = ' + idx);
      this.onTabClick(idx);
    };

    this.utilsService.getTabClickedIndex = () => {
      return this.tabClickedIndex;
    };

  }

  ngOnInit() {}

  
  onTabClick(tabIndex) {
    this.tabClickedIndex = tabIndex;
    if (tabIndex !== this.selectedTabIndex) {
      this.runSelectedTabCallback(tabIndex).then(() => {
        this.zone.run(() => {
          this.clearActiveState();
          this.activateSelectedTab(tabIndex);
          this.updateSelectedTabIndex(tabIndex);
        });
      }).catch(err => {
        this.logger.debug('on tab click callback reject');
        this.logger.error(err);
      });
    }
    // else do nothing
  }

  private clearActiveState() {
    this.tabs[this.selectedTabIndex].active = false;
  }

  private activateSelectedTab(newIdx) {
    this.tabs[newIdx].active = true;
  }

  private updateSelectedTabIndex(newIdx) {
    this.selectedTabIndex = newIdx;
  }

  private runSelectedTabCallback(newIdx): Promise<any> {
    if (this.tabs[newIdx]['callback']) {
      return this.tabs[newIdx].callback();
    } else {
      // S'il n'y a pas une callback associée
      return new Promise((resolve, reject) => {
        this.toastService.presentToast(LABELS.tabsbar_toast_no_action_attached, APP_CONFIG.TOAST_TYPE_ALERT );
        reject();
      });

    }
  }


}
