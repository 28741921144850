import { Injectable } from '@angular/core';
import { LABELS } from '../../../config/labels.config';
import { Platform, LoadingController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NGXLogger } from 'ngx-logger';
import { Badge } from '@ionic-native/badge/ngx';
import { APP_CONFIG } from 'src/config/app.config';


declare var google;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {


  isDesktop: boolean;
  map: any;
  clickTab: (idx) => void;
  getTabClickedIndex: () => number;
  loading: HTMLIonLoadingElement;
  cameFromAlertLink: boolean = undefined;
  activePage: string ;
  private isMenuClosedBySideClick: boolean = true;

  constructor(private plt: Platform,
              private loadingCtrl: LoadingController,
              private logger: NGXLogger,
              private iab: InAppBrowser,
              private badge: Badge) {
    this.logger.debug('Hello UtilsProvider Provider');
  }

  /**
   * This method sets and/or returns isDesktop boolean value
   * Device is considered desktop if it is desktop or tablet
   */
  isDesktopDevice() {
    let platforms = this.plt.platforms();
    if (this.isDesktop === undefined) {
      this.isDesktop = (platforms[0] == "core" || platforms[0] == "mobileweb");
    }
    return this.isDesktop;
  }

  /**
   * This method tells whether the application is running as a native android/ios app or not
   */
  isNativeApp(): boolean {
    // return (this.plt.is('ios') || this.plt.is('android')) && !this.plt.is('mobileweb');
    return this.plt.is('cordova');
  }

  isAndroid(): boolean {
    // return (this.plt.is('ios') || this.plt.is('android')) && !this.plt.is('mobileweb');
    return this.plt.is ('cordova') && this.plt.is('android');
  }

  setMap(m) {
    this.map = m;
  }

  getMap() {
    return this.map;
  }

  getPlaceDetailsRequestError(errCode) {
    let errMsg = LABELS.error_place_details_default;
    switch (errCode) {
      case google.maps.places.PlacesServiceStatus.ERROR:
        errMsg = LABELS.error_place_details_ERROR;
        break;
      case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
        errMsg = LABELS.error_place_details_INVALID_REQUEST;
        break;
      case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
        errMsg = LABELS.error_place_details_OVER_QUERY_LIMIT;
        break;
      case google.maps.places.PlacesServiceStatus.NOT_FOUND:
        errMsg = LABELS.error_place_details_NOT_FOUND;
        break;
      case google.maps.places.PlacesServiceStatus.REQUEST_DENIED:
        errMsg = LABELS.error_place_details_REQUEST_DENIED;
        break;
      case google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR:
        errMsg = LABELS.error_place_details_UNKNOWN_ERROR;
        break;
      case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
        errMsg = LABELS.error_place_details_ZERO_RESULTS;
        break;
    }
    return errMsg;
  }

  /* Utils */

  // methode pour enmever un element d'un array
  removeElementFromArrayIfExists(element, array) {

    const index = array.indexOf(element);

    if (index > -1) {
      array.splice(index, 1);
    }

  }

  addElementToArrayIfNotExists(element, array) {
    if (array.indexOf(element) < 0) {
      array.push(element);
    }

  }
  // methode pour faire push et pop dans un array des arrays par rapport à son status
  updateFilterArrayOptionByStatus(isSelected: boolean, element: any, array: any[]) {

    if (isSelected) {
      this.addElementToArrayIfNotExists(element, array);
    } else {
      this.removeElementFromArrayIfExists(element, array);
    }

  }

  createCopyFromObject(providerObject: any) {
    if (providerObject){
      return JSON.parse(JSON.stringify(providerObject));
    }
    return null;
  }

  compareArrayByJson(object1, object2) {
    return (JSON.stringify(object1) === JSON.stringify(object2));
  }

  async showLoading() {
    this.logger.debug('UtilsService showLoading()');
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
      });
      await this.loading.present().catch(err => this.logger.error(err));
    }
  }

  async hideLoading() {
    this.logger.debug('UtilsService hideLoading(). this.loading', this.loading);
    if (this.loading) {
      await this.loading.dismiss().catch(err => this.logger.error(err));
      this.loading = undefined;
    }
  }

  /**
   * This method checks if a string contains emoji or not
   * @param str string to check
   */
  hasEmoji(str) {
    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const matches = str.match(regex);
    return matches ? true : false;
  }

  /**
   * Transforme un string en majuscule sans les accents
   */
  toUppercase(str) {
      let upperCaseString = str.toUpperCase();
      upperCaseString = upperCaseString.replace(/[ÁÀÄÂ]/, 'A');
      upperCaseString = upperCaseString.replace(/[ÉÈËÊ]/, 'E');
      upperCaseString = upperCaseString.replace(/[ÍÌÏÎ]/, 'I');
      upperCaseString = upperCaseString.replace(/[ÓÒÖÔ]/, 'O');
      upperCaseString = upperCaseString.replace(/[ÚÙÜÛ]/, 'U');
      upperCaseString = upperCaseString.replace(/[ÝŸ]/, 'Y');
      return upperCaseString;
  }

  clearTabs(visible: boolean) {
    this.logger.debug('UtilsService clearTabs. visible : ', visible);
    const domItem = document.querySelector('ion-tab-bar');
    if (domItem) {
      domItem['style'].display = 'none';
      if (visible) {
        domItem['style'].display = '';
      }
    }
  }

  ifViewAlreadyCreatedDoNotEnter(navCtrl, componentName: string) {
    if (undefined !== navCtrl.getActive() && navCtrl.getActive().id === componentName) {
      this.logger.debug('ifViewAlreadyCreatedDoNotEnter() component: ' + navCtrl.getActive().id);
      return false;
    } else {
      return true;
    }
  }

  setCameFromAlertLink(_cameFromAlertLink) {
    this.cameFromAlertLink = _cameFromAlertLink;
  }

  getCamFromAlertLink() {
    return this.cameFromAlertLink;
  }

  setActivePage(_activePage) {
    this.activePage = _activePage;
  }

  getActivePage() {
    return this.activePage;
  }

  removeAccents(str) {
    let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split('');
    str.forEach((letter, index) => {
      let i = accents.indexOf(letter);
      if (i != -1) {
        str[index] = accentsOut[i];
      }
    })
    return str.join('');
  }

  openExternalUrl(url) {
    this.logger.debug(`UtilsService openExternalUrl() with url : ${url}`);
    if (this.plt.is('cordova')) {
      this.iab.create(url, `_system`);
    } else {
      this.iab.create(url, `_blank`);
    }
  }

  clearNativeBadge() {
    this.logger.debug('UtilsService. clearNativeBadge()');
    if (this.plt.is('hybrid')) {
      this.badge.clear()
      .then(res => {
        this.logger.debug('badge cleared. res = ', res);
      })
      .catch(err => {
        this.logger.error('an error occured on badge clearance', err);
      });
    }
    
  }

  load_script_promise(url) {
    let listener;
    return new Promise(function(resolve, reject){
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      listener = script.addEventListener('load', function(){
        console.log('UtilsService load_script_promise() addEventListener load callback');
        this.removeEventListener('load', listener);
        resolve(script);
      })
      script.src = url;
      head.appendChild(script);
    });
  }

  clearLocalStorage() {
    localStorage.clear();
    // restore 'appRanAtLeastOnce' key in localstorage
    localStorage.setItem(APP_CONFIG.APP_RAN_AT_LEAST_ONCE_STORAGE_KEY, "true");
  }

  isObjectNotEmpty(o){
    return Object.entries(o).length > 0;
  }

    /**
   * This method push l'url actuel dans l'historique pour pouvoir gérer le back web
   */
  historyPushThisHrefIfWeb(TAG:string){
    if (!this.isNativeApp()){
      this.logger.debug(`${TAG} pushing state`, location.pathname);
      history.pushState(undefined, undefined, location.href);
    }
  }


  historyBackifWeb(TAG:string){
    if (!this.isNativeApp()){
      this.logger.debug(`${TAG} doing history.back()`, window.location.href);
      history.back();
    }
  }

  setIsMenuClosedBySideClick(v:boolean){
    this.isMenuClosedBySideClick = v;
  }

  getIsMenuClosedBySideClick(){
    return this.isMenuClosedBySideClick;
  }
}
