export enum ENUM_FILTER_ID {
    EXPL = 'expl' ,
    CANA = 'cana',
    TIER = 'tier',
    FUIT = 'fuit',
    DOMM = 'domm',
    CSED = 'csed',
    A_PLANIFIER = 'planifier',
    PROGRAMME = 'programme',
    EN_COURS = 'cours',
    TERMINE = 'termine',
    NON_TRAITE = 'non_traite',
    PRISE= 'prise',
    TRAITEE= 'traite',
    NON_TRAITABLE = 'non_traitable',
    ALER_FUIT = 'aler_fuit',
    ALER_ENVI = 'aler_envi',
    ALER_BIPI = 'aler_bipi',
}

export enum ENUM_FILTER_TYPE_OPTIONS {
    EXPL = 'expl' ,
    CANA = 'cana',
    TIER = 'tier',
    FUIT = 'fuit',
    DOMM = 'domm',
    CSED = 'csed',
    TRVX = 'trvx',
}

export enum ENUM_FILTER_ALERT_TYPE_OPTIONS {
    ALER_ENVI = 0,
    ALER_FUIT = 1,
    ALER_BIPI = 2,
}

export enum ENUM_FILTER_STATUS_OPTIONS {
    A_PLANIFIER = 0,
    PROGRAMME = 1,
    EN_COURS = 2,
    TERMINE = 3,
    NON_TRAITE = 0,
    PRISE= 1,
    TRAITEE= 2,
    NON_TRAITABLE = 3,
}

export enum ENUM_ROUTE_ACTIONS {
    ALERTE_DETAIL = 'alerteDetail',
    NOTIF = 'notif',
}

export enum ENUM_ALERTE_MODE {
    ATTACHED = 'attachée',
    NON_ATTACHED = 'orpheline',
}

export enum ENUM_ALERTE_CODE_MODE {
    ATTACHED = 0,
    NON_ATTACHED = 1
}

export enum LOGIN_OPTIONS {
    GOOGLE = 1,
    EMAIL = 2,
    SUBSCRIBE = 3,
    ROCK = 4, 
}

export enum ENUM_FILTER_ALERT_STATUS_CODE {
    NON_TRAITE = 0,
    PRISE_EN_COMPTE = 1,
    TRAITE= 2,
    NON_TRAITABLE = 3
}

export enum ENUM_STATUT_DEMANDE {
    OUVERT = 'OUVERT',
    FERME = 'FERME' 
}

export enum ENUM_BANDEAU_TABS {
    TRAVAUX = 0,
    ARRET_EAU = 1,
    ALERTE = 2,
}

export enum CALLABLE_CLOUD_FUNCTIONS {
    GET_PROVIDER = 'getProvider',
    HAS_GOOGLE_PROVIDER = 'hasGoogleProvider',
    HAS_ALREADY_EXISTING_ACCOUNT_WITH_DIFFERENT_SIGN_IN = 'hasAlreadyExistingAccountWithDifferentSignIn',
    SEND_PASSWORD_RESET_EMAIL = 'sendPasswordResetEmail',
    VERIFY_ID_TOKEN = 'verifyIdToken',
    UPDATE_USER_ACCOUNT = 'updateUserAccount',
    DELETE_USER_ACCOUNT = 'deleteUserAccount',
    HAS_JIMI_ACCOUNT = 'hasJimiAccount',
    GET_ESPACE_CLIENT_CUSTOM_TOKEN= 'getEspaceClientCustomToken',
    REVOKE_ESPACE_CLIENT_REFRESH_TOKEN = 'revokeSalesForceRefreshToken'
}

export enum USER_MANAGEMENT_ACTIONS {
    RESET_PASSWORD = 'resetPassword',
    VERIFY_EMAIL = 'verifyEmail'
}

export enum USER_MANAGEMENT_URL_PARAMETERS_NAMES {
    ACTION_CODE = 'oobCode',
    MODE = 'mode',
    EMAIL = 'email'
}

export enum ENUM_LOGIN_ERROR {
    USER_CANCELED_DIALOG = '4201' 
}

export enum ENUM_FIREBASE_PROVIDER_ID {
    EMAIL = 'password',
    CUSTOM = 'custom',
    GOOGLE = 'google.com'
}

export enum MODAL_ID_LIST {
    TOAST_ALERT_CREATE_PAGE_SUCCESS,
}