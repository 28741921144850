import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { PATHS_CONFIG } from 'src/config/paths.config';

@Component({
  selector: 'app-footer-legal-notice',
  templateUrl: './footer-legal-notice.component.html',
  styleUrls: ['./footer-legal-notice.component.scss'],
})
export class FooterLegalNoticeComponent implements OnInit {

  constructor(
    private router:Router,
    private logger: NGXLogger
    ) { }

  ngOnInit() {}

  openPolitique() {
    this.router.navigate([PATHS_CONFIG.CGU_PATH]).catch(err => this.logger.error(err));
  }

  openPrivacyPolicy() {
    this.router.navigate([PATHS_CONFIG.PRIVACY_POLICY_PATH]);
  }

}
