export const LABELS = {
    login_label_page_title: 'Connexion',
    login_label_sign_in_btn: 'Connexion avec veolia.com',
    login_label_separator: 'OU',
    login_label_subscribe: `CRÉER UN COMPTE`,
    login_label_email_sign_in_btn: 'Connexion avec une adresse e-mail',
    login_label_register_link: 'S\'INSCRIRE',
    login_espace_client_label: 'Votre espace client',
    login_google_label: 'Connexion avec veolia.com',
    login_label_footer_1: 'En continuant, vous acceptez nos ',
    login_label_footer_2: 'Conditions Générales d\'Utilisation',
    login_label_footer_privacy_policy: 'Politiques de Confidentialité',
    login_label_user_has_account_question: `Vous n'avez pas encore de compte ?`,
    login_form_label_email: 'E-mail',
    login_form_label_pwd: 'Mot de passe',
    login_form_label_btn: 'Se connecter',
    login_form_label_pwd_oublie : 'Mot de passe oublié ?',
    login_form_error_title: 'Erreur authentification',
    login_error_title: 'Erreur',
    login_signinwithgoogle_error_title: 'Erreur authentification',
    login_signinwithgoogle_error_message: `Une erreur s'est produite lors de l'authentification. Veuillez vérifier votre connexion internet, rafraichir la page, recommencer ultérieurement. Si le problème persiste, nous vous prions de contacter le support JIMI.`,
    app_alert_back_button_exit_msg: 'Êtes-vous sûr de vouloir quitter l\'application ?',
    app_alert_modif_statut_grl: 'Le créateur de l’alerte sera notifié de ce changement.',
    app_alert_creation_demande_rock_ouverte: 'Une demande client ouverte sera créée sur ROCK.',
    app_alert_creation_demande_rock_fermee: 'Une demande client fermée sera créée sur ROCK.',
    app_alert_modif_statut_admin: 'Le créateur de l\'alerte sera notifié de ce changement et l\'automatisation avec SIGMA sera rompue.',
    app_alert_modif_statut: 'Confirmez vous la modification du statut à %statut% ?',
    app_alert_modif_statut_key:'%statut%',
    error_creation_demande_rock: 'Une indisponibilité technique rend impossible l\'auto remplissage de la demande ROCK. ID externe de l\'alerte: ',
    error_creation_demande_rock_redirect: 'La demande Rock a bien été créée. Mais vos paramètres semblent empêcher l\'ouverture de cette demande.',
    app_toast_back_button_exit_msg: 'Appuyez encore sur le bouton précédent pour quitter',
    menu_add_to_homescreen: 'Ajouter à l\'écran d\'accueil',
    menu_logout: 'Se déconnecter',
    menu_version_prefix: 'version',
    menu_tutorial: `Conseils d'utilisation`,
    menu_contact_us: 'Une suggestion ?',
    manage_my_account: 'Mon compte',
    page_title_home: 'Accueil',
    confirm_title: 'CONFIRMER',
    confirm_ok: 'OUI',
    confirm_ko: 'NON',
    deconnexion_title: 'Déconnexion',
    error_login_title: 'ERREUR AUTHENTIFICATION',
    error_login_invalid_email: 'Cet utilisateur n\'est pas autorisé !',
    error_login_message: 'Une erreur s\'est produite pendant l\'authentification',
    error_get_current_position: 'ERREUR POSITION',
    error_global_http_interceptor: 'Une erreur est survenue. Veuillez réessayer plus tard.',
    error_401_unauthorized: `Une erreur d'authentification ou de droit d'accès est survenue. Nous vous invitons à vous déconnecter ou à relancer l'application. En cas de difficultés, n'hésitez pas à contacter le support.`,
    error_updating_rtdb_title: `ERREUR SERVEUR`,
    error_updating_rtdb_message: 'Une erreur est survenue. Veuillez vous déconnecter et vous reconnecter.',
    error_notification_permission_not_granted_title: `AUTORISATION NOTIFICATION`,
    error_notification_permission_not_granted_message: `Vous n'avez pas autorisé la réception de notifications. Celle-ci est nécessaire pour le bon fonctionnement de l'application. Veuillez les autoriser, puis relancer l'application.`,
    error_retrieving_messaging_token_title: `ERREUR`,
    error_retrieving_messaging_token_message: `Une erreur est survenue. Veuillez vous déconnecter et vous reconnecter à l'application.`,
    file_size_invalid: 'Le fichier dépasse la taille maximum autorisée ({0} octets).',
    file_type_invalid: 'Type de fichier invalide ! {0} est attendu.',
    error_upload_file: 'ERREUR UPLOAD',
    upload_success_title: 'RESULTATS UPLOAD',
    alert_label_dismiss: 'Fermer',
    searchbar_placeholder_location_without_focus: 'Saisissez votre recherche',
    searchbar_placeholder_location_with_focus: 'Adresse, commune',
    searchbar_placeholder_location_adresse: 'Rechercher une adresse',
    searchbar_geoloc: 'Votre position',
    map_toast_geolocation_denied: 'Vous n\'avez pas activé la géolocalisation',
    autocomplete_err_no_geometry: 'Aucun lieu trouvé pour l\'entrée : \'{0}\'',
    map_label_resume_more_info: 'PLUS D\'INFOS',
    map_label_link_atlas: 'ATLAS',
    map_toast_no_visible_marker: 'Il n\'y a aucune intervention visible sur la carte',
    map_toast_too_many_items_for_list: 'Affinez votre recherche pour visualiser la liste',
    map_toast_geolocation_not_calculated: 'Il n\'a pas été possible de calculer votre position.',
    map_toast_intervention_resume_data_null: 'Aucune donnée trouvée',
    map_attach_alert: 'RATTACHER UNE ALERTE',
    map_attach_alert_orpheline: 'CRÉER UNE ALERTE',
    map_hors_perimetre_sedif: 'Cette zone n’est pas couverte par Veolia Eau d’Ile-de-France',
    tabsbar_label_map: 'Carte',
    tabsbar_label_list: 'Vue par liste',
    tabsbar_label_notif: 'Notifications',
    tabsbar_label_alertes: 'Mes alertes',
    tabsbar_toast_no_action_attached: 'Aucune action associée à cet onglet !',
    error_map_get_markers: 'ERREUR MARQUEURS INTERVENTIONS',
    error_place_details_title: 'ERREUR REQUETE DETAILS LIEU',
    error_place_details_default: 'La requête de détails de lieu a échoué',
    error_place_details_ERROR: 'Un problème est survenu lors de la communication avec les serveurs Google',
    error_place_details_INVALID_REQUEST: 'Cette requête n\'est pas valide.',
    error_place_details_OVER_QUERY_LIMIT: 'La page Web a dépassé son quota de requêtes.',
    error_place_details_NOT_FOUND: 'Le point géographique référencé n\'a pas été trouvé dans la base de données Places.',
    error_place_details_REQUEST_DENIED: 'La page Web n\'est pas autorisée à utiliser PlacesService.',
    error_place_details_UNKNOWN_ERROR: 'La requête PlacesService n\'a pas pu être traitée en raison d\'une erreur de serveur. Si vous essayez à nouveau, la requête pourrait aboutir.',
    error_place_details_ZERO_RESULTS: 'Aucun résultat n\'a été trouvé pour cette requête.',
    filter_statut_btn_label_a_planifier : 'A planifier',
    filter_statut_btn_label_programme : 'Programmé',
    filter_statut_btn_label_en_cours : 'En cours',
    filter_statut_btn_label_termine : 'Terminé',
    filter_type_btn_label_expl : 'Exploitation',
    filter_type_btn_label_canna : 'Canalisation',
    filter_type_btn_label_fuit : 'Fuites',
    filter_type_btn_label_tier : 'Tiers',
    filter_type_btn_label_domm : 'Dommages',
    filter_type_btn_label_sedi : 'SEDIF',
    filter_arreteau_label_header: 'Interventions avec arrêt d’eau',
    filter_arreteau_label_text: 'Afficher uniquement les interventions avec arrêt d’eau aux statuts',
    filter_arreteau_label_text_2: 'ou',
    filter_arreteau_label_text_en_cours: 'en cours',
    filter_arreteau_label_text_programme: 'programmé',
    filter_alerte_label_header: 'Mes alertes',
    filter_alert_type_btn_label_envi: 'Env. chantier',
    filter_alert_type_btn_label_bipi: 'Borne inc.',
    filter_alerte_statut_btn_label_non_traite: 'Non traitée',
    filter_alerte_statut_btn_label_pris: 'Prise en compte',
    filter_alerte_statut_btn_label_traite: 'Traitée',
    filter_alerte_statut_btn_label_non_traitable: 'Non traitable',
    filter_tabs_btn_label_annuler: 'ANNULER',
    filter_tabs_btn_label_appliquer: 'APPLIQUER',
    filter_inter_label_header: 'Interventions',
    filter_label_statut: 'Statuts',
    filter_label_typologie: 'Typologies',
    separator_date_hour: ' à ',
    ae_no_data: 'Il n\'y a pas d\'arrêt d\'eau rattaché à cette intervention',
    alert_no_data: 'Il n\'y a pas d\'alerte rattachée à cette intervention',
    alert_create_title: 'Rattacher une alerte',
    alert_orpheline_create_title: 'Créer une alerte',
    alert_type_grid_label: 'Choisir une typologie*',
    alert_create_btn_add_alert: 'ENVOYER',
    alert_create_success_toast_message: 'Votre alerte a été créée avec succès. Nous vous en remercions !',
    alert_create_error_message: `Une erreur est survenue lors de la création de l'alerte. Veuillez recommencer, essayer plus tard, ou contacter le support.`,
    alert_create_one_or_many_image_upload_error_toast_message: `Un problème est survenu lors de l'upload d'une ou plusieurs images.`,
    alert_description_area_placeholder_default: 'Saisir une description*',
    alert_description_area_toast_invalid_length: 'Veuillez ne pas dépasser la limite de caractères',
    alert_description_area_toast_emoji_invalid_input: 'La saisie d\'émoticones n\'est pas autorisée',
    alerte_detail: 'Voir le détail',
    alerte_email_objet: 'Besoins d\'informations concernant l\'alerte que vous avez signalée',
    alerte_statut_edit: 'Modifier le statut',
    alert_file_selector_add_btn_text: 'Ajouter de 1 à {0} photos*',
    alert_file_selector_error_add_image_thumbnail_title: 'ERREUR AJOUT VIGNETTE',
    alert_file_selector_error_add_image_thumbnail_message: 'Une erreur est survenue lors d\'un ajout de vignette',
    alert_file_selector_max_number_warning: 'Ajouter de 1 à {0} photos',
    alert_file_selector_file_type_warning: 'Les formats non valides n\'ont pas été chargés. Seuls les formats png et jpg sont acceptés.',
    alert_file_selector_compression_not_supported: 'Ce navigateur ne supporte pas la compression d\'image',
    alert_file_selector_delete_confirm_msg: 'Voulez-vous vraiment supprimer cette image ?',
    alert_file_selector_error_file_compression: 'Une erreur est survenue durant la compression d\'un fichier',
    alerte_success_update: 'Modification effectuée',
    alerte_update_error: 'Erreur mise à jour statut alerte',
    alerte_not_found: 'Alerte non trouvée',
    alerte_info_cancel: 'Annuler',
    alerte_info_repos_fuite: 'Nos équipes se reposent. Pour remonter une fuite, veuillez contacter le service Urgence Fuite.',
    alerte_adresse_incomplete: 'Adresse complète nécessaire',
    alerte_res_non_autorise: 'Contenu non autorisé',
    alerte_non_autorise: 'Vous n\'êtes pas autorisé à accéder à ce contenu.',
    detail_label_link_sigma: 'SIGMA',
    ae_rue_impact_label_debut: 'Du',
    ae_rue_impact_label_fin: 'au',
    ae_rue_impact_label_debut_only: 'A partir du',
    ae_rue_impact_label_fin_only: 'Jusqu\'au',
    ae_rue_impact_circ_label_deb: 'De',
    ae_rue_impact_circ_label_fin: 'à',
    ae_rue_impact_circ_label_sans_impact: `Pas d'impact`,
    mes_alertes_no_data: 'Vous n\'avez pas remonté d\'alerte',
    mes_alertes_title: 'Mes alertes',
    mes_notifications_title: 'Notifications',
    mes_notifications_no_data: `Vous n'avez pas de notification`,
    alert_description_title: 'Modifier le commentaire',
    alert_description_ko: 'Annuler',
    alert_description_ok: 'ok',
    bandeau_tab_label_detail: 'TRAVAUX',
    bandeau_tab_label_arreteau: 'ARRÊT D\'EAU',
    bandeau_tab_label_alerte: 'ALERTE',
    alerte_message_intervention_not_found_title: 'INTERVENTION INEXISTANTE',
    alerte_message_intervention_not_found_message: 'L\'intervention associée à cette alerte n\'existe plus.',
    alerte_message_obsolete_title: 'Alerte obsolète',
    alerte_message_obsolete_message: 'Cette alerte est au statut traité depuis plus de 1 mois',
    login_title_error_authen_google_group: 'Erreur authentification',
    login_message_error_authen_google_group: `Vous n'êtes pas autorisé à utiliser cette application.`,
    notification_card_header: 'Alerte',
    usr_mgmt_error_link_title: `Lien erroné`,
    usr_mgmt_error_link_missing_parameters: `Un ou plusieurs paramètres requis sont absents.`,
    lost_password_label_page_title: `Connexion`,
    lost_password_txt: `Entrez votre adresse E-mail ci-dessous et nous vous enverrons un lien pour réinitialiser votre mot de passe`,
    lost_password_label_btn_send_link: `Envoyer`,
    lost_password_form_label_email: `Adresse E-mail`,
    lost_password_success_txt: `Un E-mail de réinitialisation de votre mot de passe a été envoyé à l’adresse mail {0}. Veuillez consulter votre boîte mail.`,
    lost_password_success_popup_dismiss_btn: `OK`,
    reset_password_label_page_title: `Réinitialiser votre mot de passe`,
    reset_password_txt_invalid_code: `Nous sommes désolés, votre demande de nouveau mot de passe n'est plus valide pour `,
    reset_password_label_action_link_cancel: `Annuler`,
    reset_password_label_action_link_resend_link: `Renvoyer le lien`,
    reset_password_form_label_new_password: `Nouveau mot de passe`,
    reset_password_form_label_confirm_new_password: `Confirmation du mot de passe`,
    reset_password_label_btn_update_password: `Réinitialiser`,
    reset_password_txt: `Saisissez un nouveau mot de passe`,
    reset_password_txt_password_min_length: `(6 caractères minimum)`,
    reset_password_resend_link_invalid_email: `Adresse E-mail invalide.`,
    reset_password_label_link_go_to_login_page: `Aller sur la page de connexion`,
    reset_password_error_popup_invalid_form_title: `Erreur de saisie.`,
    reset_password_error_popup_mismatched_password_msg: `Veuillez entrer une confirmation du mot de passe identique au nouveau mot de passe.`,
    reset_password_error_popup_invalid_form_msg: `Saisie invalide.`,
    reset_password_error_popup_error_reset_title: `Erreur réinitialisation mot de passe.`,
    reset_password_success_txt: `Votre mot de passe a bien été modifié`,
    reset_password_success_return_home_txt: `Vous pouvez dès à présent`,
    reset_password_success_return_home_link_txt: `retourner sur JIMI`,
    cgu_title: `Conditions générales d'utilisations`,
    privacy_policy_title: `Politique de confidentialité`,
    update_password_label_page_title: `Modification du mot de passe`,
    alerte_trouvee: `Alerte trouvée`,
    alerte_ratachee: `rattachée à une intervention programmée`,
    create_account_title: 'Inscription',
    create_account_email: 'E-mail*',
    create_account_lastName: 'Nom*',
    create_account_firstName: 'Prénom*',
    create_account_password: 'Mot de passe*',
    create_account_password_rule: 'Votre mot de passe doit contenir au moins 8 caractères incluant 1 lettre en majuscule, 1 lettre en minuscule, 1 chiffre et 1 caractère spécial.',
    create_account_password_confirmation: 'Confirmation du mot de passe*',
    create_account_registration_button: 'S\'inscrire',
    create_account_have_already_jimi_account: 'Vous avez déjà un compte ?',
    create_account_connect_with_already_jimi_account: 'SE CONNECTER',
    create_account_email_error_msg: 'Veuillez entrer une adresse E-mail valide.',
    create_account_complete_field_error_msg: 'Veuillez compléter ce champ.',
    create_account_phone_error_msg: 'Veuillez saisir un numéro de téléphone valide.',
    create_account_password_required_error_msg: 'Veuillez saisir un mot de passe.',
    create_account_valid_password_error_msg: 'Votre mot de passe doit contenir au moins 8 caractères incluant 1 lettre en majuscule, 1 lettre en minuscule, 1 chiffre et 1 caractère spécial.',
    create_account_password_confirmation_required_error_msg: 'Veuillez confirmer votre mot de passe.',
    create_account_similar_passwords_error_msg: 'Veuillez saisir deux mots de passe identiques.',
    create_account_with_success: `Vous allez recevoir un E-mail pour finaliser la création de votre compte. Il vous faudra cliquer sur le lien pour confirmer votre adresse E-mail et accéder à l'application`,
    create_account_success_popup_dismiss_btn: `OK`,
    create_account_error_popup_title: `Erreur création du compte.`,
    create_account_error_popup_msg: `Une erreur est survenue lors de la création du compte.`,
    create_account_jimi_acount_already_exist: 'Un compte JIMI est déjà associé à cette adresse E-mail.',
    create_account_phone_number_already_exist: 'Ce numéro de téléphone est déjà utilisé par un autre compte.',
    intermediate_page_logIn_button: 'Se connecter',
    intermediate_page_logIn_description: 'Connectez-vous pour rester informé, créer des alertes et tout savoir sur leur suivi.',
    manage_account_title: 'Mon compte',
    manage_account_save_button: 'Sauvegarder',
    manage_account_update_password_button: 'Modifier votre mot de passe',
    manage_account_delete_button: 'Supprimer votre compte',
    manage_account_name: 'Prénom et nom*',
    manage_account_success_msg: 'Vos modifications ont bien été prises en compte',
    manage_account_alert_change_email_msg: 'La mise à jour de ces données nécessite de vous déconnecter. Souhaitez-vous continuer ?',
    manage_account_alert_change_email_title: 'Information',
    update_password_account_title: 'Modifier votre mot de passe',
    update_password_account_button: 'Modifier',
    update_password_account_new_password_label: 'Nouveau mot de passe',
    update_password_account_confirmation_password_label: 'Confirmer le nouveau mot de passe',
    update_password_account_old_password_label: 'Ancien mot de passe',
    update_password_account_forgetten_old_password_description: 'Si vous avez oublié votre ancien mot de passe, veuillez-vous déconnecter et cliquer sur "Mot de passe oublié".',
    update_password_not_valid_old_password_error_msg: 'Veuillez renseigner votre ancien mot de passe.',
    update_password_with_success: 'Votre mot de passe a bien été modifié',
    update_password_error_msg: 'Une erreur est survenue lors de la modification de mot de passe.',
    update_password_reauthentication_with_old_password_error_msg: 'Une erreur est survenue lors de la reauthentification avec l\'ancien mot de passe.',
    verify_email_page_title: `Valider votre compte`,
    verify_email_error_title: `Erreur vérification email`,
    verify_email_error_unknown: `Une erreur inconnue est survenue. Veuillez recommencer.`,
    verify_email_toast_success: `Votre compte est validé. Vous pouvez vous connecter à l’application`,
    verify_email_toast_warning_already_verified: `Votre compte est déjà validé. Connectez-vous pour continuer`,
    verify_email_txt_invalid_code_mail_pending: `Nous sommes désolés, vous ne pouvez pas encore vous connecter. Votre compte n'a pas encore été validé ou le lien de validation n'est plus valide pour`,
    verify_email_label_action_link_cancel: `Annuler`,
    verify_email_label_action_link_resend_link: `Renvoyer le lien`,
    verify_email_error_invalid_code_user_removed: `Nous sommes désolés. Votre compte a été supprimé car il n’a pas été validé dans les temps. Vous pouvez en créer un nouveau depuis le bouton "S’inscrire".`,
    delete_account_alert_title: 'Supprimer votre compte ?',
    delete_account_alert_description: 'En supprimant votre compte, vous n\'aurez plus accès au suivi de vos alertes. Souhaitez-vous continuer ?',
    delete_account_taost_success: `Votre compte a bien été supprimé`,
    intervention_detail_status_label: 'Statut',
    intervention_detail_period_label: 'Période',
    intervention_detail_company_label: 'Entreprise(s)',
    intervention_detail_service_label: 'Service',
    intervention_detail_application_label: 'Applications métiers',
    intervention_detail_communes_impactees_label: 'Commune(s) impactée(s)',
    intervention_detail_rue_impactees_label: 'Rue(s) impactée(s)',
    intervention_detail_type_travaux_label: 'Type de travaux',
    intervention_detail_longueur_label: 'Longueur',
    intervention_detail_logement_label: 'Logement(s) concerné(s)',
    intervention_detail_circulation_label: 'Impact(s) sur la circulation',
    intervention_detail_moyen_secours_label: 'Moyen(s) de secours en eau',
    intervention_detail_nbr_foyers_label: 'Nombre de logement(s) impacté(s)',
    intervention_detail_createur_label: 'Créateur de l\'Arrêt d\'eau',
    alert_detail_address: 'Localisation',
    alert_detail_status: 'Statut',
    alert_detail_creation_date: 'Date de création',
    alert_detail_comment: 'Commentaire',
    alert_detail_creator_info: 'Coordonnées de l\'alerteur',
    app_toast_activate_localisation_head: 'JIMI',
    app_toast_activate_localisation_message: 'Veuillez activer la localisation',
    manage_account_subscribe_msg: 'En cochant cette case, vous acceptez que Veolia Eau d\'Île-de-France vous transmette des informations sur le Service de l\'eau.',
    create_alert_phone_description: 'Pour traiter votre remontée, nos services peuvent être amenés à vous contacter',
    tutorial_labels: {
        slide_1: {
            title: 'Suivez le guide !',
            text: 'Bienvenue sur l\'application JIMI. Elle permet de se tenir informé en temps réel des interventions réalisées sur le réseau d’eau potable du territoire du SEDIF, mais aussi de signaler au Service de l’eau des événements qui semblent inhabituels : barrières déplacées, fuites d\'eau sur la voirie, ouvertures inhabituelles de bornes à incendie… Par des gestes simples, nous pouvons tous être acteurs sur notre territoire !'
        },
        slide_2: {
            title: 'Toutes les interventions en un coup d\'oeil ',
            text: 'Les interventions sont symbolisées sur la carte par différents pictogrammes carrés. Leur couleur varie en fonction de leur statut. Quand une pastille rouge s’affiche sur un pictogramme, cela indique qu’un arrêt d’eau est prévu ou en cours à cet endroit.'
        },
        slide_3: {
            title: 'En savoir plus sur une intervention',
            text: 'En appuyant sur un pictogramme sur la carte, vous pouvez accéder à des informations complémentaires sur le chantier concerné : adresse, durée des travaux…'
        },
        slide_4: {
            title: 'En savoir plus sur un arrêt d\'eau',
            text: 'L\'onglet “Arrêt d\'eau” permet d’obtenir plus d\'informations sur celui-ci, par exemple sur les moyens de secours en eau mis en place.'
        },
        slide_5: {
            title: 'Accédez facilement aux informations qui vous \nintéressent',
            text: 'Grâce aux filtres, vous pouvez affiner votre recherche pour obtenir plus de précisions sur un chantier.'
        },
        slide_6: {
            title: 'Faites un geste citoyen : créer une alerte !' ,
            text: 'Vous remarquez une fuite d\'eau, une ouverture inhabituelle d\'une borne à incendie ou du matériel déplacé ? Signalez-le nous en appuyant sur le bouton "Créer une alerte". Vous devrez alors vous connecter.'
        },
        slide_7: {
            title: 'Restez informé !',
            text: 'En vous authentifiant, vous pourrez bénéficier d\'un suivi de votre alerte : l\'alerte apparaît sur votre carte et vous êtes tenu informé de sa résolution.'
        },
    },
    env_chantier_decription: 'Vous avez repéré nos barrières sur la chaussée, du matériel loin d’un chantier ? Vous avez une suggestion à nous faire ? N’hésitez pas à nous tenir informés. Nous interviendrons dans les plus brefs délais pour toujours améliorer la qualité de nos services.',
    fuite_decription: 'Vous avez constaté une fuite d’eau sur la voirie ? N’hésitez pas à nous la signaler. Ainsi vous nous permettrez d’intervenir au plus vite. Nous pouvons tous agir par des gestes simples sur notre territoire pour préserver la ressource.',
    usage_frauduleux_decription: 'Les bornes à incendie sont essentielles aux pompiers. Leur ouverture par une personne non habilitée peut être dangereuse et causer des dégâts. Si vous constatez un usage inhabituel d’une borne, n’hésitez pas à nous le signaler. Soyons tous acteurs sur notre territoire !'
};
