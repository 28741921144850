import { Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-check-button-grid',
  templateUrl: './check-button-grid.component.html',
  styleUrls: ['./check-button-grid.component.scss'],
})
export class CheckButtonGridComponent implements OnInit {

  btnsTypeArray: string[] = [];
  btnsType: string [] = [];
  btnsTypeDefault: string [] = ['left', 'middle', 'right'];
  btnsTypeRow: string [] = ['left bottom-row', 'middle bottom-row', 'right bottom-row'];

  @Input() arrayElements: any[];
  @Input() btnsArrayStatus: boolean[];

  @Output() currentElementChange = new EventEmitter();

  constructor(public zone: NgZone, public modalCtrl: ModalController,
              private logger: NGXLogger) {
    this.logger.debug('Hello CheckButtonGridComponent Component');
    this.logger.debug('arrayElements' + JSON.stringify(this.arrayElements));
  }

  ngOnInit() {}

  
  setArrayElements(ae: any) {
    this.arrayElements = ae;
  }

  getArrayElements() {
    return this.arrayElements;
  }

  setBnsArrayStatus(bas: any) {
    this.btnsArrayStatus = bas;
  }

  getBnsArrayStatus() {
    return this.btnsArrayStatus;
  }

  onBtnClick(index: number) {
    // this.toggleStatusOfBtnsArrayElement(index);
    this.updateBtnStatusArray(index);
    this.emitElementChangeEvent(index);
  }

  private emitElementChangeEvent(index) {

    const self = this;
    this.zone.run(() => {
      this.currentElementChange.emit(self.arrayElements[index].codeElement);
    });
  }

  private updateBtnStatusArray(index: number) {

    this.btnsArrayStatus[index] = true;
    for (let i = 0; i < this.btnsArrayStatus.length; i++) {
      if (index !== i) {
        this.btnsArrayStatus[i] = false;
      }
    }

  }

}
