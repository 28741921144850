import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { PAGES_CONFIG } from '../../../config/pages.config';
import { LABELS } from '../../../config/labels.config';
import { Validators, FormBuilder, FormGroup, ValidatorFn, AbstractControlOptions, FormControl } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { AlertService } from '../../../app/services/alert/alert.service';
import { Router } from '@angular/router';
import { PATHS_CONFIG } from '../../../config/paths.config';
import { APP_CONFIG } from '../../../config/app.config';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
})
export class PasswordFormComponent implements OnInit {

  
  resetPasswordForm: FormGroup = this.formBuilder.group({});
  @Input() email;
  @Input() cameFromEmail: boolean;
  @Output() updatePassword = new EventEmitter<string>();

  newPassword = '';
  confirmNewPassword = '';

  isNewPasswordInvalid = false;
  isConfirmNewPasswordInvalid = false;

  private passwordRegex = new RegExp(APP_CONFIG.REGEX_PWD);
  
  constructor(
    private formBuilder: FormBuilder,          
    private logger: NGXLogger
  ) {

  }

  ngOnInit() {
    
  }

  resetPassword() {
    this.logger.debug('PasswordFormComponent call to resetPassword()');
    this.validateResetPasswordForm()
    .then(() => {
      this.logger.debug('form ok');
      this.updatePassword.emit(this.newPassword);
    })
    .catch((err) => {
      this.logger.error(err);
    })
  }

  onKeyUp(evt, controlKey:string) {
    if (evt.keyCode !== APP_CONFIG.KEYCODE_ENTER) {
      switch(controlKey) {
        case 'newPassword':
          this.isNewPasswordInvalid = false;
          break;
        case 'confirmNewPassword':
          this.isConfirmNewPasswordInvalid = false;
          break;
      }
    }
  }

  private validateResetPasswordForm(){
    this.logger.debug('PasswordFormComponent call to validateResetPasswordForm()');
    return new Promise((resolve, reject) => {

      this.isNewPasswordInvalid = !this.passwordRegex.test(this.newPassword);
      this.isConfirmNewPasswordInvalid = this.newPassword !== this.confirmNewPassword;

      if (!this.isNewPasswordInvalid && !this.isConfirmNewPasswordInvalid) {
          resolve();
      } else {
        reject(LABELS.reset_password_error_popup_invalid_form_msg);
      }
    });
  }

}
