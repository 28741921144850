import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PAGES_CONFIG } from '../../../config/pages.config';
import { NavController, IonSlides } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { MediaData } from '../../../app/services/rest-alerte/model/mediaData';
import { Router, ActivatedRoute } from '@angular/router';
import { PATHS_CONFIG } from '../../../config/paths.config';
import { NavExtrasService } from 'src/app/services/nav-extra/nav-extras.service';

@Component({
  selector: 'app-carrousel-images',
  templateUrl: './carrousel-images.component.html',
  styleUrls: ['./carrousel-images.component.scss'],
})
export class CarrouselImagesComponent implements OnInit {

  @Input() mediaData: MediaData[];
  @Input() initialIndex: number;
  @Input() lengthSlides: number;

  @ViewChild('slides') slides: IonSlides;

  currentIndex = 1;

  slideOpts;

  constructor(public navCtrl: NavController,
              private logger: NGXLogger,
              private router: Router,
              private route: ActivatedRoute,
              private navExtrasService: NavExtrasService) {
    this.logger.debug('Hello CarrouselImagesComponent Component');
  }

  
  ngOnInit() {
    this.slideOpts = {
      initialSlide: this.initialIndex,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
      }
    };
  }
  

  
  async slideChanged() {
    const activeIndex = await this.slides.getActiveIndex();
    if (activeIndex < this.lengthSlides && this.currentIndex > 0) {
      this.currentIndex = activeIndex + 1;
      this.logger.debug('Current index is', this.currentIndex);
    }
  }

  zoomImage(image) {

    this.logger.debug('CarrouselImagesComponent zoomImage()');

    const navigationExtras= {
      mediaData: this.mediaData,
      currentIndex: this.currentIndex,     
    };
    this.navExtrasService.setExtras(navigationExtras, true);
    
    //this.router.navigateByUrl(PATHS_CONFIG.IMAGE_ZOOM_PATH);
    this.router.navigate(['.' + PATHS_CONFIG.IMAGE_ZOOM_PATH], { relativeTo: this.route })
    .catch(err => this.logger.error(err));
   }
   
}
