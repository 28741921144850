export const PAGES_CONFIG = {
    LOGIN_PAGE: 'LoginPage',
    TABS_PAGE: 'TabsPage',
    MENU_PAGE: 'GlobalMenuPage',
    ALERTE_DETAIL_PAGE: 'AlerteDetailPage',
    DETAIL_PAGE: 'DetailPage',
    MAP_PAGE: 'MapPage',
    IMAGE_ZOOM_PAGE: 'ImageZoomPage',
    BANDEAU_PAGE: 'BandeauPage',
    FILTER_PAGE: 'FilterPage',
    ARRET_EAU_PAGE: 'ArretEauPage',
    ALERTE_PAGE: 'AlertePage',
    NOTIFICATION_PAGE: 'NotificationPage',
    MES_ALERTES_PAGE: 'MesAlertesPage',
    LIST_PAGE: 'ListPage',
    ALERTE_CREATE_PAGE: 'AlertCreatePage',
    ALERTE_STATUT_PAGE: 'AlerteStatutPage',
    ALERTE_DESCRIPTION_PAGE: 'AlerteDescriptionPage',
    LOST_PASSWORD_PAGE: 'LostPasswordPage',
    RESET_PASSWORD_PAGE: 'ResetPasswordPage',
    ALERTE_CREATE_PAGE_SELECTOR: 'page-alert-create',
    ALERTE_INFO_PAGE_SELECTOR: 'alerte',
    CGU_PAGE: 'CguPage',
    UPDATE_PASSWORD_PAGE: 'UpdatePasswordPage',
    PRIVACY_POLICY_PAGE: 'privacyPolicyPage',
    TEST_RECAPTCHA_PAGE: 'testRecaptchaPage',
    MANAGE_ACCOUNT_PAGE: 'manageAccountPage',
    UPDATE_USER_PASSWORD_PAGE: 'updateUserPasswordPage'
};
