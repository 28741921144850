import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { APP_CONFIG } from 'src/config/app.config';
import { ENUM_ALERTE_CODE_MODE } from 'src/config/enum.config';
import { LABELS } from 'src/config/labels.config';
import { PATHS_CONFIG } from 'src/config/paths.config';
import { AlertService } from '../alert/alert.service';
import { AuthService } from '../auth/auth.service';
import { MapService } from '../map/map.service';
import { NavExtrasService } from '../nav-extra/nav-extras.service';
import { RestAlerteService } from '../rest-alerte/rest-alerte.service';
import { RoutingService } from '../routing/routing.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanDeactivate<any> {

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private logger: NGXLogger,
              private alertService: AlertService,
              private mapService: MapService,
              private restAlerteService: RestAlerteService,
              private navExtras: NavExtrasService,
              private routingService: RoutingService,
              private storageService: StorageService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.logger.log('url guard : ', state.url);

    const user = await this.authService.isLoggedIn();

    // handle move to 'alerteDetail' page.
    // go to bandeau page if alerte is attached
    if (this.isAlerteDetailUrl(state)) {

      return this.handleAlerteDetailUrl(route, user);
      
    }
    else {
      if (user) {
        return true;
      } else {
        this.routingService.globalGoToLoginPage();
        return false;
      }

    }
  }

  async canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot) {
    this.logger.log('next url guard : ', nextState.url);
    this.logger.debug(' this.router.url ', this.router.url );

    // Cas où pop up de deconnexion est présente
    if (this.alertService.isAlerteActive()) {
      this.alertService.getAlert().dismiss().then(() => {
        this.alertService.setAlerteActive(false);
      }).catch(err => this.logger.error(err));
    }

    if (this.mapService.hasInterventionResume() && !nextState.url.includes(PATHS_CONFIG.BANDEAU_PATH)) {
      this.logger.log('hasInterventionResume')
      //this.events.publish(APP_CONFIG.EVENT_RESUME_CLEAR);
      history.pushState(undefined, 'Overlay', APP_CONFIG.MAP_URL);
    }
    return true;
  }

  private isAlerteDetailUrl(state) {
    return (state.url.indexOf('alerteDetail') > -1 || state.url.indexOf('alerte-detail') > -1) && (state.url.indexOf('bandeau') < 0);
  }


  // handle move to 'alerteDetail' page.
  // go to bandeau page if alerte is attached
  private handleAlerteDetailUrl(route, user): Promise<boolean> {

    this.logger.debug('AuthGuardService : Call to handleAlerteDetailUrl()');

    let idAlerte = route.paramMap.get('idAlerte');
    let idNotif = route.paramMap.get('idNotif');

    const navExtras = this.navExtras.getExtras();
    idAlerte = idAlerte || navExtras.idAlerte;
    idNotif = idNotif != undefined ? idNotif : navExtras.idNotif;
    
    return new Promise((resolve, reject) => {

      if (user) {
        // get alerte detail

        this.storageService.getUserToken().then(token => {

        this.restAlerteService.getAlerteDetail({
          'id': idAlerte,
        }, token)
        .then(response => {
          let isAlerteOrpheline = true;
          let idIntervention;
          if (response.msg !== LABELS.alerte_res_non_autorise) {
            const alerteDetail = response.data.alerteDetail;
            isAlerteOrpheline = alerteDetail.codeMode !== ENUM_ALERTE_CODE_MODE.ATTACHED;
            idIntervention = alerteDetail.idIntervention;
          } 
          
          const path = isAlerteOrpheline ? PATHS_CONFIG.ALERTE_DETAIL_PATH : PATHS_CONFIG.BANDEAU_PATH;
          const paramsInUrl = isAlerteOrpheline ? `/${idAlerte}` + (idNotif !== undefined ? `/${idNotif}` : '') : `/${idIntervention}`;
          const navExtraObj = isAlerteOrpheline ? {idAlerte, idNotif} : {idIntervention, idNotif};
          this.navExtras.setExtras(navExtraObj, true);
          this.router.navigate([path + paramsInUrl], { relativeTo : this.route});
          resolve(false);
        })
        .catch(err => {
          this.logger.error(err);
        });
      });
    } else {
        this.routingService.globalGoToLoginPage({idAlerte, idNotif});
        resolve(false);
      }
    });
  }
}
