import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { APP_CONFIG } from '../../../config/app.config';
import { CacheEntry } from './model/cacheEntry';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  cacheMap = new Map<string, CacheEntry>();

  /*
   * Méthode qui permet de vérifier si une requête est contenue dans le cache ou si elle a expirée.
   */
  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const body = req.body;
    const cached = this.cacheMap.get(JSON.stringify(body));

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < (Date.now() - APP_CONFIG.MAX_CACHE_AGE);

    return isExpired ? undefined : cached.response;
  }

  /*
   Méthode qui permet d'ajouter une entrée dans le cache.
   La clé correspond au corps de la requête.
  */
  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const body = req.body;
    const key = JSON.stringify(body);
    const entry = { url, body, response, lastRead: Date.now() };
    this.cacheMap.set(key, entry);
    this.deleteExpiredCache();
  }

  // Méthode qui permet de vérifier si on utilise un cache pour une requête à partir de son url.
  isCachable(url: string): boolean {
    const serviceName = url.split('_ah/api/jimi/v1/');
    if (serviceName !== null && serviceName.length > 1) {
      return APP_CONFIG.URL_CACHABLES.indexOf(serviceName[1]) !== -1;
    }

    return false;
  }

  // Méthode qui permet de supprimer du cache les valeurs qui sont expirées.
  private deleteExpiredCache() {
    const expired = Date.now() - APP_CONFIG.MAX_CACHE_AGE;
    this.cacheMap.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cacheMap.delete(JSON.stringify(expiredEntry.body));
      }
    });
  }

  updateCache(key, index, codePicto) {
    const cacheEntry = this.cacheMap.get(key);
    let hasAnElementBeenDeletedFromCache = false;
    if (cacheEntry) {
      if (codePicto !== undefined && cacheEntry.response.body.data[index]) {
        cacheEntry.response.body.data[index].codePicto = codePicto;
      } else {
        cacheEntry.response.body.data.splice(index, 1);
        hasAnElementBeenDeletedFromCache = true;
      }
      this.cacheMap.set(key, cacheEntry);
    }
    return hasAnElementBeenDeletedFromCache;
  }

  clearCache() {
    this.cacheMap.clear();
  }

}
