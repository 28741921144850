import { Component, OnInit, Input } from '@angular/core';
import { InformationLineOptions } from './model/informationLineOptions';

@Component({
  selector: 'app-information-line',
  templateUrl: './information-line.component.html',
  styleUrls: ['./information-line.component.scss'],
})
export class InformationLineComponent implements OnInit {

  anonymizedUserName: string;
  
  cssLeft = {
    seventy: 'w70',
    eighty: 'w80',
    hundred: 'w100',
  };

  lineOptions: InformationLineOptions = {
    cssClass: '',
  };

  // @Input() options: InformationLineOptions;
  @Input()
  set options(val: InformationLineOptions) {
    if (val) {
      this.lineOptions = val;
      this.lineOptions.cssClassLeft = this.calculateCssClassLeftValue(val.materialIconRight);
    }
  }

  constructor() { }

  ngOnInit() {
    this.anonymizedUserName = 'Utilisateur supprimé';
  }

  
  private calculateCssClassLeftValue(materialIconRight) {
    if (!materialIconRight) {
      return this.cssLeft.hundred;
    } else {
      if (materialIconRight.length === 2) {
        return this.cssLeft.seventy;
      }
      if (materialIconRight.length === 1) {
        return this.cssLeft.eighty;
      }
    }
  }


}
