import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'ctm-back-btn',
  templateUrl: './ctm-back-btn.component.html',
  styleUrls: ['./ctm-back-btn.component.scss'],
})
export class CtmBackBtnComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {}

  back(){
    this.location.back();
  }

}
