import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SearchBarService {

  inputLocation = '';
  showFilterIndicator = false;
  showSearchView:boolean;

  constructor(private logger: NGXLogger) {
    this.logger.debug('Hello SearchBarService Provider');
  }

  updateShowFilterIndicator(value) {
    this.showFilterIndicator = value;
  }

}
