import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { InitAuthGuardService } from './services/init-auth-guard/init-auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'global-menu/tabs', pathMatch: 'full' },
  { path: 'login', redirectTo: 'global-menu/tabs/tabs/auth/login', pathMatch: 'full' },
  { path: 'global-menu', canActivate:[InitAuthGuardService], loadChildren: './pages/global-menu/global-menu.module#GlobalMenuPageModule' },
  { path: 'alerteDetail/:idAlerte/:idNotif', canActivate: [AuthGuardService], loadChildren: './pages/alerte-detail/alerte-detail.module#AlerteDetailPageModule' },
  { path: 'bandeau/:idIntervention', loadChildren: './pages/bandeau/bandeau.module#BandeauPageModule' },
  { path: 'alerte-detail/:idAlerte', loadChildren: './pages/alerte-detail/alerte-detail.module#AlerteDetailPageModule' },
  { path: 'alerte-detail/:idAlerte/alerte-statut', loadChildren: './pages/alerte-statut/alerte-statut.module#AlerteStatutPageModule' },
  { path: 'alerte-detail/:idAlerte/image-zoom', loadChildren: './pages/image-zoom/image-zoom.module#ImageZoomPageModule' },
  { path: 'cgu', loadChildren: './pages/cgu/cgu.module#CguPageModule' },
  { path: 'alerte-detail/:idAlerte/:idNotif', loadChildren: './pages/alerte-detail/alerte-detail.module#AlerteDetailPageModule' },
  { path: 'alerte-detail/:idAlerte/:idNotif/alerte-statut', loadChildren: './pages/alerte-statut/alerte-statut.module#AlerteStatutPageModule' },
  { path: 'alerte-detail/:idAlerte/:idNotif/image-zoom', loadChildren: './pages/image-zoom/image-zoom.module#ImageZoomPageModule' },
  // { path: 'lost-password', loadChildren: './pages/lost-password/lost-password.module#LostPasswordPageModule' },
  { path: 'reset-password', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordPageModule' },
  // { path: 'login-with-email', loadChildren: './pages/login-with-email/login-with-email.module#LoginWithEmailPageModule' },
  { path: 'privacy-policy', loadChildren: './pages/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule' },
  { path: 'usr-mgmt', loadChildren: './pages/usr-mgmt/usr-mgmt.module#UsrMgmtPageModule' },
  { path: 'manage-account', loadChildren: './pages/manage-account/manage-account.module#ManageAccountPageModule' },
  { path: 'update-password', loadChildren: './pages/update-password/update-password.module#UpdatePasswordPageModule' },
  { path: 'tutorial', loadChildren: './pages/tutorial/tutorial.module#TutorialPageModule' },
  // { path: '**', redirectTo: 'global-menu/tabs'}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, relativeLinkResolution: 'corrected' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
