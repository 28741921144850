import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ENV } from '../../../config/env.config';
import { NetService } from '../net/net.service';
import { CommuneDetailResponse } from './model/communeDetailResponse';
import { CommuneResponse } from './model/communeResponse';
@Injectable({
  providedIn: 'root'
})
export class RestCommuneService {

  communesSedif: string[];

  constructor(public http: HttpClient,
              private logger: NGXLogger,
              private netService: NetService) {
    this.logger.debug('Hello RestCommuneService Service');
  }

  async getCommuneDataWithParams(token:string, params?): Promise<CommuneDetailResponse> {
    this.logger.debug('getting commune data');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.post(ENV.DATA_GATEWAY_URL + 'communeDetail', params, { headers })
    .toPromise() as Promise<CommuneDetailResponse>;
  }

  async getCommunes(token:string): Promise<CommuneResponse> {
    this.logger.debug('getting communes');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.get(ENV.DATA_GATEWAY_URL + 'communes', { headers })
    .toPromise() as Promise<CommuneResponse>;
  }

  isCommuneSedif(codePostal: string, country: string) {
    return new Promise<boolean>((resolve, reject) => {
      if (country && country.toLowerCase() !== 'france') {
        resolve(false);
      } 
      else {
        if (this.communesSedif) {
          for (const commune of this.communesSedif) {
            if (codePostal === commune) {
              resolve(true);
            }
          }
          resolve(false);
        } else {
          reject();
        }
      }
    });
  }

  getCommunesSedif() {
    return this.communesSedif;
  }

  setCommunesSedif(communesSedif: string[]) {
    this.communesSedif = communesSedif;
  }
}
