import { Component, OnInit, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
})
export class NoContentComponent implements OnInit {

  text: string;
  @Input() messageToShow: string;

  constructor(private logger: NGXLogger) {
    this.logger.debug(' Hello NoContentComponent');
  }

  ngOnInit() {}

}
