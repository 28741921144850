import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { PATHS_CONFIG } from 'src/config/paths.config';

@Injectable()
export class RoutingService {
  private history: string [] = [];
  private maxOfRoutesToKeepInHistory: number = 4;

  constructor(
    private router: Router,
    private logger: NGXLogger
  ) {}

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        /* we keep jsut a number of routes in the history */
        // this.history = [...this.history, urlAfterRedirects];
        this.history.unshift(urlAfterRedirects);
        this.history = this.history.slice(0,this.maxOfRoutesToKeepInHistory);
        // this.logger.debug('navigation history RoutingState',this.history);
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public globalGoToHomePage() {
    this.logger.debug('RoutingService globalGoToHomePage()');
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.MAP_PATH]).catch(err => this.logger.error(err));
  }

  public globalGoToNotificationPage() {
    this.logger.debug('RoutingService globalGoToNotificationPage()');
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.NOTIFICATION_PATH]).catch(err => this.logger.error(err));
  }

  public globalGoToMesAlertesPage() {
    this.logger.debug('RoutingService globalGoToMesAlertesPage()');
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.MES_ALERTES_PATH]).catch(err => this.logger.error(err));
  }

  public globalGoToTutoPage() {
    this.logger.debug('RoutingService globalGoToTutoPage()');
    this.router.navigate([PATHS_CONFIG.TUTORIAL_PATH]).catch(err => this.logger.error(err));
  }

  public globalGoToLoginPage(navExtras?) {
    this.logger.debug('RoutingService globalGoToLoginPage(). navExtras => ', navExtras);
    const ne = navExtras || {};
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.AUTH_PATH + PATHS_CONFIG.LOGIN_PATH, ne]).catch(err => this.logger.error(err));
  }

  public globalGoToLoginWithEmailPage(navExtras?) {
    this.logger.debug('RoutingService globalGoToLoginWithEmailPage(). navExtras => ', navExtras);
    const ne = navExtras || {};
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.AUTH_PATH + PATHS_CONFIG.LOGIN_WITH_EMAIL_PATH, ne]).catch(err => this.logger.error(err));
  }
}