import { NgModule } from '@angular/core';
import { LocalizationPipe } from './localization/localization.pipe';
import { EscapeHtmlPipe } from './escape-html/escape-html.pipe';
import { ProcessDateNotifPipe } from './process-date-notif/process-date-notif.pipe';
import { SeparatorDateHourPipe } from './intervention/intervention.pipe';
import { CapitalizeFirstLetterPipe } from './capitalize-first-letter/capitalize-first-letter.pipe';

@NgModule({
  declarations: [LocalizationPipe,
                 SeparatorDateHourPipe,
                 EscapeHtmlPipe,
                 ProcessDateNotifPipe,
                 CapitalizeFirstLetterPipe],
  imports: [],
  exports: [LocalizationPipe,
            SeparatorDateHourPipe,
            EscapeHtmlPipe,
            ProcessDateNotifPipe,
            CapitalizeFirstLetterPipe],
})
export class PipesModule {}
