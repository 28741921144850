import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent implements OnInit {


  text: string;
  TAG:string='MenuButtonComponent';

  constructor(private menuCtrl: MenuController,
              private logger: NGXLogger,
              private utilsService: UtilsService) {
    this.logger.debug('Hello MenuButtonComponent Component');
  }

  ngOnInit() {}

  menuToggle() {
    this.logger.debug('MenuButtonComponent menuToggle()');
    this.menuCtrl.toggle().catch(err => this.logger.error(err));
    // this.utilsService.historyPushThisHrefIfWeb(this.TAG);
    this.utilsService.historyPushThisHrefIfWeb(this.TAG);
  }

}
