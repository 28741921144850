import { NgModule } from '@angular/core';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { InterventionResumeComponent } from './intervention-resume/intervention-resume.component';
import { AlertDescriptionAreaComponent } from './alert-description-area/alert-description-area.component';
import { AlerteResumeComponent } from './alerte-resume/alerte-resume.component';
import { InformationLineComponent } from './information-line/information-line.component';
import { CarrouselImagesComponent } from './carrousel-images/carrousel-images.component';
import { CheckButtonGridComponent } from './check-button-grid/check-button-grid.component';
import { AlerteTypeButtonComponent } from './alerte-type-button/alerte-type-button.component';
import { AlertFilesSelectorComponent } from './alert-files-selector/alert-files-selector.component';
import { InterventionResumeBlockComponent } from './intervention-resume-block/intervention-resume-block.component';
import { MenuItemsListComponent } from './menu-items-list/menu-items-list.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { TabsBarComponent } from './tabs-bar/tabs-bar.component';
import { NoContentComponent } from './no-content/no-content.component';
import { LoginOptionsComponent } from './login-options/login-options.component';
import { LoginEmailFormComponent } from './login-email-form/login-email-form.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { ToastComponent } from './toast/toast.component';
import { AlertComponent } from './alert/alert.component';
import { FooterLegalNoticeComponent } from './footer-legal-notice/footer-legal-notice.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes.module';
import { ZoomPanDirective } from '../pages/image-zoom/zoomPanDirective';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatInputModule, MatFormFieldModule } from '@angular/material';
import {CtmBackBtnComponent} from './ctm-back-btn/ctm-back-btn.component';
import {CtmCloseBtnComponent} from './ctm-close-btn/ctm-close-btn.component';
import { IntermediateLoginComponent } from './intermediate-login/intermediate-login.component';


@NgModule({
  declarations: [SearchBarComponent,
                 InterventionResumeComponent,
                 AlertDescriptionAreaComponent,
                 AlerteResumeComponent,
                 InformationLineComponent,
                 CarrouselImagesComponent,
                 ZoomPanDirective,
                 CheckButtonGridComponent,
                 AlerteTypeButtonComponent,
                 InformationLineComponent,
                 AlertFilesSelectorComponent,
                 InterventionResumeBlockComponent,
                 MenuItemsListComponent,
                 MenuButtonComponent,
                 NavBarComponent,
                 TabsBarComponent,
                 NoContentComponent,
                 LoginOptionsComponent,
                 LoginEmailFormComponent,
                 PasswordFormComponent,
                 ToastComponent,
                 AlertComponent,
                 FooterLegalNoticeComponent,
                 CtmBackBtnComponent,
                 CtmCloseBtnComponent,
                 IntermediateLoginComponent
   ],
  // Components externes à importer
  imports: [ MatIconModule,
            FormsModule,
            ReactiveFormsModule,
            PipesModule,
            IonicModule,
            CommonModule,
            MatInputModule,
            MatFormFieldModule,
  ],
    // Exporters nos components
  exports: [SearchBarComponent,
            InterventionResumeComponent,
            AlertDescriptionAreaComponent,
            AlerteResumeComponent,
            InformationLineComponent,
            CarrouselImagesComponent,
            CheckButtonGridComponent,
            AlerteTypeButtonComponent,
            CarrouselImagesComponent,
            InformationLineComponent,
            AlertFilesSelectorComponent,
            InterventionResumeBlockComponent,
            MenuItemsListComponent,
            MenuButtonComponent,
            NavBarComponent,
            NoContentComponent,
            LoginOptionsComponent,
            LoginEmailFormComponent,
            PasswordFormComponent,
            ToastComponent,
            AlertComponent,
            FooterLegalNoticeComponent,
            CtmBackBtnComponent,
            CtmCloseBtnComponent,
            IntermediateLoginComponent
   ],
})
export class ComponentsModule {}