import { Component, OnInit, Input } from '@angular/core';
import { APP_CONFIG } from '../../../config/app.config';
import { PAGES_CONFIG } from '../../../config/pages.config';
import { NavController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { InterventionResumeBlockOptions } from './model/interventionResumeBlockOptions';
import { Router, NavigationExtras } from '@angular/router';
import { PATHS_CONFIG } from '../../../config/paths.config';
import { NavExtrasService } from 'src/app/services/nav-extra/nav-extras.service';

@Component({
  selector: 'app-intervention-resume',
  templateUrl: './intervention-resume.component.html',
  styleUrls: ['./intervention-resume.component.scss'],
})
export class InterventionResumeComponent implements OnInit {

  private _interventionResumeData: any;
  interventionResumeBlockOptions: InterventionResumeBlockOptions = new InterventionResumeBlockOptions();

  @Input()
  set interventionResumeData(value) {
    this._interventionResumeData = value;
    this.updateOptionsObject(value);
  }

  get interventionResumeData() {
    return this._interventionResumeData;
  }

  constructor(public navCtrl: NavController,
              private logger: NGXLogger,
              private router: Router,
              private navExtras: NavExtrasService) {
  }

  ngOnInit() {}

  
  goToBandeauPage() {
    const navigationExtras= {
      intervention: this.interventionResumeData,
      tab: 0,     
    };
    this.navExtras.setExtras(navigationExtras)
    this.router.navigate([PATHS_CONFIG.BANDEAU_PATH])
    .catch(err => this.logger.error(err));
  }

  /**
   * This method updates the options object used to display intervention resume data
   * @param data - Object with appropriate properties for data to display in intervention resume
   */
  private updateOptionsObject(data) {

    // is there data ?
    this.interventionResumeBlockOptions.hasData = data !== undefined && Object.keys(data).length > 0;

    // we fill options object if it has data to display
    if (this.interventionResumeBlockOptions.hasData) {

      // is inside list ?
      this.interventionResumeBlockOptions.isInsideList = data.isForList;
      // is search result
      this.interventionResumeBlockOptions.isSearchResult = data.isSearchResult;

      // build rows data
      this.buildRowsData(data);
    }

  }

  /**
   * build interventionResumeBlockOptions rows data
   */
  private buildRowsData(data: any) {
    // Affichage Bandeau Alerte Orpheline et Pages Mes Alertes
    if (data.dataType === APP_CONFIG.DATATYPE_ALERTE_ORPHELINE || data.dataType === APP_CONFIG.DATATYPE_ALERTE_RATTACHEE) {
      this.interventionResumeBlockOptions.row1 = this.buildRow1ForAlerte(data);
    } else if (data.dataType === APP_CONFIG.DATATYPE_INTER) { // Affichage Bandeau Intervention
      this.interventionResumeBlockOptions.row1 = this.buildRow1ForIntervention(data);
    }
    this.interventionResumeBlockOptions.row2 = this.buildRow2ForResume(data);

    // Affichage Bandeau Recherche Adresse
    if (this.interventionResumeBlockOptions.isSearchResult) {
      this.interventionResumeBlockOptions.row1 = this.buildRow1ForLocation(data);
      this.interventionResumeBlockOptions.row2 = this.buildRow2ForLocation(data);
    }

  }

  private buildRow1ForAlerte(data: any): any {
    return {
      label: data.titre,
      leftItems: [{
        css: 'ic-alerte-resume-status s' + data.codeStatutAlerte,
      }],
      isAlerte: true,
    };
  }

  private buildRow1ForIntervention(data: any): any {
    return {
      label: data.titre,
      leftItems: [{
        css: data.codeStatutInter !== undefined ? 'ic-intervention-resume-status s' + data.codeStatutInter : '',
      }],
      rightItems: [{
        css: data.codeStatutAlertePrio !== undefined && data.codeStatutAlertePrio !== null && data.codeStatutAlertePrio > -1 ? 'ic-intervention-resume-alert-status s' + data.codeStatutAlertePrio : '',
        template: '<svg style="width:24px;height:24px" viewBox="0 0 24 24">\
        <path class="cls-2" fill="#000000" d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M13,10V6H11V10H13M13,14V12H11V14H13Z" />\
        </svg>',
        },
                   {
        css: data.codeStatutAe !== undefined && data.codeStatutAe > -1 ? 'ic-intervention-resume-AE-status s' + data.codeStatutAe : '',
        template: '<svg style="width:24px;height:24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arret-eau</title><g id="arret-eau"><path class="cls-1" d="M17.24,17l-4.66-4.63L5.28,5.08,4,6.36,7.35,9.7A9.48,9.48,0,0,0,6,13.84a6,6,0,0,0,6.05,6,6.13,6.13,0,0,0,4-1.51L18.72,21,20,19.73,17.24,17m.88-3.13c0-4-6.05-10.84-6.05-10.84S10.73,4.52,9.32,6.53L18,15.16A6.35,6.35,0,0,0,18.12,13.84Z"/></g></svg>',
      }],
    };
  }

  private buildRow2ForResume(data: any): any {
    return {
      labels: [data.ligne1, data.ligne2],
    };
  }

  private buildRow1ForLocation(data): any {
    return {
      isLocation: true,
      label: data.libTypeInter,
    };
  }

  private buildRow2ForLocation(data): any {
    return {
      labels: [data.loc],
    };
  }
}
