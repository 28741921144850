import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Events, NavController, Platform } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { NGXLogger } from 'ngx-logger';
import { first } from 'rxjs/operators';
import { APP_CONFIG } from 'src/config/app.config';
import { PAGES_CONFIG } from 'src/config/pages.config';
import { PATHS_CONFIG } from 'src/config/paths.config';
import { CALLABLE_CLOUD_FUNCTIONS, ENUM_FIREBASE_PROVIDER_ID } from '../../../config/enum.config';
import { ENV } from '../../../config/env.config';
import { DatabaseService } from '../database/database.service';
import { NavExtrasService } from '../nav-extra/nav-extras.service';
import { RestAuthService } from '../rest-auth/rest-auth.service';
import { RoutingService } from '../routing/routing.service';
import { UtilsService } from '../utils/utils.service';
import { CustomTokenData } from './model/customTokenEspaceClient';
import FbAuthProvider = firebase.auth.AuthProvider;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: firebase.User;
  idToken;
  
  providerId;

  constructor(public afAuth: AngularFireAuth,
              private platform: Platform,
              private logger: NGXLogger,
              private googlePlus: GooglePlus,
              private databaseService: DatabaseService,
              private restAuthService: RestAuthService,
              private utilService: UtilsService,
              private navCtrl: NavController,
              private router: Router,
              private navExtras: NavExtrasService,
              private routingService: RoutingService,
              private events: Events
             ) {

    this.logger.debug('Hello AuthService Service');
    afAuth.authState.subscribe(user => {
      this.logger.debug('afAuth.authState subscribe callback AuthService');
      this.user = user;
      if (user) {
        this.databaseService.writeUserConnection(user).catch(err => this.logger.error(err));
        this.setProviderIdValue();
      }
    });

  }

 
  
  isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  signInWithGoogle(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const Service = new firebase.auth.GoogleAuthProvider();
        Service.setCustomParameters({
          display: "page",
          prompt: "select_account"
        });
        this.oauthSignIn(Service)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
      })
      .catch(error => {
        this.logger.error('Error avec la configuration de la persistance', error)
        reject(error);
      });
    });
  }

  async signInWithGooglePlusNative(Service) {
    const gplusUser = await this.googlePlus.login({
      'webClientId': ENV.INO_CLIENT_ID,
      'offline': false,
      'scopes': 'profile email',
      'prompt': 'select_account'
    });
    return this.afAuth.auth.signInWithCredential(Service.credential(gplusUser.idToken, gplusUser.accessToken));
  }

  oauthSignIn(Service: FbAuthProvider): Promise<any> {
    if (!this.platform.is('cordova')) {
      this.logger.log('calling popup');
      return this.afAuth.auth.signInWithPopup(Service);
    } else {
      this.logger.log('calling native');
      return this.signInWithGooglePlusNative(Service);
    }
  }

  getIdToken() {
    return this.afAuth.auth.currentUser && this.afAuth.auth.currentUser.getIdToken(true);
  }

  async signOut() {
    await this.databaseService.clearregistrationToken();

    // WEB
    if (!this.platform.is('cordova')) {
      return this.afAuth.auth.signOut();
    } 
    
    // NATIVE
    // the order is important : trySilentLogin and then logout. if trySilentLogin KO, then disconnect
    else {

      // clear badge
      this.utilService.clearNativeBadge();
      
      switch (this.providerId) {
        case ENUM_FIREBASE_PROVIDER_ID.GOOGLE:
          this.signOutGoogle();
          break;
      }

      return this.afAuth.auth.signOut();
    }
  }

  getUser() {
    return this.user;
  }

  setUser(_user) {
    this.user = _user;
  }

  getUserEmail() {
    return this.user && this.user.email;
  }

  get authenticated(): boolean {
    return this.user !== null;
  }

  signUp(credentials) {
    return this.afAuth.auth.createUserWithEmailAndPassword(credentials.email, credentials.password);
  }

  signInWithEmail(credentials) :Promise<any> {
   this.logger.log('Sign in with email');
   return new Promise((resolve, reject) => {
    this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).
    then(() => {
      this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
    })
    .catch(error => {
      this.logger.error('Error avec la configuration de la persistance', error)
      reject(error);
    });
   });
  }

  async signInWithEspaceClient(customTokenData: CustomTokenData){
    return new Promise<void>((resolve, reject) => {
      this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).
      then(async () => {
        await this.afAuth.auth.signInWithCustomToken(customTokenData.customToken)
        .then(async (result) => {
          this.logger.log('result', result);
          await this.afAuth.auth.currentUser.updateEmail(customTokenData.userEmail);
          await this.afAuth.auth.currentUser.updateProfile({
            displayName: customTokenData.userName
          });
          this.logger.debug('AuthService signInWithEspaceClient() this.afAuth.auth.currentUser', this.afAuth.auth.currentUser);
          this.events.publish(APP_CONFIG.EVENT_UPDATE_USER_DATA, this.afAuth.auth.currentUser);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      })
      .catch(error => {
        this.logger.error('Error avec la configuration de la persistance', error)
        reject(error);
      });
     });
 }


  getUserImageUrl() {
    return this.user.photoURL;
  }

  getUserName() {
    return this.user.displayName;
  }

  getUserPhoneNumber() {
    return this.user.phoneNumber;
  }

  sendPasswordResetEmail(email) {
    return new Promise<void>((resolve,reject) => {
      this.restAuthService.sendPasswordResetEmail(email)
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
    });
  }


  verifyPasswordResetCode(code) {
    return new Promise((resolve, reject) => {
      firebase.auth().verifyPasswordResetCode(code)
      .then(email => {
        resolve(email);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  confirmPasswordReset(code, newPassword) {
    this.logger.debug('AuthService call to confirmPasswordReset()');
    return new Promise<void>((resolve, reject) => {
      firebase.auth().confirmPasswordReset(code, newPassword)
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  /**
   * call to cloud function to check if user has already an existing account with different sign in credentials than the one given in parameter
   * It returns false if not.
   * If yes, it returns an error with an error code
   * @param email 
   * @param signInMethod 
   */
  async hasAlreadyExistingAccountWithDifferentSignIn(email, signInMethod) {
    try {
      const res = await this.databaseService.callCloudFunction(CALLABLE_CLOUD_FUNCTIONS.HAS_ALREADY_EXISTING_ACCOUNT_WITH_DIFFERENT_SIGN_IN, { email, signInMethod });
      this.logger.debug("hasAlreadyExistingAccountWithDifferentSignIn() res", JSON.stringify(res));
      if (res.data) {
        if (res.data.hasAlreadyExistingAccountWithDifferentSignIn) {
          throw ({
            code: 'auth-custom/' + res.data.provider
          });
        } else {
          return false;
        }
      }
    } catch(err) {
      this.logger.error('Error hasAlreadyExistingAccountWithDifferentSignIn() => this.databaseService.callCloudFunction', JSON.stringify(err));
      // if error comes from callCloudFunction, we will have property 'message'
      // else, if it comes from the throw above, we will have property 'code'
      throw ({
        code: err.message || err.code
      });
    }
  }

  signInWithEmailAndPassword(email: string, password: string) {
    return new Promise<void>((resolve, reject) => {
        this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }


  routeToPage(idAlerte, idNotif?, fromPage?, fromEspaceClient?) {
    this.logger.debug(`AuthService: Call to routeToPage() with fromPage value : ${fromPage}`);
    
    this.utilService.setActivePage(PAGES_CONFIG.LOGIN_PAGE);

    if (idAlerte) {
      this.goToDetailPage(idAlerte, idNotif);
    }
    else if(fromPage) {
      this.getFromPageUrl(fromPage, fromEspaceClient);
    } else {
      this.goToMapPage();
    }
  }

  getFromPageUrl(fromPage: string, fromEspaceClient?) {
    this.logger.debug('AuthService getFromPageUrl()');

    let delta = -1;

    const currentPathName = document.location.pathname;
    this.logger.debug(`currentPathName : ${currentPathName}`);
    delta = currentPathName.indexOf(PATHS_CONFIG.LOGIN_WITH_EMAIL_PATH) >= 0 ? -2 : -1;
 
    this.logger.debug(`delta : ${delta}`);

    history.go(delta);
  }

  goToMapPage() {
    this.logger.debug('AuthService goToMapPage()');
    this.utilService.clearTabs(true);
    this.routingService.globalGoToHomePage();
  }

  goToNotifPage() {
    this.logger.debug('AuthService goToNotifPage()');
    this.routingService.globalGoToNotificationPage();
  }

  goToMyResumePage() {
    this.logger.debug('AuthService goToMyResumePage()');
    this.routingService.globalGoToMesAlertesPage();
  }

  goToCreateAlertePage() {
    this.logger.debug('AuthService goToCreateAlertePage()');
    this.navCtrl.navigateRoot([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.MAP_PATH + PATHS_CONFIG.ALERTE_CREATE_PATH]).catch(err => this.logger.error(err));
  }

  goToDetailPage(idAlerte, idNotif) {
    this.logger.debug('AuthService goToDetailPage()');
    setTimeout(() => {
      const navigationExtras = {
        idAlerte,
        idNotif
      };
      this.navExtras.setExtras(navigationExtras);
      this.router.navigate([`${PATHS_CONFIG.GLOBAL_MENU_PATH}${PATHS_CONFIG.TABS_PATH}${PATHS_CONFIG.NOTIFICATION_PATH}${PATHS_CONFIG.ALERTE_DETAIL_PATH}/${idAlerte}/${idNotif}`])
      .catch(err => this.logger.error(err));  
    }, 1000);
  }


  private async signOutGoogle() {
    try {
      await this.googlePlus.trySilentLogin({});

      try {
        await this.googlePlus.logout();
      }
      catch (err) {
        this.logger.error('Error on this.googlePlus.logout()');
      }
      
    } catch (err) {

      try {
        await this.googlePlus.disconnect();
      } 
      catch (err) {
        this.logger.error('Error on this.googlePlus.disconnect()');
      }
    }
  }


  private parseJwt (token) {
    this.logger.debug('AuthService. Call to parseJwt()');
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  private setProviderIdValue() {
    this.getFirebaseSignInProvider()
        .then(response => { this.providerId = response; })
        .catch(err => this.logger.error('An error occured on getting firebase SignIn Provider', err));
  }

  /**
   * This method returns sign in provider id. Even if user has multiple providers, it will return the provider used for the current user
   */
  private async getFirebaseSignInProvider() {
    this.logger.debug('AuthService. Call to getFirebaseSignInProvider()');
    const idtoken = await this.getIdToken();
    const parsedJwt = this.parseJwt(idtoken);
    return parsedJwt.firebase.sign_in_provider;
  }
}