import { Component, OnInit, NgZone, EventEmitter, Output } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from '../../../app/services/utils/utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '../../../app/services/alert/alert.service';
import { ToastService } from '../../../app/services/toast/toast.service';
import { DateService } from '../../../app/services/date/date.service';
import { APP_CONFIG } from '../../../config/app.config';
import { LABELS } from '../../../config/labels.config';
import { MediaData } from '../../../app/services/rest-alerte/model/mediaData';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {
  imageTypeToExtension,
  arrayBufferToDataURL,
  getOrientation,
  normalizeDecimalNumber,
  parseOrientation,
  urltoFile
} from './alert-files-selector.utils';
import { Router } from '@angular/router';
import { PATHS_CONFIG } from '../../../config/paths.config';
import { Platform } from '@ionic/angular';
import { v4 as uuid } from 'uuid';
import { StatusBar } from '@ionic-native/status-bar/ngx';



@Component({
  selector: 'app-alert-files-selector',
  templateUrl: './alert-files-selector.component.html',
  styleUrls: ['./alert-files-selector.component.scss'],
})
export class AlertFilesSelectorComponent implements OnInit {

  fileInputId = uuid();
  selectedImages: any[] = [];
  ALERT_ATTACHMENT_MAX_NUMBER: number = APP_CONFIG.ALERT_ATTACHMENT_MAX_NUMBER;
  btnSelectorText: string = LABELS.alert_file_selector_add_btn_text.replace('{0}', '' + APP_CONFIG.ALERT_ATTACHMENT_MAX_NUMBER);
  closeIconSrc: string = APP_CONFIG.CUSTOM_PNG_PATH + 'close-circle@2x.png';
  idx;
  isNatif;
  isiOS;

  @Output() selectedImagesChange = new EventEmitter();

  constructor(private toastService: ToastService,
              private alertService: AlertService,
              private domSanitizer: DomSanitizer,
              private dateService: DateService,
              private zone: NgZone,
              private utilsService: UtilsService,
              private logger: NGXLogger,
              private router: Router,
              private camera: Camera,
              private platform: Platform,
              private statusBar: StatusBar) {
    this.logger.debug('Hello AlertFilesSelectorComponent Component');
  }

  ngOnInit(): void {
   this.isNatif = this.platform.is('cordova');
   this.isiOS = this.platform.is('ios');
  }

  /**
   * prendre un photo en utilisant le plugin natif
   * 
   */
  takePhoto(source: number){
  
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      sourceType: source,
      correctOrientation: true,
    }

    this.camera.getPicture(options).then(async (imageData) => {
        const photo = 'data:image/png;base64,' + imageData;
        const photoFile = await urltoFile(photo, this.dateService.getPhotoDate() + '.jpeg', 'image/jpeg')
        this.onFilesSubmit([photoFile]);
    }, (err) => {
       this.logger.error('Error taking picture' + err);

       // fix CDIG-2349 
       this.fixiOSStatusBar();
    });
  }

  takePhotoFromCamera() {
    this.takePhoto(this.camera.PictureSourceType.CAMERA);
  }
  
  takePhotoFromPhotoLibrary() {
    this.takePhoto(this.camera.PictureSourceType.PHOTOLIBRARY);
  }

  /**
   * It triggers a click on the input that will open the file selection wizard
   */
  triggerFileInputClick() {
    this.logger.debug('call to triggerFileInputClick()');
    const fileUploadDom = document.getElementById(this.fileInputId);
    fileUploadDom.click();    
  }

  /**
   * Callback called when user has selected files to upload.
   * It will :
   * - check files number
   * - check files types
   * - compress images
   * - check total file size
   * @param files submitted files
   */
  async onFilesSubmit(files) {

    // fix CDIG-2349 
    this.fixiOSStatusBar();

    this.logger.debug('call to onFilesSubmit()', files);
    let isValid = false;
    let validFiles;

    await this.utilsService.showLoading();  // display spinner

    // check files number
    isValid = this.checkFilesNumber(files.length);

    if (isValid) {

      // get valid images files
      validFiles = this.getValidFileTypes(files);

      if (validFiles && validFiles.length > 0) {
        // get compressed files
        this.getCompressedFiles(validFiles)
          // check compressed files total size
          .then(async compressedFiles => {

            // validate files sizes
            isValid = this.checkFilesSize(compressedFiles);

            // Add files to current selection
            if (isValid) {
              this.addFilesToSelection(compressedFiles)
                .then(async () => {
                  await this.utilsService.hideLoading();  // hide spinner
                  // UPDATE VALUE IN PARENT COMPONENT
                  this.selectedImagesChange.emit(this.selectedImages);
                })
                .catch(err => this.logger.error(err));
            } else {
              await this.utilsService.hideLoading();  // hide spinner
            }

          })
          .catch(err => this.logger.error(err));
      } else {
        await this.utilsService.hideLoading();
      }

    } else {
      this.utilsService.hideLoading();  // hide spinner
    }

    // AT THE END OF PROCESS, CLEAR FILE INPUT
    this.clearFileInputValue();
  }

  /**
   * Called to delete image from list
   * @param idx index of image to delete
   */
  async onDeleteImage(idx) {
    const okHandler = async () => {
      // REMOVE IMAGE
      this.selectedImages.splice(idx, 1);
      // UPDATE VALUE IN PARENT COMPONENT
      this.selectedImagesChange.emit(this.selectedImages);
      await alert.dismiss();
    };
    const koHandler = async () => {
      await alert.dismiss();
    };
    const alert = await this.alertService.buildConfirmAlert(
      LABELS.confirm_title,
      LABELS.alert_file_selector_delete_confirm_msg,
      okHandler,
      koHandler,
      APP_CONFIG.ALERT_MODAL_TYPE_WARNING,
      APP_CONFIG.ALERT_MODAL_ICON_DELETE
      );
    await alert.present()
    .catch(err => this.logger.error(err));
  }

  /**
   * Called to display image in full screen
   * @param img image to display full screen
   */
  displayImageFullscreen(img) {
    if (!this.router.url.includes(PATHS_CONFIG.IMAGE_ZOOM_PATH)) {
      const imgData: MediaData = {
        id: 0,
        idAlerte: 0,
        url: img.url,
        fileName: img.name,
        dateCreation: 0,
      };
      this.router.navigate([PATHS_CONFIG.IMAGE_ZOOM_PATH, { 'mediadata': [imgData], 'currentIndex': 1, 'hideDotsAndPosition': true }])
      .catch(err => this.logger.error(err));
    }
  }

  private clearFileInputValue() {
    const fileUploadDom = document.getElementById(this.fileInputId);
    fileUploadDom['value'] = '';
  }

  /**
   * this method check files number and display toast message if it is more than maximum allowed
   * returns a boolean telling whether criteria is valid or not
   * @param length length
   */
  private checkFilesNumber(length) {

    if (length > (APP_CONFIG.ALERT_ATTACHMENT_MAX_NUMBER - this.selectedImages.length)) {
      const warningMessage = LABELS.alert_file_selector_max_number_warning.replace('{0}', '' + APP_CONFIG.ALERT_ATTACHMENT_MAX_NUMBER);
      this.toastService.presentToast(warningMessage, APP_CONFIG.TOAST_TYPE_ALERT, APP_CONFIG.TOAST_REGULAR_CONFIG);
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check files size. returns false if not valid
   * @param compressedFiles  list of compressed files
   */
  private checkFilesSize(compressedFiles) {
    let isValid = false;

    const currentSelectionTotalSize: number = this.getSelectedFilesTotalSize();
    let tmpSize = currentSelectionTotalSize;

    for (let ii = 0, jj = compressedFiles.length; ii < jj; ii++) {
      tmpSize += compressedFiles[ii].size;
    }

    if (tmpSize <= APP_CONFIG.ALERT_ATTACHMENT_TOTAL_MAX_SIZE) {
      isValid = true;
    }

    return isValid;
  }

  /**
   * This method returns currently selected total file size
   */
  private getSelectedFilesTotalSize() {
    let size = 0;
    for (let ii = 0, jj = this.selectedImages.length; ii < jj; ii++) {
      size += this.selectedImages[ii].size;
    }
    return size;
  }

  /**
   * This method adds files to array this.selectedImages
   * @param compressedFiles compressed files
   */
  private addFilesToSelection(compressedFiles) {
    this.logger.debug('call to addFilesToSelection(). compressedFiles length = ' + compressedFiles.length);
    return new Promise(resolve => {
      let count = 0;
      for (let ii = 0, jj = compressedFiles.length; ii < jj; ii++) {
        const compressedFile = compressedFiles[ii];
        this.getFileData(compressedFile)
          .then(fileData => {
            this.zone.run(() => {
              this.selectedImages.push(fileData);
              count++;
              if (count === compressedFiles.length) {
                resolve();
              }
            });
          })
          .catch(err => {
            this.alertService.presentErrorAlert(LABELS.alert_file_selector_error_add_image_thumbnail_title, LABELS.alert_file_selector_error_add_image_thumbnail_message);
            count++;
            if (count === compressedFiles.length) {
              resolve();
            }
          });
      }
    });
  }

  /**
   * This methods return fileData to add to selectedImages array
   * It uses FileReader to get DataURL
   * @param file file
   */
  private getFileData(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const imageObj: any = {
        name: file.name,
        size: file.size,
        file,
      };
      reader.onload = evt => {
        const result = evt.target['result'];
        const url = this.domSanitizer.bypassSecurityTrustUrl(arrayBufferToDataURL(result, 'image/jpeg'));
        imageObj.url = url;
        resolve(imageObj);
      };
      reader.onabort = reject;
      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * This method checks if all files are images (png/jpg).
   * If one or more files are not images, a warning message is displayed
   * Method returns list of valid files
   * @param files files
   */
  private getValidFileTypes(files) {
    const res: File[] = [];
    for (let ii = 0, jj = files.length; ii < jj; ii++) {
      const tmpFile = files[ii];
      const isValid = this.checkFileType(tmpFile);
      if (isValid) {
        res.push(tmpFile);
      } else {
        this.toastService.presentToast(LABELS.alert_file_selector_file_type_warning, APP_CONFIG.TOAST_TYPE_ALERT, APP_CONFIG.TOAST_REGULAR_CONFIG);
      }
    }
    return res;
  }

  /**
   * This method checks file type (image png,jpg). If not valid, it returns false
   * @param file file
   */
  private checkFileType(file: File) {
    const type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
    return APP_CONFIG.ALERT_ALLOWED_IMAGE_FILETYPES.indexOf(type) !== -1;
  }

  /**
   * In this method we try to compress files and return them in an array
   * @param files files
   */
  private getCompressedFiles(files) {
    this.logger.debug('call to getCompressedFiles()');
    return new Promise((resolve, reject) => {
      let res = [];
      let count = 0;
      if (!URL && !FileReader) {
        this.toastService.presentToast(LABELS.alert_file_selector_compression_not_supported, APP_CONFIG.TOAST_TYPE_ALERT, APP_CONFIG.TOAST_REGULAR_CONFIG);
        res = files;
        resolve(res);
      } else {
        for (let ii = 0, jj = files.length; ii < jj; ii++) {
          this.compressFile(files[ii])
            // add compressed file to array
            .then(compressedFile => {
              res.push(compressedFile);
              count++;
              // if end of files array, resolve in order to continue the process
              if (count === files.length) {
                resolve(res);
              }
            })
            // In case of error caught for one file, display a simple toast message
            // resolve() if end of array has been reached in order to continue process
            .catch(err => {
              // inform user that something went wrong
              this.toastService.presentToast(LABELS.alert_file_selector_error_file_compression, APP_CONFIG.TOAST_TYPE_ALERT);
              count++;
              // continue process if end of array
              if (count === files.length) {
                resolve(res);
              }
            });
        }
      }
    });

  }

  /**
   * In this method we compress one file.
   * Inspired from library image-compressor https://github.com/xkeshi/image-compressor/blob/761d0fa735d8274c11ceae588e392681c2bff122/src/index.js
   * @param file file
   */
  private compressFile(file) {

    const image = new Image();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = evt => {
        const result = evt.target['result'];
        resolve({
          url: arrayBufferToDataURL(result, 'image/jpeg'),
          ...parseOrientation(getOrientation(result)),
        });
      };

      reader.onabort = reject;
      reader.onerror = reject;

      reader.readAsArrayBuffer(file);

    })
      // we get image object src from previous blob url
      .then(data => new Promise((resolve, reject) => {

        image.onload = () => resolve({
          ...data,
          naturalWidth: image.naturalWidth,
          naturalHeight: image.naturalHeight,
        });
        image.onabort = reject;
        image.onerror = reject;
        image.alt = file.name;
        image.src = data['url'];
      }))

      // we build canvas where we draw the image with 60% compression
      .then(({
      naturalWidth,
      naturalHeight,
      rotate = 0,
      scaleX = 1,
      scaleY = 1,
    }) => new Promise(resolve => {

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const aspectRatio = naturalWidth / naturalHeight;
          let maxWidth = APP_CONFIG.ALERT_ATTACHMENT_MAX_WIDTH;
          let maxHeight = Infinity;
          let minWidth = 0;
          let minHeight = 0;
          let width = naturalWidth;
          let height = naturalHeight;

          if (maxWidth < Infinity && maxHeight < Infinity) {
            if (maxHeight * aspectRatio > maxWidth) {
              maxHeight = maxWidth / aspectRatio;
            } else {
              maxWidth = maxHeight * aspectRatio;
            }
          } else if (maxWidth < Infinity) {
            maxHeight = maxWidth / aspectRatio;
          } else if (maxHeight < Infinity) {
            maxWidth = maxHeight * aspectRatio;
          }

          if (minWidth > 0 && minHeight > 0) {
            if (minHeight * aspectRatio > minWidth) {
              minHeight = minWidth / aspectRatio;
            } else {
              minWidth = minHeight * aspectRatio;
            }
          } else if (minWidth > 0) {
            minHeight = minWidth / aspectRatio;
          } else if (minHeight > 0) {
            minWidth = minHeight * aspectRatio;
          }

          width = Math.min(Math.max(width, minWidth), maxWidth);
          height = Math.min(Math.max(height, minHeight), maxHeight);

          const destX = -width / 2;
          const destY = -height / 2;
          const destWidth = width;
          const destHeight = height;

          if (Math.abs(rotate) % 180 === 90) {
            ({ width, height } = {
              width: height,
              height: width,
            });
          }

          canvas.width = normalizeDecimalNumber(width);
          canvas.height = normalizeDecimalNumber(height);

          context.fillStyle = '#fff';
          context.fillRect(0, 0, width, height);
          context.save();
          context.translate(width / 2, height / 2);
          context.rotate((rotate * Math.PI) / 180);
          context.scale(scaleX, scaleY);

          context.drawImage(
            image,
            Math.floor(normalizeDecimalNumber(destX)),
            Math.floor(normalizeDecimalNumber(destY)),
            Math.floor(normalizeDecimalNumber(destWidth)),
            Math.floor(normalizeDecimalNumber(destHeight))
          );

          context.restore();

          const done = result => {
            resolve({
              naturalWidth,
              naturalHeight,
              result,
            });
          };

          canvas.toBlob(done, 'image/jpeg', APP_CONFIG.ALERT_ATTACHMENT_QUALITY);

        }))

      // we revoke object url and return appopriate content
      // according to result, we might have to return initial file instead of the blob from canvas.toBlob
      .then(({
      naturalWidth,
      naturalHeight,
      result,
    }) => new Promise(resolve => {
          // if (URL) {
          //   URL.revokeObjectURL(image.src);
          // }

          if (result) {

            // if result bigger than initial file size, return file
            if (result.size > file.size) {
              result = file;
            } else {
              result.name = file.name;

              const REGEXP_EXTENSION = /\.\w+$/;
              // Convert the extension to match its type
              if (result.name && result.type !== file.type) {
                result.name = result.name.replace(
                  REGEXP_EXTENSION,
                  imageTypeToExtension(result.type)
                );
              }
            }

          } else {
            result = file;
          }

          resolve(result);

        }))

      // IN CASE OF ERROR in the process
      .catch(err => {
        throw err;
      });
  }

  // fix CDIG-2349 
  // hide and show statusbar
  fixiOSStatusBar() {
    if (this.isiOS) {
      this.statusBar.hide();
      this.statusBar.show();
    }
  }


}
