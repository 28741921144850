export const PATHS_CONFIG = {
    IMAGE_ZOOM_PATH: '/image-zoom',
    ALERTE_CREATE_PATH: '/alert-create',
    ALERTE_DESCRIPTION_PATH: '/alerte-description',
    ALERTE_DETAIL_PATH: '/alerte-detail',
    ALERTE_STATUT_PATH: '/alerte-statut',
    BANDEAU_PATH: '/bandeau',
    CGU_PATH: '/cgu',
    FILTER_PATH: '/filter',
    GLOBAL_MENU_PATH: '/global-menu/tabs',
    LIST_PATH: '/list',
    LOGIN_PATH: '/login',
    LOGIN_WITH_EMAIL_PATH: '/login-with-email',
    LOST_PASSWORD_PATH: '/lost-password',
    MAP_PATH: '/map',
    MES_ALERTES_PATH: '/mes-alertes',
    NOTIFICATION_PATH: '/notification',
    RESET_PASSWORD_PATH: '/reset-password',
    VERIFY_EMAIL_PATH: '/verify-email',
    TABS_PATH: '/tabs',
    UPDATE_PASSWORD_PATH: '/update-password',
    PRIVACY_POLICY_PATH: '/privacy-policy',
    TEST_RECAPTCHA_PATH: '/test-recaptcha',
    CREATE_ACCOUNT_PATH: '/create-account',
    MANAGE_ACCOUNT_PATH: '/manage-account',
    UPDATE_USER_PASSWORD_PATH: '/update-password',
    AUTH_PATH: '/auth',
    TUTORIAL_PATH: '/tutorial'
};