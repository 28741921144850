import { Pipe, PipeTransform } from '@angular/core';
import { LABELS } from '../../../config/labels.config';

@Pipe({
  name: 'separatorDateHour'
})
export class SeparatorDateHourPipe implements PipeTransform {

  /**
   * Takes a key value and returns the label associated to it
   */
  transform(value: string, ...args) {
    return value ? value.replace(' ', LABELS.separator_date_hour) : value;
  }

}
