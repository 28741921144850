import { Component, OnInit, Input } from '@angular/core';
import { PATHS_CONFIG } from 'src/config/paths.config';
import { Router, ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { APP_CONFIG } from 'src/config/app.config';
import { RoutingService } from 'src/app/services/routing/routing.service';

@Component({
  selector: 'app-intermediate-login',
  templateUrl: './intermediate-login.component.html',
  styleUrls: ['./intermediate-login.component.scss'],
})
export class IntermediateLoginComponent implements OnInit {

  @Input() fromPage: string;
  constructor(private router: Router, 
              private logger: NGXLogger,
              private route: ActivatedRoute,
              private routingService: RoutingService) { }

  ngOnInit() {
    this.logger.debug('IntermediateLoginComponent ngOnInit()');
    this.logger.debug('fromPage', '=>', this.fromPage);
  }

  onLogin() {
    this.routingService.globalGoToLoginPage({fromPage: this.fromPage});
  }

}
