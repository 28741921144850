import { ENUM_FILTER_STATUS_OPTIONS, ENUM_FILTER_TYPE_OPTIONS, ENUM_FILTER_ALERT_TYPE_OPTIONS } from './enum.config';
import { PAGES_CONFIG } from './pages.config';

export const APP_CONFIG = {
    HEADER_TOKEN_AUTHENTICATION_KEY: 'User-Authentication-Token',
    HEADER_ACCEPT_KEY: 'Accept',
    HEADER_CONTENT_TYPE_KEY: 'Content-Type',
    HEADER_CONTENT_TYPE_APPLICATION_JSON: 'application/json',
    HEADER_CONTENT_TYPE_MULTIPART_FORMDATA: 'multipart/form-data;charset=utf-8',
    HEADER_AUTHORIZATION_KEY: 'Authorization',
    HEADER_AUTHORIZATION_VALUE_BEARER_PREFIX: 'Bearer ',
    DEFAULT_TOAST_DURATION: 3000,
    DEFAULT_TOAST_POSITION: 'bottom',
    TOAST_TYPE_INFO: 'info',
    TOAST_TYPE_ALERT: 'alert',
    DEFAULT_UPLOAD_MAXIMUM_FILESIZE: 1048576,    // 1 Mo -> octets
    CUSTOM_PNG_PATH: '/assets/PNG_custom/',
    IMGS_PATH: '/assets/imgs/',
    GEOLOC_PIN: 'geolocalise.png',
    GEOLOC_PIN_SIZE: {
        h: 32,
        w: 32,
    },
    LOCATION_SEARCH_PIN: 'pin.png',
    LOCATION_SEARCH_PIN_SIZE: {
        h: 32,
        w: 32,
    },
    PIN_SELECTION: 'pin-selection.png',
    PIN_SELECTION_SIZE: {
        w: 42,
        h: 42,
    },
    PNG_FILE_EXTENTIONS: '.png',
    MAP_PICTO_ICONS_SIZE: {
        w: 34,
        h: 38,
    },
    LIST_MAX: 50,
    FILTER_DEFAULT_CONFIG : {
        statutInter: [ ENUM_FILTER_STATUS_OPTIONS.PROGRAMME, ENUM_FILTER_STATUS_OPTIONS.EN_COURS ],
        typeInter:  [ ENUM_FILTER_TYPE_OPTIONS.EXPL,
                      ENUM_FILTER_TYPE_OPTIONS.CANA,
                      ENUM_FILTER_TYPE_OPTIONS.TIER,
                      ENUM_FILTER_TYPE_OPTIONS.DOMM,
                      ENUM_FILTER_TYPE_OPTIONS.FUIT,
                      ENUM_FILTER_TYPE_OPTIONS.CSED,
                    ],
        typeAlert: [ ENUM_FILTER_ALERT_TYPE_OPTIONS.ALER_BIPI,
                     ENUM_FILTER_ALERT_TYPE_OPTIONS.ALER_ENVI,
                     ENUM_FILTER_ALERT_TYPE_OPTIONS.ALER_FUIT],
        statutAlert: [ENUM_FILTER_STATUS_OPTIONS.NON_TRAITE,
                      ENUM_FILTER_STATUS_OPTIONS.PRISE],
        ae: false,
    },
    FILTER_DEFAULT_STATUS : {
        planifier: false,
        planifie: false,
        cours: true,
        termine: false,
        programme: true,
        cana: true,
        expl: true,
        tier: true,
        fuit: true,
        domm: true,
        csed: true,
        ae: false,
        non_traite: true,
        prise: true,
        traite: false,
        non_traitable: false,
        aler_fuit: true,
        aler_envi: true,
        aler_bipi: true,
    },
    GEOLOC_DEFAULT_OPTIONS: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    },
    ALERT_DESCRIPTION_MAX_DEFAULT: 300,
    ALERT_ATTACHMENT_MAX_NUMBER: 3,
    ALERT_ALLOWED_IMAGE_FILETYPES: '|jpg|png|jpeg|', // if new file type to allow, add it between two '|' for example '|jpg|png|jpeg|bmp|'
    ALERT_ATTACHMENT_QUALITY: 0.8,
    ALERT_ATTACHMENT_TOTAL_MAX_SIZE: 10000000,
    ALERT_ATTACHMENT_MAX_WIDTH: 500,
    TOAST_REGULAR_CONFIG: {
        duration: 4000,
        position: 'top',
    },
    HORS_PERIMETRE_TOAST_DURATION: 4000,
    CLUSTER_MAX_ZOOM: 22,
    CLUSTER_MAX_DISPLAY_ZOOM: 13,
    CLUSTER_MIN_DISPLAY_ZOOM: 16,
    CHECK_DATE_OPTIONS: {
        heureDebut: 8,
        heureFin: 18,
        checkWeekEnd: true,
        checkJourFerie: true,
    },
    TOAST_EXIT_APP_DURATION: 2000,
    REF_JIMI_USER_ACTIVITY: 'jimi/userActivity/',
    REF_JIMI_USER_CONNECTION: 'jimi/user/',
    EVENT_ALERTES_RESUME: 'alertes:resume',
    VEOLIA_DIRECTORY_PHONE_TYPE_WORK: 'work',
    VEOLIA_DIRECTORY_PHONE_TYPE_WORK_MOBILE: 'work_mobile',
    VEOLIA_DIRECTORY_PHONE_TYPE_MOBILE: 'mobile',
    DATATYPE_INTER: 'inter',
    DATATYPE_ALERTE_ORPHELINE: 'ao',
    DATATYPE_ALERTE_RATTACHEE: 'ar',
    EVENT_SEARCH: 'list:search',
    EVENT_LIST_TOO_MANY: 'list:toomany',
    EVENT_LIST_NONE: 'list:none',
    EVENT_LIST_FILTER: 'list:filter',
    EVENT_UPDATE_MARKER: 'marker:update',
    EVENT_RESUME_CLEAR: 'resume:clear',
    // Si non vide decommenter dans resquest.interceptor
    URL_CACHABLES: [],
    MAX_CACHE_AGE: 60000, // in milliseconds
    EVENT_SELECT_APP_TAB: 'tabs:select',
    OVERLAY_COMPONENTS_URL: 'overlayComponent',
    MAP_URL: 'global-menu/tabs/tabs/map',
    LOGIN_URL: 'login',
    NOTIFICATIONS_URL: '/global-menu/tabs/tabs/notification',
    DETAIL_URL: 'global-menu/tabs/tabs/mes-alertes/alerte-detail',
    EVENT_OPEN_PAGE: 'page:open',
    BANDEAU_TAB_VALUE_DETAIL: 'detail',
    BANDEAU_TAB_VALUE_ARRETEAU: 'arret-eau',
    BANDEAU_TAB_VALUE_ALERTE: 'alerte',
    PAGES_WITH_MENU_SWIPE_ALLOWED: [PAGES_CONFIG.MAP_PAGE, PAGES_CONFIG.NOTIFICATION_PAGE, PAGES_CONFIG.LIST_PAGE, PAGES_CONFIG.MES_ALERTES_PAGE],
    MAP_MIN_ZOOM: 5,
    LOGO_VEDIF_PATH: '/assets/imgs/logo-vedif.jpg',
    LOGO_SEDIF_PATH: '/assets/imgs/logo_sedif.jpg',
    STATUS_NOT_FOUND: 'NOT_FOUND',
    IMG_ZOOM_PAGE_STORAGE_KEY: 'zoomPageHasBeenOpened',
    USER_PHONE_KEY: 'JIMI_USER_PHONE',
    USER_ACCESSTOKEN_KEY: 'JIMI_USER_TOKEN',
    METADATA_KEY: 'metadata',
    ROCK_ALERTE_FAMILLE : 'FUITES_RCF',
    ROCK_ALERTE_OBJET : 'FUITE_SUR_RESEAU_CPC',
    ROCK_ALERTE_MEDIA : 'MOBILE',
    ROCK_ALERTE_SERVICE : 'CIT',
    ROCK_ALERTE_INTERNAL_SOURCE : 'VEOLIA',
    ROCK_ALERTE_EXTERNAL_SOURCE : 'CLIENT',
    ID_NOTIF_LABEL: 'idNotif',
    EVENT_CHG_ADR_ALERTE: 'alerte:changeAdr',
    EVENT_CHG_DESC_ALERTE_BACK: 'alerte:changeDesc',
    EVENT_TOGGLE_SEARCH_VIEW: 'toggle-search-view',
    EVENT_CHG_ADR_BACK: 'changeAdr:back',
    EVENT_UPDATE_USER_DATA: 'updateUserData',
    URL_MENU_CHG_ADRS_ALRT: 'chg-adresse',
    URL_MENU_EDIT_STATUT_ALRT: 'edit-statut',
    URL_MENU_ALRT_DESC: 'alerte-description',
    URL_CONFIRM_CHG_STATUT: 'confirm',
    ALERT_MODAL_TYPE_WARNING: 'warning',
    ALERT_MODAL_TYPE_CONFIRM: 'confirm',
    ALERT_MODAL_TYPE_ERROR: 'error',
    ALERT_MODAL_TYPE_INFO: 'info',
    ALERT_MODAL_ICON_ALERT: 'alert',
    ALERT_MODAL_ICON_CHECK: 'check',
    ALERT_MODAL_ICON_DELETE: 'delete',
    ALERT_MODAL_CUSTOM_ICON_SHIELD: 'bouclier',
    REGEX_PWD: `^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*)(?=.*[._@àáâãäåìíîïèéêùúûü$!%*?&+=-])[A-Za-z0-9@._àáâãäåìíîïèéêùúûü$!%*?&+=-]{8,}$`,
    REGEX_EMAIL: `^[a-zA-Z0-9._àáâãäåìíîïèéêùúûü$!%*?&+=-]+@[a-z0-9.àáâãäåìíîïèéêùúûü$!%*?&=+-]+\\.[a-z]{2,4}$`,
    FROM_PAGE_STORAGE_KEY: 'fromPage',
    APP_RAN_AT_LEAST_ONCE_STORAGE_KEY: 'appRanAtLeastOnce',
    KEYCODE_ENTER: 13,
    CONTACT_EMAIL: 'fr.water.vedif.dsi-digital.all.groups@veolia.com',
    CONTACT_EMAIL_SUBJECT: 'Renseignements ou suggestions sur l\'application JIMI'
};
