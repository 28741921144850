import { Component, OnInit, Input } from '@angular/core';
import { AlerteTypeGraphiqueOptions } from './model/AlerteTypeGraphiqueOptions';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-alerte-type-button',
  templateUrl: './alerte-type-button.component.html',
  styleUrls: ['./alerte-type-button.component.scss'],
})
export class AlerteTypeButtonComponent implements OnInit {


  typeLeft = false;
  typeRight = false;
  typeDefault = false;
  typeMiddle = false;

  @Input() options: AlerteTypeGraphiqueOptions;
  @Input() status = false;
  @Input() typeBtn: string;
  constructor(private logger: NGXLogger) {
   this.logger.debug('Hello AlerteTypeButtonComponent Component');
  }

  ngOnInit() {
    this.setType();
    this.logger.debug('typeButton: ' + this.typeBtn);
    this.logger.debug('options: ' + JSON.stringify(this.options));
  }

  private setType() {

    if (this.typeBtn === 'left') {
       this.typeLeft = true;
    }

    if (this.typeBtn === 'right') {
      this.typeRight = true;
    }

    if (this.typeBtn === 'middle') {
      this.typeMiddle = true;
    }

    if (this.typeBtn === 'default') {
       this.typeDefault = true;
    }

    }

}
