import { Injectable, Injector } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { AlertComponent } from '../../../app/components/alert/alert.component';
import { APP_CONFIG } from '../../../config/app.config';
import { LABELS } from '../../../config/labels.config';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {


  alerteActive: boolean;
  alert: HTMLIonModalElement;
  TAG: string = 'AlertService';

  constructor(private injector: Injector,
    private modalCtrl: ModalController,
    private utilsService: UtilsService,
    private logger: NGXLogger
  ) {
  }

  async presentAlert(title: string, message: string, dismissLabel?: string, dismissCb?: () => void) {
    if (!dismissCb && !this.utilsService.isNativeApp()){
      dismissCb = this.setDissmissCbAsHistoryBack(`${this.TAG} presentAlert`);
    }
    const confirm = await this.modalCtrl.create({
      component: AlertComponent,
      componentProps: { title, message, typeAlert: APP_CONFIG.ALERT_MODAL_TYPE_WARNING, iconName: APP_CONFIG.ALERT_MODAL_ICON_ALERT, buttonOK: dismissLabel || LABELS.alert_label_dismiss, okHandler: dismissCb },
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'alert-modal'
    });
    confirm.present().catch(err => this.injector.get(NGXLogger).error(err));
    this.utilsService.historyPushThisHrefIfWeb(`${this.TAG} presentAlert`);
  }

  async presentAlertInfo(title: string, message: string, dismissLabel?: string, dismissCb?: () => void) {
    if (!dismissCb && !this.utilsService.isNativeApp()){
      dismissCb = this.setDissmissCbAsHistoryBack(`${this.TAG} presentAlertInfo`);
    } 
    const confirm = await this.modalCtrl.create({
      component: AlertComponent,
      componentProps: { title, message, typeAlert: APP_CONFIG.ALERT_MODAL_TYPE_INFO, iconName: APP_CONFIG.ALERT_MODAL_ICON_CHECK, buttonOK: dismissLabel || LABELS.alert_label_dismiss, okHandler: dismissCb },
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'alert-modal'
    });
    confirm.present().catch(err => this.injector.get(NGXLogger).error(err));
    this.utilsService.historyPushThisHrefIfWeb(`${this.TAG} presentAlertInfo`);
  }

  async createAlert(title: string, message: string, dismissLabel?: string, dismissCb?: () => void) {
    return await this.modalCtrl.create({
      component: AlertComponent,
      componentProps: { title, message, typeAlert: APP_CONFIG.ALERT_MODAL_TYPE_WARNING, iconName: APP_CONFIG.ALERT_MODAL_ICON_ALERT, buttonOK: dismissLabel || LABELS.alert_label_dismiss, okHandler: dismissCb },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'alert-modal'
    });
  }

  async buildConfirmAlert(title: string, message: string, okHandler: () => void, koHandler: () => void, typeAlert: string, iconName?: string) {
    const bind = this;
    // On ajoute la fermeture du modal en faisant back if web
    if (!this.utilsService.isNativeApp()){
      koHandler = (()=> {
        bind.utilsService.historyBackifWeb(`${bind.TAG} buildConfirmAlert`);
        koHandler;
      })
    }
    this.utilsService.historyPushThisHrefIfWeb(`${bind.TAG} buildConfirmAlert`);
    return await this.modalCtrl.create({
      component: AlertComponent,
      componentProps: { title, message, typeAlert, iconName, buttonOK: LABELS.confirm_ok, buttonKO: LABELS.confirm_ko, okHandler, koHandler },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'alert-modal'
    });
  }

  async presentErrorAlert(title?: string, err?: string) {
    const bind = this;
    let okHandler;
    // On ajoute la fermeture du modal en faisant back if web
    if (!this.utilsService.isNativeApp()){
        okHandler = (()=> {
        bind.utilsService.historyBackifWeb(`${bind.TAG} presentErrorAlert`);
      })
    }
    const confirm = await this.modalCtrl.create({
      component: AlertComponent,
      componentProps: { title, message: err, typeAlert: APP_CONFIG.ALERT_MODAL_TYPE_ERROR, customIconName: APP_CONFIG.ALERT_MODAL_CUSTOM_ICON_SHIELD, buttonOK: LABELS.alert_label_dismiss, okHandler },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'alert-modal'
    });
    this.utilsService.historyPushThisHrefIfWeb(this.TAG);
    confirm.present().catch(err => this.injector.get(NGXLogger).error(err));
  }

  setAlerteActive(alerteActive: boolean) {
    this.alerteActive = alerteActive;
  }

  isAlerteActive() {
    return this.alerteActive;
  }

  setAlert(alert: HTMLIonModalElement) {
    this.alert = alert;
  }

  getAlert() {
    return this.alert;
  }

  setDissmissCbAsHistoryBack(TAG: String) {
    const res = (() => {
          this.injector.get(NGXLogger).debug(`${TAG} history.back()`)
          history.back();
        });
    return res;
  }



}
