import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PATHS_CONFIG } from '../../../config/paths.config';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login-email-form',
  templateUrl: './login-email-form.component.html',
  styleUrls: ['./login-email-form.component.scss'],
})
export class LoginEmailFormComponent implements OnInit {

  @Output() loginEmail = new EventEmitter<any>();

  text: string;
  showingForgottenPasswordForm;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

  loginWithEmail(form: NgForm) {
    this.loginEmail.emit(form.value);
  }

  initForgottenPasswordForm() {
    this.router.navigate(['..' + PATHS_CONFIG.LOST_PASSWORD_PATH], { relativeTo: this.route });
  }

  handleKeyEnter(event) {
    event.preventDefault();
  }
}
