import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ENV } from '../../../config/env.config';
import { ResumeResponse } from '../rest/model/resumeResponse';
import { NetService } from '../net/net.service';


@Injectable({
  providedIn: 'root'
})
export class RestCommonService {

  constructor(public http: HttpClient,
    private logger: NGXLogger,
    private netService: NetService) {
    this.logger.debug('Hello RestCommonService Service');
  }

  async getResumeData(params): Promise<ResumeResponse> {
    this.logger.debug('getting summary data');
    const headers = await this.netService.buildHeadersWithIdToken();
    return this.http.post(ENV.DATA_GATEWAY_URL + 'resume', params, { headers })
    .toPromise() as Promise<ResumeResponse>;  
  }

  async getResumeList(params): Promise<ResumeResponse> {
    this.logger.debug('getting summary list');
    const headers = await this.netService.buildHeadersWithIdToken();
    return this.http.post(ENV.DATA_GATEWAY_URL + 'resumeList', params, { headers })
      .toPromise() as Promise<ResumeResponse>;
  }

  async getMyResume(token): Promise<any> {
    this.logger.debug('getting my resume');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.get(ENV.DATA_GATEWAY_URL + 'myresume', { headers })
      .toPromise();
  }
}
