import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ENV } from 'src/config/env.config';
import { APP_CONFIG } from '../../../config/app.config';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NetService {

  constructor(public http: HttpClient,
    private authProvider: AuthService,
    private logger: NGXLogger) {
  }

  async buildHeadersWithIdToken() {
    let token = '';
    try {
      token = await this.authProvider.getIdToken();
    } catch (err) {
      this.logger.error('NetService buildHeadersWithIdToken()', err);
    }

    return await this.buildHeadersWithToken(token);
  }

  async buildHeadersWithToken(token) {
    let headers = new HttpHeaders();
    const apiGeeHeaders = {[APP_CONFIG.HEADER_AUTHORIZATION_KEY]: `Basic ${btoa(ENV.APIGEE_KEY+":"+ENV.APIGEE_SECRET)}`,
      "Content-Type":"application/x-www-form-urlencoded"};
    let body = new URLSearchParams();
    body.set('grant_type', "client_credentials");
    const apigeeToken = await this.http.post(ENV.HUB_API_URL + '/security/v2/oauth/token',body.toString(), {headers: apiGeeHeaders}).toPromise();
    headers = headers.append(APP_CONFIG.HEADER_AUTHORIZATION_KEY, APP_CONFIG.HEADER_AUTHORIZATION_VALUE_BEARER_PREFIX + apigeeToken["access_token"]);
    headers = headers.append("x-hubapi-jwt", APP_CONFIG.HEADER_AUTHORIZATION_VALUE_BEARER_PREFIX + token);
    return headers;
  }

  buildHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append(APP_CONFIG.HEADER_CONTENT_TYPE_KEY, APP_CONFIG.HEADER_CONTENT_TYPE_APPLICATION_JSON);
    return headers;
  }

  async buildHeaderWithAccessToken(token){
    let headers = new HttpHeaders();
    headers = headers.append(APP_CONFIG.HEADER_AUTHORIZATION_KEY, APP_CONFIG.HEADER_AUTHORIZATION_VALUE_BEARER_PREFIX + token);
    this.logger.debug("buildHeaderWithAccessToken" + token);
    this.logger.debug(headers);
    return headers;
  }
}
