import { Component, OnInit, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '../../../config/app.config';
import { Events, ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from '../../../app/services/utils/utils.service';
import { PAGES_CONFIG } from '../../../config/pages.config';

@Component({
  selector: 'app-alerte-description',
  templateUrl: './alerte-description.page.html',
  styleUrls: ['./alerte-description.page.scss'],
})
export class AlerteDescriptionPage implements OnInit, OnDestroy {

  alertDescription: string;
  isValid: boolean;
  isDismissedWithBackBtn;
  TAG:string='AlerteDescriptionPage'

  constructor(private logger: NGXLogger,
              private utilService: UtilsService,
              public modalCtrl: ModalController,
              public event: Events) {
  }

  ngOnInit() {
    this.logger.log('ionViewDidLoad AlerteDescriptionPage');
    this.utilService.historyPushThisHrefIfWeb(this.TAG);
    /*this.event.subscribe(APP_CONFIG.EVENT_CHG_DESC_ALERTE_BACK, () =>{
      this.logger.debug('AlertDescriptionPage#ngOnInit() callback event EVENT_CHG_DESC_ALERTE_BACK');
      this.isDismissedWithBackBtn = true;
      this.cancelAction();
    });*/
  }

  
  ngOnDestroy(){
    this.logger.log('ngOnDestroy AlerteDescriptionPage');
    this.logger.log('this.isDismissedWithBackBtn value : ' + this.isDismissedWithBackBtn);
    /*if (!this.isDismissedWithBackBtn){
      history.back();
    }*/
    // this.event.unsubscribe(APP_CONFIG.EVENT_CHG_DESC_ALERTE_BACK);
  }
    /*
   * Callback called when description is updated.
   * It updates the description value and update validity boolean
   * @param alertDescriptionValue alert description value
   */
  onAlertDescriptionChange(alertDescriptionValue) {
    this.alertDescription = alertDescriptionValue;
    // validity based on alert description
    const alertDescriptionValidity = (this.alertDescription && this.alertDescription.length > 0) && (this.alertDescription.length <= APP_CONFIG.ALERT_DESCRIPTION_MAX_DEFAULT);
    this.isValid = alertDescriptionValidity && !this.utilService.hasEmoji(this.alertDescription);
  }

  cancelAction() {
    this.modalCtrl.dismiss().
    then(() => {this.onDismissSuccess(); }
    ).
    catch(err => {this.logger.error(err); }
    );
  }

  saveAction() {
    this.modalCtrl.dismiss(this.buildDismissresponse(this.alertDescription)).
    then(() => {this.onDismissSuccess(); }
    ).
    catch(err => {this.logger.error(err); }
    );
  }

    // Recuperation d'objet à passer à la vue detail d'alerte
  private buildDismissresponse(description) {
      return {
        description,
      };
  }

  private onDismissSuccess() {
    this.logger.debug('AlertDescriptionPage dismiss view');
    this.utilService.historyBackifWeb(this.TAG)
    // if (!this.isDismissedWithBackBtn){
    //   history.back();
    // }
    // this.event.unsubscribe(APP_CONFIG.EVENT_CHG_DESC_ALERTE_BACK);
  }


}
