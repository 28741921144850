import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../../../config/app.config';
import { NGXLogger } from 'ngx-logger';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(public http: HttpClient,
    public logger: NGXLogger,
    public storage: Storage
                ) {
    this.logger.log('Hello StorageProvider Provider');
  }

  setUserPhone(userPhone) {
    return this.storage.set(APP_CONFIG.USER_PHONE_KEY, userPhone);
  }

  async getUserPhone(): Promise<string> {
    return this.storage.get(APP_CONFIG.USER_PHONE_KEY);
  }

  setUserToken(userToken) {
    return this.storage.set(APP_CONFIG.USER_ACCESSTOKEN_KEY, userToken);
  }

  async getUserToken(): Promise<string> {
    return this.storage.get(APP_CONFIG.USER_ACCESSTOKEN_KEY);
  }

}
