import { Component, OnInit, Input, EventEmitter, ElementRef, NgZone, Output } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { AlertDescriptionAreaOptions } from './model/alertDescriptionAreaOptions';

import { LABELS } from '../../../config/labels.config';
import { APP_CONFIG } from '../../../config/app.config';

import { ToastService } from '../../../app/services/toast/toast.service';
import { UtilsService } from '../../../app/services/utils/utils.service';

@Component({
  selector: 'app-alert-description-area',
  templateUrl: './alert-description-area.component.html',
  styleUrls: ['./alert-description-area.component.scss'],
})
export class AlertDescriptionAreaComponent implements OnInit {


  hasFocus = false;

  @Input() options: AlertDescriptionAreaOptions;

  private _alertDescription: string;

  @Input()
  set alertDescription(value) {
    this._alertDescription = value;
  }

  get alertDescription() {
    return this._alertDescription;
  }

  // get alertDescription() {
  //   return this.alertDescriptionValue;
  // }

  // set alertDescription(val) {
  //   this.alertDescriptionValue = val;
  //   this.alertDescriptionChange.emit(this.alertDescriptionValue);
  // }

  @Output() alertDescriptionChange = new EventEmitter();

  constructor(public element: ElementRef,
              private toastService: ToastService,
              private zone: NgZone,
              private utilsService: UtilsService,
              private logger: NGXLogger) {
    this.logger.debug('Hello AlertDescriptionAreaComponent Component');
  }

  /**
   * Initialisation des valeurs par défaut si elles n'ont pas été définiespour les propriétés
   * - placeholder
   * - maxLength
   */
  ngOnInit() {

    if (!this.options) {
      this.options = {
        placeholder: LABELS.alert_description_area_placeholder_default,
        maxLength: APP_CONFIG.ALERT_DESCRIPTION_MAX_DEFAULT,
      };
    }

    if (!this.options.placeholder) {
      this.options.placeholder = LABELS.alert_description_area_placeholder_default;
    }

    if (!this.options.maxLength) {
      this.options.maxLength = APP_CONFIG.ALERT_DESCRIPTION_MAX_DEFAULT;
    }
  }

  /**
   *  when user focuses in textarea, we display the counter
   */
  onFocus() {
    this.toggleHasFocus();
  }

  /**
   * When user focuses out of textarea, we run several actions
   * - hide counter
   * - check if limit has been exceeded and display toast if it has
   */
  onBlur() {
    this.toggleHasFocus();
    this.checkValidity();
  }

  /**
   * Toggles boolean that tells whether there is focus on textarea or not
   */
  toggleHasFocus() {
    this.logger.debug('toggleHasFocus()');
    // this.hasFocus = !this.hasFocus;
    this.logger.debug('this.hasFocus : ' + this.hasFocus);
    this.zone.run(() => {
      this.hasFocus = !this.hasFocus;
    });
  }

  /**
   * Runs a series of actions on input change
   * - autosize text area
   * - check if text has emoji and display toast warning message if so
   * - update parent component alertDescription value
   */
  onInputChange() {
    this.autoSizeTextarea();
    this.checkEmoji();
    this.zone.run(() => {
      this.alertDescriptionChange.emit(this.alertDescription);
    });
  }

  /**
   * resize text area height dynamically
   */
  protected autoSizeTextarea(): void {
    const textArea = this.element.nativeElement.getElementsByTagName('textarea')[0];
    textArea.style.overflow = 'hidden';
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
    return;
  }

  /**
   * this methods checks description area validity and displays toast if it is not valid
   */
  private checkValidity() {
    if (this.alertDescription.length > this.options.maxLength) {
      this.toastService.presentToast(LABELS.alert_description_area_toast_invalid_length, APP_CONFIG.TOAST_TYPE_ALERT, {
        position: 'top',
        duration: 3000,
      });
    }
  }

  /**
   * this method checks if description text has emoji and display a warning message if it does
   */
  private checkEmoji() {
    if (this.alertDescription && this.alertDescription.length > 0 && this.utilsService.hasEmoji(this.alertDescription)) {
      this.toastService.presentToast(LABELS.alert_description_area_toast_emoji_invalid_input, APP_CONFIG.TOAST_TYPE_ALERT, APP_CONFIG.TOAST_REGULAR_CONFIG);
    }
  }


}
