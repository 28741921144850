import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CALLABLE_CLOUD_FUNCTIONS } from '../../../config/enum.config';
import { DatabaseService } from '../database/database.service';
import { NGXLogger } from 'ngx-logger';
import { ENV } from '../../../../env.config.dev';

@Injectable({
  providedIn: 'root'
})
export class RestAuthService {

  constructor(public http: HttpClient,
              private databaService: DatabaseService,
              private logger: NGXLogger) {
    this.logger.info('Hello RestAuthService Service');
  }

  async sendPasswordResetEmail(email) {
    let result: Promise<any>;
    result = this.databaService.callCloudFunction(CALLABLE_CLOUD_FUNCTIONS.SEND_PASSWORD_RESET_EMAIL, email);
    return result;
  }

}

