export class InterventionResumeData {
    id: string;
    loc: string;
    libTypeInter: string;
    codeStatut: number;
    codeStatutAe: number;
    codeStatutAlert: number;
    linkAtlas: string;
    linkSigma: string;
    latitude: string;
    longitude: string;
    titre: string;
    ligne1: string;
    ligne2: string;
}
