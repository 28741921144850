import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { UserProfilMetadata } from './model/userProfilMetadata';
import { APP_CONFIG } from '../../../config/app.config';
import { PageMetadata } from './model/pageMetadata';
import { VariableData } from './model/variableData';
import { DEFAULT_METADATA as defaultMetadata }  from './model/defaultMetadata';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(
    private utilService: UtilsService,
    private logger: NGXLogger
  ) {
    this.logger.log('Hello MetadataProvider Provider');
  }

  private _metadata: UserProfilMetadata;

  get metadata(): UserProfilMetadata {
    return this._metadata;
  }

  set metadata(val: UserProfilMetadata) {
    this._metadata = val;
  }

  initMetadata() {
    const savedMetaData = this.getMetadataInStorage();
    this._metadata = (savedMetaData && JSON.parse(savedMetaData)) || this.utilService.createCopyFromObject(defaultMetadata);
  }

  setMetadataInStorage() {
    localStorage.setItem(APP_CONFIG.METADATA_KEY, JSON.stringify(this._metadata));
  }

  getMetadataInStorage() {
    return localStorage.getItem(APP_CONFIG.METADATA_KEY);
  }

  getPageMetadata(pageName: string): PageMetadata {
    const metadataCopy = this.utilService.createCopyFromObject(this._metadata);
    return metadataCopy.pages.find(element => {
      return element.name === pageName;
    });
  }

  getPageVariableData(pageName: string, variableName: string): VariableData {
    const pageMetadata: PageMetadata = this.getPageMetadata(pageName);
    return pageMetadata.variables.find(element => {
      return element.name === variableName;
    });
  }

  getProviderMetadata(providerName: string): PageMetadata {
    return this._metadata.providers.find(element => {
      return element.name === providerName;
    });
  }

  getProviderVariableData(providerName: string, variableName: string): VariableData {
    const providerMetadata: PageMetadata = this.getProviderMetadata(providerName);
    return providerMetadata.variables.find(element => {
      return element.name === variableName;
    });
  }

  setDefaultMetadata() {
    this._metadata = this.utilService.createCopyFromObject(defaultMetadata);
  }

  getIsDefaultMetadata(){
    return this.utilService.compareArrayByJson(this._metadata,defaultMetadata);
  }
}
