import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, throwError } from 'rxjs';
import { ENV } from '../../../config/env.config';
import { AuthService } from '../auth/auth.service';
import { NetService } from '../net/net.service';
import { NotificationCountRequest } from './model/NotificationCountRequest';
import { NotificationListResponse } from './model/NotificationListResponse';
import { NotificationUpdateRequest } from './model/NotificationUpdateRequest';

@Injectable({
  providedIn: 'root'
})
export class RestNotifService {

  notifCount = 0;
  notificationsOfUser = new Subject<NotificationListResponse>();

  _notifCountSource: Subject<number> = new Subject();
  get notifCountSource(): Subject<number> {
    return this._notifCountSource;
  }
  set notifCountSource(src: Subject<number>) {
    this.notifCountSource = src;
  }

  constructor(public http: HttpClient,
              private authService: AuthService,
              private logger: NGXLogger,
              private netService: NetService) {
    logger.debug('Hello RestNotifService Service');
  }


  getNotificationsOfThisUser(token): Observable<NotificationListResponse> {
    this.logger.debug('getting notification list');
    
    this.netService.buildHeaderWithAccessToken(token)
    .then(headers => {
      this.http.get<NotificationListResponse>(ENV.DATA_GATEWAY_URL + 'notificationDetailList', { headers })
      .toPromise()
      .then(response => {
        this.notificationsOfUser.next(response);
      })
      .catch(err => {
        this.logger.error(err);
      });
    });

    return this.notificationsOfUser.asObservable();

    /*
    return from(
      this.netService.buildHeadersWithIdToken()
      .then(headers => {
        return this.http.get<NotificationListResponse>(ENV.DATA_GATEWAY_URL + 'notificationDetailList', { headers })
        .toPromise() as Promise<NotificationListResponse>;  
      })
    );//.pipe(catchError(this.handleError));*/
  }

  // async xgetNotificationsOfThisUser(): Promise<NotificationListResponse> {

  //   this.logger.debug('getting notification list');
  //   const headers = await this.netService.buildHeadersWithIdToken();
  //   return this.http.get(ENV.DATA_GATEWAY_URL + 'notificationDetailList', { headers })
  //   .toPromise() as Promise<NotificationListResponse>;
  // }

  async getNotificationCount(source) {
    this.logger.debug('Call getNotificationCount()', source);
    const headers = await this.netService.buildHeadersWithIdToken();
    const notificationCountRequest: NotificationCountRequest = new NotificationCountRequest();
    notificationCountRequest.userEmail = this.authService.getUserEmail();
    notificationCountRequest.codesStatutNotif = [0, 1, 3];
    const obs = this.http.post(ENV.DATA_GATEWAY_URL + 'notificationCount', notificationCountRequest, { headers }) as Observable<any>;
    obs.subscribe(
        res => {
            this.notifCountSource.next(res.data);
        });
    return obs;

  }

  async updateStatutNotif(token, notificationUpdateRequest: NotificationUpdateRequest): Promise<any> {
    this.logger.debug('update notification statut');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.post(ENV.DATA_GATEWAY_URL + 'updateStatutNotification', notificationUpdateRequest, { headers })
    .toPromise();
  }

  private handleError(error: any) {
    console.log(error);
    return throwError(error);
  }

}