export class CommonData {
    id: string;
    idIntervention: string;
    idUtil: string;
    statutInter: string;
    codeStatutInter: number;
    statutAlerte: string;
    codeStatutAlerte: number;
    typeInter: string;
    typeAlerte: string;
    codeTypeAlerte: number;
    hasAe: boolean;
    statutAe: string;
    codeStatutAe: number;
    titre: string;
    ligne1: string;
    ligne2: string;
    codeStatutAlertePrio: number;
    dataType: string;
    latitude: string;
    longitude: string;
    email: string;
    dateCreationAlerte: number;
}
