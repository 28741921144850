import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LOGIN_OPTIONS } from '../../../config/enum.config';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-login-options',
  templateUrl: './login-options.component.html',
  styleUrls: ['./login-options.component.scss'],
})
export class LoginOptionsComponent implements OnInit {

  @Output() login = new EventEmitter<LOGIN_OPTIONS>();

  text: string;

  constructor(private logger: NGXLogger) {
    this.logger.log('constructor LoginOptionsComponent');
  }

  ngOnInit(): void {
  }

  signIn(loginOption: LOGIN_OPTIONS) {
    this.login.emit(loginOption);
  }

}
