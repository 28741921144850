import { Injectable } from '@angular/core';
// tslint:disable-next-line: no-import-side-effect
import 'firebase/database';
// tslint:disable-next-line: no-import-side-effect
import 'firebase/functions';
import * as firebase from 'firebase/app';

import { APP_CONFIG } from '../../../config/app.config';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { DateService } from '../date/date.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  user;
  refJimmyUserActivity = APP_CONFIG.REF_JIMI_USER_ACTIVITY;
  refJimmyUserConnection = APP_CONFIG.REF_JIMI_USER_CONNECTION;

  constructor(public http: HttpClient,
              private logger: NGXLogger,
              private dateService: DateService
    ) {
this.logger.debug('Hello DatabaseService Service');
}

  callCloudFunction(functionName, param) {
    this.logger.debug(`Call to callCloudFunction() with functionName = ${functionName} and param = ${ JSON.stringify(param, undefined, 3) }`);
    const functions_eu = firebase.app().functions('europe-west1');
    const cf = functions_eu.httpsCallable(functionName);
    return cf(param);
  }

  // Mettre à jour l'objet userActivity dans la base JIMI de FBRTDB
  writeUserActivityData(userActivity: any): Promise<any> {
    this.logger.debug('writeUserActivityData >>> ' + JSON.stringify((userActivity)));
    return firebase.database().ref(this.refJimmyUserActivity + this.getFirebaseUser().uid).set(userActivity);
  }

  // Mettre à jour l'objet userActivity/updateAlert dans la base JIMI de FBRTDB
  writeUpdateAlerte(updateAlerteAction: any): Promise<any> {
    this.logger.debug('writeUpdateAlerte >>> ' + JSON.stringify((updateAlerteAction)));
    return firebase.database().ref(this.refJimmyUserActivity + this.getFirebaseUser().uid + '/updateAlerte').set(updateAlerteAction);
  }

  // Mettre à jour l'objet userActivity/createAlerte dans la base JIMI de FBRTDB
  writeCreateAlerte(alerteDetail, email, context, emailCopieCachee, addressBefore?): Promise<any> {
    const request = this.buildCreateAlerteAction(alerteDetail, email, context, emailCopieCachee, addressBefore);
    this.logger.debug('writeCreateAlerte >>> ' + JSON.stringify((request)));
    return firebase.database().ref(this.refJimmyUserActivity + this.getFirebaseUser().uid + '/createAlerte').set(request);
  }

  writeUserConnection(firebaseUser) {
    return firebase.database().ref(this.refJimmyUserConnection + firebaseUser.uid).set(this.buildUserConnection(firebaseUser));
  }

  // Créer l'objet userACtivity s'il n'existe ou il y a un noveua registrationToken
  initUserActivity(registrationToken): Promise<any> {
    return firebase.database().ref(this.refJimmyUserActivity + this.getFirebaseUser().uid + '/registrationToken').once('value').then(results => {
      if (results.val() !== registrationToken) {
        return this.writeUserActivityData(this.buildDefaultUserActivity((registrationToken)));
      }
    });
  }

    // Créer l'objet userACtivity s'il n'existe ou il y a un noveua registrationToken
  clearregistrationToken(): Promise<any> {
      const firebaseUser = this.getFirebaseUser();
      if (firebaseUser) {
        return firebase.database().ref(this.refJimmyUserActivity + firebaseUser.uid + '/registrationToken').set('');
      }
      return;
  }

  buildUserConnection(firebaseUser) {
    return {
      userConnection: {
        displayName: firebaseUser.displayName,
        email: firebaseUser.email,
        date: this.dateService.getFormatedDate(),
      },
    };
  }

  private buildCreateAlerteAction(alerteDetail, email, context, emailCopieCachee, addressBefore) {
    return {
      adresse: alerteDetail.adresse,
      alerteResponsableEmail: email,
      idAlerte: alerteDetail.id,
      typologie: alerteDetail.typeAlerte,
      // tslint:disable-next-line:no-null-keyword
      idIntervention: alerteDetail.idIntervention === undefined ? null : alerteDetail.idIntervention,
      context,
      emailCopieCachee,
      addressBefore: addressBefore || '',
      status: alerteDetail.statut,
      dateCreation: alerteDetail.dateCreation,
    };
  }

  buildDefaultUserActivity(registrationToken) {

    return {
      createAlerte: {
        alerteResponsableEmail: '',
        idAlerte: 0,
        adresse: '',
        typologie: '',
      },
      updateAlerte: {
        alerteCreatorEmail: '',
        alerteId: '',
        interventionId: '',
        status: '',
      },
      email: this.getFirebaseUser().email,
      registrationToken: registrationToken || '',
    };

  }

  getFirebaseUser() {
    return firebase.auth().currentUser;
  }


}
