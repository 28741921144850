import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'processDateNotif'
})
export class ProcessDateNotifPipe implements PipeTransform {

  constructor(public datePipe: DatePipe
    ) {
  
    }
  
    transform(timestamp) {
      if (timestamp) {
        timestamp = (new Date(timestamp)).getTime();
        const today = new Date();
        const dateDiff = today.getTime() - timestamp;
        const diffHeures = dateDiff / (1000 * 3600);
  
        if (diffHeures < 24) {
          return Math.floor(diffHeures) + ' h';
        } else {
          return this.datePipe.transform(new Date(timestamp), 'dd MMMM, HH:mm');
        }
      }
      return timestamp;
    }
  

}
