import { Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';

import { ENV } from '../../../config/env.config';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {


  constructor(
    private utilsService: UtilsService,
    private logger: NGXLogger,
  ) { }

  async init () {
    this.logger.debug("AppInitService.init() called");
    const res = await this.addGoogleMapsApiScriptToHead();
    return res;
   
  }

  private addGoogleMapsApiScriptToHead() {
    this.logger.debug('AppInitService addGoogleMapsApiScriptToHead()');
    const url = 'https://maps.google.com/maps/api/js?key=' + ENV.MAPS_API_KEY + '&libraries=places';
    return this.utilsService.load_script_promise(url);
  }

}
