import { Pipe, PipeTransform } from '@angular/core';
import { LABELS } from '../../../config/labels.config';

@Pipe({
  name: 'localization'
})
export class LocalizationPipe implements PipeTransform {

  /**
   * Takes a key value and returns the label associated to it
   */
  transform(value: string, ...args) {
    return LABELS[value];
  }

}
