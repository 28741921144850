import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(public http: HttpClient,
    private logger: NGXLogger) {
    this.logger.debug('Hello DateService Provider');
  }

  getFormatedDate() {
    return moment().format('YYYY/MM/DD HH:mm:ss');
  }
  getPhotoDate(){
    return moment().format('YYYYMMDD_HHmmss');
  }

}
