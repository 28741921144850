import { Component, ViewChild, NgZone } from '@angular/core';

import { Platform, MenuController, Events, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { PAGES_CONFIG } from '../config/pages.config';
import { ENV } from '../config/env.config';
import { UtilsService } from './services/utils/utils.service';
import { NGXLogger } from 'ngx-logger';
import { AlertService } from './services/alert/alert.service';
import * as firebase from 'firebase/app';
import { APP_CONFIG } from '../config/app.config';
import { DataService } from './services/data/data.service';
import { FilterService } from './services/filter/filter.service';
import { MetadataService } from './services/metadata/metadata.service';
import { Router } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SearchBarService } from './services/search-bar/search-bar.service';
import { AlerteDetailPage } from './pages/alerte-detail/alerte-detail.page';
import { RoutingService } from './services/routing/routing.service';
import { UsrMgmtPage } from './pages/usr-mgmt/usr-mgmt.page';
import { PATHS_CONFIG } from './../config/paths.config';
import { Location } from '@angular/common';
import { MapService } from './services/map/map.service';
import { MODAL_ID_LIST } from 'src/config/enum.config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  rootPage: any = PAGES_CONFIG.LOGIN_PAGE;

  constructor(private platform: Platform,
    public splashScreen: SplashScreen,
    private utilsService: UtilsService,
    private logger: NGXLogger,
    private menuCtrl: MenuController,
    private alertService: AlertService,
    private events: Events,
    private dataService: DataService,
    private metadataService: MetadataService,
    private filterService: FilterService,
    private router: Router,
    private zone: NgZone,
    private searchBarService: SearchBarService,
    private deeplinks: Deeplinks,
    private routingService: RoutingService,
    private location: Location,
    private modalController: ModalController,
    private mapService: MapService) {

    this.logger.debug('constructor MyApp');

    this.routingService.loadRouting();


    firebase.initializeApp(ENV.FIREBASE_CONFIG);

    this.metadataService.initMetadata();
    this.filterService.initFilterService();

    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      this.logger.debug('platform ready callback');

      
      this.splashScreen.hide();
      
      // clear badge number (it seembs to works only for ios)
      this.utilsService.clearNativeBadge();

      this.handleDeepLinksRoute();

      this.handleSwipeMenuEnabling();
      this.handleNativeBackButton();
      if (!this.utilsService.isNativeApp()) {
        this.handleBackButtonAction();
      }

      // this.hadleFirebaseNotifications();

    }).catch(err => {
      this.logger.error('ERROR. Platform not ready. Check logs');
      this.logger.error(err);
    });

  }

  ngOnDestroy() {
    //  this.app.viewDidLoad.unsubscribe();
  }

  /**
   * Swipe menu has to be enabled or disabled depending on the opening page
   */
  private handleSwipeMenuEnabling() {
    /*  this.app.viewDidEnter.subscribe(evt => {
        const page = evt.id;
        const enableSwipe = APP_CONFIG.PAGES_WITH_MENU_SWIPE_ALLOWED.indexOf(page) > 0;
        this.menuCtrl.swipeEnable(enableSwipe);
      });*/
  }

  // Register browser back button action(s)
  private handleBackButtonAction() {

    const id_list_modal_not_to_close = [
      MODAL_ID_LIST[MODAL_ID_LIST.TOAST_ALERT_CREATE_PAGE_SUCCESS]
    ];

    window.onpopstate = async e => {

      const lastVisitedUrl = this.routingService.getHistory()[0];
      const MAP_PAGE_PATH = '/global-menu/tabs/tabs/map';
      const modalElement = await this.modalController.getTop();

      this.logger.debug('AppComponent handleBrowserBackAction() callback fired');
      if (modalElement) {

        if (!(modalElement.id && id_list_modal_not_to_close.includes(modalElement.id))) {
          this.logger.log('Appcomponent handleBrowserBackAction Modal controller dismiss');
          this.modalController.dismiss();
        }
        
      }else if (await this.menuCtrl.isOpen()) {
        this.utilsService.setIsMenuClosedBySideClick(false);
        this.logger.debug('AppComponent handleBrowserBackAction closing menu');
        this.menuCtrl.close().catch(err => this.logger.debug(err));
      } else if (this.isSearchViewVisible()) {
        this.logger.debug('AppComponent handleBrowserBackAction closing searchview');
        this.searchBarService.showSearchView = false;
        this.events.publish(APP_CONFIG.EVENT_CHG_ADR_BACK);
      } else if (this.mapService.hasInterventionResume() && lastVisitedUrl === MAP_PAGE_PATH) {
        this.logger.debug('AppComponent handleBrowserBackAction closing resume tab');
        this.events.publish(APP_CONFIG.EVENT_RESUME_CLEAR);
      } 

      
      /*this.logger.debug('Call to window.onpopstate callback AppComponent', window.location.href);

      const lastVisitedUrl = this.routingService.getHistory()[0];

      this.logger.debug('last visited url AppComponent', lastVisitedUrl);

      if (this.alertService.isAlerteActive()) {
        this.manageAlertIsOpen();
      } else if (this.dataService.displayEmailLoginForm) {
        this.dataService.displayEmailLoginForm = false;
      } else if (this.isSearchViewVisible()) {
        this.searchBarService.showSearchView = false;
        this.logger.debug("Angular Back event");
        if (this.platform.is('android')) {
          this.logger.debug("Redirection to map page");
          this.globalGoToMapPage();
        } else {
          this.events.publish(APP_CONFIG.EVENT_CHG_ADR_BACK);
        }
      } else if (!this.maganageBackActionWhenEditAlerte()) {
        this.manageCameFromNotification();
      }


      this.menuCtrl.isOpen().then(isAnyMenuOpened => {
        if (isAnyMenuOpened) {
          this.logger.debug('closing menu AppComponent');
          this.menuCtrl.close().catch(err => this.logger.debug(err));
        }
      })
        .catch(menuopenerr => this.logger.error(menuopenerr));


      if (this.mapService.hasInterventionResume() && lastVisitedUrl === '/global-menu/tabs/tabs/map') {
        this.events.publish(APP_CONFIG.EVENT_RESUME_CLEAR);
      }*/

    };
  }
  /**
   * handle hardware back button specifically for android
   * this is a workaround to fix some issues with back button behaviour
   * CDIG-2337
   */
  private handleNativeBackButton() {
    if (this.utilsService.isNativeApp()) {

      const MAP_PAGE_PATH = '/global-menu/tabs/tabs/map';
      const LIST_PAGE_PATH = '/global-menu/tabs/tabs/list';

      this.platform.backButton.subscribeWithPriority(102, async () => {
        this.logger.debug('AppComponent. handleNativeBackButton() backButton.subscribe callback');
        const lastVisitedUrl = this.routingService.getHistory()[0];
        this.logger.debug('last visited url ===>>> ', lastVisitedUrl);
        if (await this.modalController.getTop()) {
          this.logger.log('appcomponent Modal controller dismiss');
          this.modalController.dismiss();
        } else if (lastVisitedUrl !== MAP_PAGE_PATH && !lastVisitedUrl.includes(LIST_PAGE_PATH)) {
          this.logger.log('appcomponent location.back()');
          this.location.back();
        }
      });

    }
  }

  public globalGoToLoginPage() {
    this.logger.debug('call to globalGoToLoginPage()');
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.AUTH_PATH + PATHS_CONFIG.LOGIN_PATH]).catch(err => this.logger.error(err));
  }

  public globalGoToMapPage() {
    this.logger.debug('call to globalGoToMapPage()');
    const navigationExtras = {
      showSearchView: false
    };
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.MAP_PATH, navigationExtras], { replaceUrl: true }).catch(err => this.logger.error(err));
  }

  public globalGoToLoginWithEmailPage() {
    this.logger.debug('call to globalGoToLoginWithEmailPage()');
    const loginNavExtras = { idAlerte: '', idNotif: '', fromPage: '' };
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.AUTH_PATH + PATHS_CONFIG.LOGIN_WITH_EMAIL_PATH, loginNavExtras]).catch(err => this.logger.error(err));
  }

  public globalGoToNotifPage() {
    this.logger.debug('call to globalGoToNotifPage()');
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.NOTIFICATION_PATH]).catch(err => this.logger.error(err));
  }

  public globalGoToMyAlertsPage() {
    this.logger.debug('call to globalGoToMyAlertsPage()');
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.MES_ALERTES_PATH]).catch(err => this.logger.error(err));
  }

  public globalGoToCreateAlertPage() {
    this.logger.debug('call to globalGoToCreateAlertPage()');
    this.router.navigate([PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.MAP_PATH + PATHS_CONFIG.ALERTE_CREATE_PATH]).catch(err => this.logger.error(err));
  }

  private maganageBackActionWhenEditAlerte() {

    this.logger.debug('AppComponent#maganageBackActionWhenEditAlerte()');

    // Si l'on vient de faire back depuis le menu créer une alerte set l'adresse  
    if (this.isCurrentAlertCreatelPage()) {
      this.logger.debug('AppComponent#maganageBackActionWhenEditAlerte() this.isCurrentAlertCreatelPage() true');
      this.events.publish(APP_CONFIG.EVENT_CHG_ADR_ALERTE, true);
      return true;
    }

    // Si l'on vient de faire back en étant dans un menu pour modifier une alerte        
    if (this.isCurrentAlertDetailPage()) {
      this.logger.debug('AppComponent#maganageBackActionWhenEditAlerte() this.isCurrentAlertDetailPage() true');
      this.events.publish(APP_CONFIG.EVENT_CHG_ADR_ALERTE, true);
      this.events.publish(APP_CONFIG.EVENT_CHG_DESC_ALERTE_BACK, true);
      return true;
    }

    if (window.history.state && window.history.state.isAlerteDetailPageFromNotif) {
      return false;
    }

    return false;
  }

  private isSearchViewVisible() {
    return this.searchBarService.showSearchView;
  }

  private isCurrentAlertDetailPage() {
    return this.isCurrentPage(PAGES_CONFIG.ALERTE_INFO_PAGE_SELECTOR);
  }

  private isCurrentAlertCreatelPage() {
    return this.isCurrentPage(PAGES_CONFIG.ALERTE_CREATE_PAGE_SELECTOR);
  }

  private isCurrentPage(currentURL) {
    return window.location.href.toString().includes(currentURL);
  }

  private manageCameFromNotification() {

    const activePage = this.utilsService.getActivePage();
    this.logger.debug('MyApp, manageCameFromNotification() >> activePAge ' + activePage);
    if (this.utilsService.getCamFromAlertLink()) {
      // CDIG-1269
      // handle if user comes from zoom page
      if (sessionStorage.getItem(APP_CONFIG.IMG_ZOOM_PAGE_STORAGE_KEY)) {
        // remove from session storage
        sessionStorage.removeItem(APP_CONFIG.IMG_ZOOM_PAGE_STORAGE_KEY);
        // do nothing
        return;
      }

      // CDIG-2269 remove unecessary code. it is handled in CommonAlerteAndInterventionService#manageIfWeCameFromAlertLink() -> changeHistoryStatus
    }
  }

  private handleDeepLinksRoute() {

    if (this.platform.is('cordova')) {
      this.deeplinks.route({
        '/alerteDetail/:idAlerte/:idNotif': AlerteDetailPage,
        '/alerte-detail/:idAlerte/:idNotif': AlerteDetailPage,
        '/usr-mgmt': UsrMgmtPage
      }).subscribe(match => {
        // match.$route - the route we matched, which is the matched entry from the arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        this.logger.log('Successfully matched route', JSON.stringify(match, undefined, 3));

        const urlObj = new URL(match.$link.url);
        const url = urlObj.pathname + urlObj.search;

        setTimeout(() => {
          this.router.navigateByUrl(url);
        }, 800);

      }, nomatch => {
        // nomatch.$link - the full link data
        this.logger.error('Got a deeplink that didn\'t match', nomatch);
      });
    }
  }
}