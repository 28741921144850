import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranscoAlerteStatut } from './model/transcoAlerteStatut';
// tslint:disable-next-line: no-import-side-effect
import * as firebase from 'firebase/app';
import "firebase/storage";

import { NGXLogger } from 'ngx-logger';
import { ENV } from '../../../config/env.config';
import { AuthService } from '../auth/auth.service';
import { NetService } from '../net/net.service';
import { AlerteDTO } from './model/alerteDTO';
import { AlerteDetailResponse } from './model/alerteDetailResponse';
import { AlerteMapStatusRequest } from './model/alerteMapStatutRequest';
import { AlerteMotifNonTraitableRequest } from './model/alerteMotifNonTrataitableRequest';
import { AlerteResumeResponse } from './model/alerteResumeResponse';
import { AlerteStatutResponse } from './model/alerteStatutResponse';
import { SingleAlerteResumeResponse } from './model/singleAlerteResumeResponse';
import { TranscoAlerteStatutResponse } from './model/transcoAlerteStatutResponse';
import { TranscoAlerteTypeResponse } from './model/transcoAlerteTypeResponse';
import { TranscoMotifNonTraitableResponse } from './model/transcoMotifNonTraitableResponse';

@Injectable({
  providedIn: 'root'
})
export class RestAlerteService {

  jimiImagesRef: any;
  transcoAlerteStatut: TranscoAlerteStatut[];

  constructor(public http: HttpClient,
              private netService: NetService,
              private authService: AuthService,
              private logger: NGXLogger) {
    this.logger.debug('Hello RestAlerteService Service');
    this.jimiImagesRef = firebase.storage().ref().child('JIMI');
  }

  /**
   * Get an alerte summary data based on params which will contain alerte id
   * @param params param
   */
  async getAlerteResume(params): Promise<SingleAlerteResumeResponse> {
    this.logger.debug('getting alerte summary');
    const headers = await this.netService.buildHeadersWithIdToken();
    return this.http.post(ENV.DATA_GATEWAY_URL + 'alerteResume', params, { headers })
      .toPromise() as Promise<SingleAlerteResumeResponse>;
  }

  /**
   * Get List of Alerts for an intervention
   * @param params if of the intervention
   */
  async getAlerteResumeList(params): Promise<AlerteResumeResponse> {
    this.logger.debug('getting alertes summary list');
    const headers = await this.netService.buildHeadersWithIdToken();
    return this.http.post(ENV.DATA_GATEWAY_URL + 'alerteResumes', params, { headers })
      .toPromise() as Promise<AlerteResumeResponse>;
  }

  /**
   * Get details of an alert
   * @param params : id of the alerte
   */
  async getAlerteDetail(params, token): Promise<AlerteDetailResponse> {
    this.logger.debug('getting alertes details');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.post(ENV.DATA_GATEWAY_URL + 'alerteDetail', params, { headers })
      .toPromise() as Promise<AlerteDetailResponse>;
  }

  /**
   * Update statut alerte
   */
  async updateAlerteStatut(token, params): Promise<AlerteStatutResponse> {
    this.logger.debug('update alerte statut');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.post(ENV.DATA_GATEWAY_URL + 'alerteStatut', params, { headers })
      .toPromise() as Promise<AlerteStatutResponse>;
  }

    /*
    * Update description alerte
    */
  async updateAlerteDescription(token, params): Promise<AlerteStatutResponse> {
    this.logger.debug('update alerte description');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.post(ENV.DATA_GATEWAY_URL + 'alerteDescription', params, { headers })
      .toPromise() as Promise<AlerteStatutResponse>;
  }

    /*
    * Update adresse alerte
    */
  async updateAlerteAdresse(token, params): Promise<AlerteStatutResponse> {
    this.logger.debug('update alerte adresse');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.post(ENV.DATA_GATEWAY_URL + 'alerteAdresse', params, { headers })
      .toPromise() as Promise<AlerteStatutResponse>;
  }

  /**
   * Get List of Values for the status of an alert
   */
  async getTranscoStatutAlerte(token): Promise<TranscoAlerteStatutResponse> {
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.get(ENV.DATA_GATEWAY_URL + 'getTranscoStatutAlerte', { headers })
      .toPromise() as Promise<TranscoAlerteStatutResponse>;
  }

  async getTranscoTypeAlerte(token): Promise<TranscoAlerteTypeResponse> {
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.get(ENV.DATA_GATEWAY_URL + 'getTranscoTypeAlerte', { headers })
      .toPromise() as Promise<TranscoAlerteTypeResponse>;
  }

  async getMapStatutAlerte(token, codeTypeAlerte, codeStatutInitAlerte): Promise<TranscoAlerteStatutResponse> {
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    const request : AlerteMapStatusRequest = {codeTypeAlerte, codeStatutInitAlerte};
    return this.http.post(ENV.DATA_GATEWAY_URL + 'getAlerteStatusMap', request, { headers })
      .toPromise() as Promise<TranscoAlerteStatutResponse>;
  }

  async getTranscoMotifNonTraitable(token, codeTypeAlerte): Promise<TranscoMotifNonTraitableResponse> {
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    const request : AlerteMotifNonTraitableRequest = {codeTypeAlerte};
    return this.http.post(ENV.DATA_GATEWAY_URL + 'transcoMotifNonTraitable', request, { headers })
      .toPromise() as Promise<TranscoMotifNonTraitableResponse>;
  }


  async getLogInterfacage(token): Promise<TranscoAlerteTypeResponse> {
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.get(ENV.DATA_GATEWAY_URL + 'logInterfacageSigmaNotifNotSent', { headers })
      .toPromise() as Promise<TranscoAlerteTypeResponse>;
  }

  async updateLogInterfacage(token, id): Promise<TranscoAlerteTypeResponse> {
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    const request = {id};
    return this.http.post(ENV.DATA_GATEWAY_URL + 'updateStatutNotifSent', request, { headers })
      .toPromise() as Promise<TranscoAlerteTypeResponse>;
  }

  /**
   * add new alert
   */
  async addAlerte(newAlert: AlerteDTO, token) {
    this.logger.debug('add new alert ');
    const headers = await this.netService.buildHeaderWithAccessToken(token);
    return this.http.post(ENV.DATA_GATEWAY_URL + 'alerte', newAlert, { headers })
      .toPromise();
  }

  /**
   * Upload file to bucket storage and resolve with file url
   * @param file file object to upload
   * @param interventionId intervention id
   */
  uploadImage(file, interventionId) {

    const userId = this.authService.getUser().uid;

    return new Promise((resolve, reject) => {
      const folderRef = this.jimiImagesRef.child(userId);
      const imageRef = folderRef.child(this.buildPath(file.name, interventionId));
      const uploadTask = imageRef.put(file);
      uploadTask
        .then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            resolve(downloadURL);
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * This method builds file path for storage
   * @param filename file name
   * @param interventionId intervention id
   */
  private buildPath(filename, interventionId) {
    return interventionId
    + '_'
    + (new Date()).getTime()
    + '_'
    + filename;
  }

  getTranscoAlerteStatut() {
    return this.transcoAlerteStatut;
  }

  setTranscoAlerteStatut(transcoAlerteStatut: TranscoAlerteStatut[]) {
    this.transcoAlerteStatut = transcoAlerteStatut;
  }
}
