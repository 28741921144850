import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _displayEmailLoginForm: boolean;
  get displayEmailLoginForm(): boolean {
    return this._displayEmailLoginForm;
  }
  set displayEmailLoginForm(val) {
    this._displayEmailLoginForm = val;
    this._displayEmailLoginFormSource.next(val);
  }

  _displayEmailLoginFormSource: Subject<boolean> = new Subject();
  get displayEmailLoginFormSource(): Subject<boolean> {
    return this._displayEmailLoginFormSource;
  }
  set displayEmailLoginFormSource(src: Subject<boolean>) {
    this.displayEmailLoginFormSource = src;
  }

  constructor(private logger: NGXLogger) {
    this.logger.log('Hello DataProvider Provider');
  }

}
