import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'keepHtml'
})
export class EscapeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  /**
   * Takes a value and makes it lowercase.
   */
  transform(content) {
    return content ? this.sanitizer.bypassSecurityTrustHtml(content) : '';
  }

}
