import { DatePipe, Location } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Events, ModalController, NavController, Platform } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { CommonAlerteAndInterventionService } from 'src/app/services/common-alerte-and-intervention/common-alerte-and-intervention.service';
import { NavExtrasService } from 'src/app/services/nav-extra/nav-extras.service';
import { v4 as uuid } from 'uuid';
import { InformationLineOptions } from '../../../app/components/information-line/model/informationLineOptions';
import { InterventionResumeData } from '../../../app/components/intervention-resume/model/interventionResumeData';
import { SearchBarOptions } from '../../../app/components/search-bar/model/searchBarOptions';
import { AlertService } from '../../../app/services/alert/alert.service';
import { DatabaseService } from '../../../app/services/database/database.service';
import { MapService } from '../../../app/services/map/map.service';
import { LocationData } from '../../../app/services/map/model/locationData';
import { AlerteAdresseRequest } from '../../../app/services/rest-alerte/model/alerteAdresseRequest';
import { AlerteDescriptionRequest } from '../../../app/services/rest-alerte/model/alerteDescriptionRequest';
import { AlerteDetailData } from '../../../app/services/rest-alerte/model/alerteDetailData';
import { AlerteMediaData } from '../../../app/services/rest-alerte/model/alerteMediaData';
import { MediaData } from '../../../app/services/rest-alerte/model/mediaData';
import { RestAlerteService } from '../../../app/services/rest-alerte/rest-alerte.service';
import { RestCommonService } from '../../../app/services/rest-common/rest-common.service';
import { RestCommuneService } from '../../../app/services/rest-commune/rest-commune.service';
import { CommonData } from '../../../app/services/rest/model/commonData';
import { ToastService } from '../../../app/services/toast/toast.service';
import { UtilsService } from '../../../app/services/utils/utils.service';
import { APP_CONFIG } from '../../../config/app.config';
import { ENUM_ALERTE_CODE_MODE, ENUM_FILTER_ALERT_STATUS_CODE } from '../../../config/enum.config';
import { ENV } from '../../../config/env.config';
import { LABELS } from '../../../config/labels.config';
import { PATHS_CONFIG } from '../../../config/paths.config';
import { StorageService } from '../../services/storage/storage.service';
import { AlerteDescriptionPage } from '../alerte-description/alerte-description.page';

@Component({
  selector: 'app-alerte-detail',
  templateUrl: './alerte-detail.page.html',
  styleUrls: ['./alerte-detail.page.scss'],
})
export class AlerteDetailPage implements OnInit, OnDestroy {

  informationslineOptions: InformationLineOptions[];

  alerteMediaData: AlerteMediaData;
  alerteData: AlerteDetailData;
  mediaData: MediaData[];
  creatorAlerteIsInternal: boolean;
  interventionResumeData: InterventionResumeData = new InterventionResumeData();
  interventionCommonData: CommonData = new CommonData();
  lengthSlides = 1;
  resumeDataLoaded: Promise<boolean>;
  cameFromNotif: boolean;
  cameFromNotifPage: boolean;
  isAlerteOrpheline: boolean;
  alerteLocationData: LocationData;
  isCreatorNameExist: boolean = false;

  showSearchBar = false;
  showSearchView = false;
  inputLocation;
  currentStatus;
  idDemandeExterne;

  addressBefore:string;

  showFilterIndicator: boolean;

  // Options avec les callbacks pour le searchbar
  searchBarOptions: SearchBarOptions = {
    inputDomId: uuid(),
    onPlaceChangedCb: place => {
      this.callOnPlaceChanged(place);
    },
    toggleSearchView: show => {
      this.logger.debug('AlertDetailPage#SearchOptions Call to toggleSearchView()');
      this.cssClassNavBar = 'navbar';
      this.showSearchView = show;
      this.showSearchBar = !this.showSearchBar;     
    },
    cssClass: 'ic-search-view-alerte',
    placeholder: LABELS.searchbar_placeholder_location_adresse,
    showGeoloc: true,
    isStandalone: true,
    eventToUpdateInputLocationForSearchView: 'UPDATE_INPUT',
  };
  cssClassNavBar = 'navbar';
  isChangeAdresseOpen;
  isBtnBack;
  idNotif;
  defaultHref = PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.NOTIFICATION_PATH;
  hardwareBtnSubscription;
  TAG:string= 'AlerteDetailPage';

  constructor(
    public navCtrl: NavController,
    public route: ActivatedRoute,
    public restAlerteService: RestAlerteService,
    public restService: RestCommonService,
    private datePipe: DatePipe, public modalCtrl: ModalController,
    private toastService: ToastService,
    private databaseService: DatabaseService,
    private alertService: AlertService,
    private zone: NgZone,
    private logger: NGXLogger,
    private events: Events,
    private mapService: MapService,
    private restCommuneService: RestCommuneService,
    private utilService: UtilsService,
    private router: Router,
    private navExtras: NavExtrasService,
    private commonService: CommonAlerteAndInterventionService,
    private platform: Platform,
    private modalController: ModalController,
    private location: Location,
    private storageService: StorageService,
  ) {
     
  }

  ionViewWillEnter() {
    this.logger.debug('AlerteDetailPage# ionViewWillEnter()');
  
    if(this.utilService.isAndroid()){
      this.subscribeToHardwareBtn();
    }

    this.utilService.clearTabs(false);

    let id = this.route.snapshot.paramMap.get('idAlerte');
    this.logger.debug("Jimi log : alerte id from Activated route = ", id);
    this.idNotif = this.route.snapshot.paramMap.get('idNotif');

    const navExtras = this.navExtras.getExtras();
    if (navExtras) {
      this.logger.debug("Jimi log : alerte id from navExtras = ", navExtras.idAlerte);
      if(id === null || id === undefined) {
        id = navExtras.idAlerte;
      }
      this.cameFromNotifPage = navExtras.cameFromNotifPage;
      this.idNotif = navExtras.idNotif;
    }  
    this.cameFromNotif = this.cameFromNotifPage ? false : (navExtras && navExtras.cameFromNotif) || (this.idNotif !== undefined);
    this.storageService.getUserToken().then( token => {
      this.restAlerteService.getAlerteDetail({
        'id': id,
      }, token).then(
        async data => {
          this.logger.log('data : ', data);
          if (data !== null && data !== undefined && data.data ) {
            this.setAlerteInfo(data);
            this.setIsAlerteOrpheline();
            this.initInformationsData();
            this.setInterventionResumeData();
            this.handleActionsIfOrphelineAlertExpired();
  
            this.commonService.manageIfWeCameFromAlertLink(this.cameFromNotif, this.idNotif);
            this.commonService.manageIfWeCameFromNotif(this.cameFromNotif, this.cameFromNotifPage, this.idNotif);
            
            
          } else {
            if (data && data.msg === LABELS.alerte_res_non_autorise) {
              const alert = await this.alertService.createAlert('', LABELS.alerte_non_autorise);
              await alert.present().catch(err => this.logger.error(err));
              await alert.onDidDismiss();
            } else {
              this.toastService.presentToast(LABELS.alerte_not_found, APP_CONFIG.TOAST_TYPE_ALERT, {
                duration: 3000,
              });
            }
            this.goToMapPage();
  
          }
        })
        .catch(err => {
          // Gestion de cas quand l'id de l'alerte n'éxiste pas?
          this.toastService.presentToast(LABELS.error_global_http_interceptor, APP_CONFIG.TOAST_TYPE_ALERT, {
            duration: 3000,
          });
          this.logger.error(err);
          this.goToMapPage();
        });

    });
    
  }

  ngOnInit() {
    this.logger.debug('ngOnInit AlerteDetailPage');
    this.events.subscribe(APP_CONFIG.EVENT_CHG_ADR_ALERTE, async () => {
      if(this.isChangeAdresseOpen){
        this.isBtnBack = true;
        this.searchBarOptions.toggleSearchView();
        this.isChangeAdresseOpen = false;
      }
    });
      
  }

    
  async ionViewDidEnter() {
    this.logger.debug('ionViewDidEnter AlerteDetailPage');
    this.logger.debug('AlerteDetailPage ionViewDidEnter() this.cameFromNotif', this.cameFromNotif);
  }

  ionViewWillLeave() {
    this.logger.debug('Call to ionViewWillLeave() AlerteDetailPage');
    this.unsubscribeFromHardwareBtn();
    this.utilService.clearTabs(true);
  }

  ngOnDestroy() {
    this.logger.debug('Call to ngOnDestroy() AlerteDetailPage');
    this.events.unsubscribe(APP_CONFIG.EVENT_CHG_ADR_ALERTE);
  }
  

  // Initialisation des options pour le component information-line
  private initInformationsData() {
    let materialIconRightCreator = ['email'];
    if (this.alerteData.creatorPhone) {
      materialIconRightCreator = ['email', 'phone'];
    }

    this.isCreatorNameExist = this.alerteData.creatorName || this.alerteData.creatorPhone || this.alerteData.creatorEmail ? true : false;

    this.informationslineOptions = [
      {
        materialIconLeft: 'map-marker',
        textArea: this.alerteData.adresse && this.alerteData.adresse.replace(' - ', ' \n '),
        label: LABELS.alert_detail_address,
        cssClass: 'row-adresse',
        hidden: !this.isAlerteOrpheline,
        materialIconRight: this.alerteData.isEditable ? ['pencil'] : undefined,
        clickActionRow: () => {
          this.logger.debug('click adresse alerte detail');
        },
        clickAction: () => {
          this.logger.debug('edit adresse alerte');
          this.cssClassNavBar = 'navbar-adresse';
          this.showSearchBar = false;
          this.showSearchView = !this.showSearchView;
          this.events.publish(this.searchBarOptions.eventToUpdateInputLocationForSearchView, this.alerteData.adresse);
          this.isChangeAdresseOpen = true;
          this.utilService.historyPushThisHrefIfWeb(this.TAG);
          const inputDom = document.getElementById(this.searchBarOptions.inputDomId);
          if (inputDom !== null && inputDom !== undefined) {
            inputDom.focus();
          }
        },
      },
      {
        codeStatut: this.alerteData.codeStatut,
        label: LABELS.alert_detail_status,
        text: this.alerteData.statut,
        subText: this.isNonTraitable() ? this.alerteData.textMotifNonTraitable : '',
        materialIconRight: this.alerteData.isEditable && this.alerteData.isStatutEditable ? ['pencil'] : undefined,
        clickAction: () => {
          this.logger.debug('edit statut alerte');
          this.editAlerteStatut();
        },
        cssClass: 'row-date',
      },
      {
        svgPath: 'assets/custom_svg/date-icon.svg',
        text: this.datePipe.transform(this.alerteData.dateCreation, 'dd/MM/yyyy à HH:mm'),
        label: LABELS.alert_detail_creation_date,
        cssClass: 'row-date',
      },
      {
        materialIconLeft: 'message-reply-text',
        label: LABELS.alert_detail_comment,
        text: this.alerteData.description,
        materialIconRight: this.alerteData.isEditable ? ['pencil'] : undefined,
        clickAction: () => {
          this.logger.debug('edit description alerte');
          this.editAlerteDescription();
        },
        cssClass: 'row-description',
      },
      {
        materialIconLeft: 'account',
        label: LABELS.alert_detail_creator_info,
        isCreatorNameExist: this.isCreatorNameExist,
        text: this.alerteData.creatorName,
        materialIconRight: this.alerteData.isEditable && (this.alerteData.creatorEmail || this.alerteData.creatorPhone) ? materialIconRightCreator : undefined,
        clickAction: icon => {
          if (icon === 'email') {
            this.logger.debug('send email to ' + this.alerteData.creatorEmail);
            this.sendEmail(this.alerteData.creatorEmail, LABELS['alerte_email_objet']);
          } else if (icon === 'phone') {
            this.callPhone(this.alerteData.creatorPhone);
          }

        },
        cssClass: 'row-creator',
        dataRight: {
          phone: this.alerteData.creatorPhone,
        },
      },
    ];
  }

  /**
   * Fonction de modification du statut d'une alerte.
   * Utilisation de Modal Controller
   */
  private async editAlerteStatut() {
    this.router.navigate([
      '.' + PATHS_CONFIG.ALERTE_STATUT_PATH, 
      {
        statutInit: this.alerteData.codeStatut,
        codeTypeAlerte: this.alerteData.codeTypeAlerte,
        typeAlerte: this.alerteData.typeAlerte,
        idMotifInit: this.alerteData.idMotifNt,
        idMotifNt: this.alerteData.idMotifNt,
        idAlerte: this.alerteData.id
      }], { relativeTo : this.route })
      .catch(err => this.logger.error(err));

  }

  private async editAlerteDescription() {
    const alerteDescriptionModal = await this.modalCtrl.create({
       component: AlerteDescriptionPage,
       componentProps: { alertDescription: this.alerteData.description },
       showBackdrop: true,
       backdropDismiss: true,
       cssClass: 'alerte-description-modal' 
    });
    // Gestion du retour sur la page détail après sélection de la description
    await alerteDescriptionModal.present().catch(err => this.logger.error(err));
    const onDidDismiss: any = await alerteDescriptionModal.onDidDismiss();
    if (onDidDismiss) {
      const onDidDismissData = onDidDismiss.data;
      if (onDidDismissData && onDidDismissData.description) {
        this.logger.debug('descriptionAlerte >>> ' + onDidDismissData.description);
        const alerteDescriptionRequest = new AlerteDescriptionRequest();
        alerteDescriptionRequest.description = onDidDismissData.description;
        alerteDescriptionRequest.id = this.alerteData.id;
        this.updateDescription(alerteDescriptionRequest);
      }
    }

  }

  private updateDescription(alerteDescriptionRequest) {
    this.storageService.getUserToken().then( token => {
    this.restAlerteService.updateAlerteDescription(token, alerteDescriptionRequest).then(
      updateAlerteDescriptionData => {
        this.alerteData.description = updateAlerteDescriptionData.data.description;
        this.informationslineOptions[3].text = this.alerteData.description;
        this.toastService.presentToast(LABELS.alerte_success_update, APP_CONFIG.TOAST_TYPE_INFO, {
          duration: 3000,
        });
      }).catch(err => this.logger.error(err));
    });
  }

  /* Fonction d'envoi d'un mail
  *  Params: email du créateur de l'alerte, objet du mail
  * */

  private sendEmail(email: string, objet: string) {
    const url = 'https://mail.google.com/mail/?tf=cm&to=' + email + '&cc&bcc&su=' + objet;
    window.open(url, '_system');
  }

  private callPhone(phone: string) {
    window.location.href = 'tel:' + phone;
  }


  private buildupdateAlerteAction(status, statusAvant) {
    return {
      alerteCreatorEmail: this.alerteData.creatorEmail,
      alerteId: this.alerteData.id,
      // tslint:disable-next-line:no-null-keyword
      interventionId: this.alerteData.idIntervention === undefined ? null : this.alerteData.idIntervention,
      status,
      statusAvant,
    };
  }

  private getResumeData(id) {
    return this.restService.getResumeData({
      'id': id,
    });
  }

  private goToMapPage() {
    this.logger.debug('AlerteDeatilPage goToMapPage() ');
    this.events.publish(APP_CONFIG.EVENT_SELECT_APP_TAB, 0);
    // if (this.navCtrl.parent === null) {
      this.navCtrl.navigateRoot(PATHS_CONFIG.GLOBAL_MENU_PATH).catch(err => this.logger.error(err));
    // }
    // this.navCtrl.popToRoot().catch(err => this.logger.error(err));
  }

  private setInterventionResumeData() {
    this.logger.debug('setInterventionResumeData >>> ' + JSON.stringify(this.route.snapshot.paramMap.get('intervention')));
    const intervention: any = this.route.snapshot.paramMap.get('intervention');
    // verifier si l'on vient d'un push d'un autre page
    if (intervention !== null && intervention != undefined) {
      this.interventionResumeData = intervention;
      this.resumeDataLoaded = Promise.resolve(true);
    } else {
      if (!this.isAlerteOrpheline) {
        const idIntervention = this.alerteData.idIntervention;
        this.getResumeData(idIntervention)
          .then((response: any) => {
            if (!response) {
              this.alertService.presentAlert(LABELS.alerte_message_intervention_not_found_title, LABELS.alerte_message_intervention_not_found_message);
            } else {
              if (response.data) {
                this.zone.run(() => {
                  this.interventionCommonData = response.data;
                  this.buildInfoResumeDataFromCommonResume(response.data);
                  this.resumeDataLoaded = Promise.resolve(true);
                });
              } else {
                this.logger.debug('getResumeData() response value', response);
              }
            }
          })
          .catch(err => {
            this.logger.info('CATCH');
            this.logger.error(err);
          });
      }
    }
  }

  /**
   * This method will check if alert is obsolete.
   * An orphan alert is considered obsolete if its status is 2 or 3 (done or not tratable) and it's last update is 30 days old or more
   * If obsolete, we display alert to user and desactivate edit icons
   */
  private handleActionsIfOrphelineAlertExpired() {
    this.logger.debug('AlerteDetailPage:: Call to handleActionsIfOrphelineAlertExpired()');
    if (this.isAlerteOrphelineObsolete()) {
      this.alertService.presentAlert(LABELS.alerte_message_obsolete_title, LABELS.alerte_message_obsolete_message);
    }
  }

  /**
   * Method used to tell wether an orphan alerte is obsolete or not
   */
  private isAlerteOrphelineObsolete() {
    if (this.isAlerteOrpheline) {
      return (this.alerteData.codeStatut === 2 || this.alerteData.codeStatut === 3) && this.alerteData.isObsolete;
    }
    return false;
  }

  private buildInfoResumeDataFromCommonResume(interventionResume: CommonData) {
    this.interventionResumeData.id = interventionResume.id;
    this.interventionResumeData.libTypeInter = interventionResume.titre;
    this.interventionResumeData.loc = interventionResume.ligne2;
  }

  private setAlerteInfo(data) {
    this.alerteMediaData = data.data;
    this.alerteData = this.alerteMediaData.alerteDetail;
    this.mediaData = this.alerteMediaData.medias;
    this.creatorAlerteIsInternal = this.alerteMediaData.creatorAlerteIsInternal;
    this.lengthSlides = this.mediaData && this.mediaData.length || this.lengthSlides;
    this.addressBefore = this.alerteData.adresse;
  }

  private setIsAlerteOrpheline() {
    this.isAlerteOrpheline = (this.alerteData.codeMode !== ENUM_ALERTE_CODE_MODE.ATTACHED);
  }

  private callOnPlaceChanged(place) {
    this.onPlaceChanged(place).then(() => {
      this.logger.debug('callback after call to onPlaceChanged');
    })
    .catch(err => {
      this.logger.error('error during call to onPlaceChanged()');
    });
  }

  private async onPlaceChanged(place) {
    this.alerteLocationData = place;
    this.logger.log(this.alerteData);
    if (place.address_components) {
      this.alerteLocationData = await this.mapService.getLocationFromPlace(place);
    }
    this.restCommuneService.isCommuneSedif(this.alerteLocationData.codePostal, this.alerteLocationData.country).then(result => {
      // Vérif de la validité de l'adresse
      const adresseValidity = this.alerteLocationData !== undefined && this.alerteLocationData.name !== '' && this.alerteLocationData.vicinity !== '';
      const toastLabels: string[] = [];
      if (!adresseValidity) {
        toastLabels.push(LABELS.alerte_adresse_incomplete);
      }
      if (!result) {
        toastLabels.push(LABELS.map_hors_perimetre_sedif);
        this.toastService.presentToasts(toastLabels, APP_CONFIG.TOAST_TYPE_ALERT, {
          duration: APP_CONFIG.HORS_PERIMETRE_TOAST_DURATION,
        }).catch(err => this.logger.error(err));
      } else {
        if (!adresseValidity) {
          this.toastService.presentToast(LABELS.alerte_adresse_incomplete, APP_CONFIG.TOAST_TYPE_ALERT);
        } else {
          const alerteAdresseRequest = new AlerteAdresseRequest();
          alerteAdresseRequest.adresse = this.alerteLocationData.name + ' - ' + this.alerteLocationData.vicinity;
          alerteAdresseRequest.latitude = this.alerteLocationData.latitude;
          alerteAdresseRequest.longitude = this.alerteLocationData.longitude;
          alerteAdresseRequest.id = this.alerteData.id;
          this.storageService.getUserToken().then( token => {
            this.restAlerteService.updateAlerteAdresse(token, alerteAdresseRequest).then(
              updateAlerteAdresseData => {
                this.events.publish(APP_CONFIG.EVENT_UPDATE_MARKER, this.alerteData.id, updateAlerteAdresseData.data.codeTypeAlerte, updateAlerteAdresseData.data.codeStatut);
                this.informationslineOptions[0].textArea = this.alerteLocationData.name + '\n' + this.alerteLocationData.vicinity;
                this.onUpdateAdresseSuccess(updateAlerteAdresseData);
                this.toastService.presentToast(LABELS.alerte_success_update, APP_CONFIG.TOAST_TYPE_INFO, {
                  duration: 3000,
                });
              }
            ).catch(err => this.logger.error(err));
          });
        }
      }
    }).catch(err => this.logger.error(err));
  }

  private onUpdateAdresseSuccess(response) {

    // mise à jour de l'adresse dans l'objet alerteData
    this.alerteData.adresse = response.data && response.data.adresse;

    // Envoi d'un mail au responsable SET de la commune
    this.storageService.getUserToken().then( token => {
    this.restCommuneService.getCommuneDataWithParams(token, {
      'codePostal': this.alerteLocationData.codePostal,
      'commune': this.alerteLocationData.vicinity,
    }).then(
      data => {
        if (data) {
          const alerteDetail = response.data;
          const emailResponsableSET = data.data.service.mailingList.mail;
          const email = emailResponsableSET;
          const emailSupport = ENV.EMAIL_SUPPORT;

          this.databaseService.writeCreateAlerte(alerteDetail, email, 1, emailSupport, this.addressBefore)
          .then(()=> {this.addressBefore = this.alerteData.adresse;})
          .catch(err => { this.logger.debug(err); });
        }
      })
      .catch(err => {
        this.logger.error(err);
      });
    });
  }

  private isNonTraitable() {
    return this.alerteData.codeStatut == ENUM_FILTER_ALERT_STATUS_CODE.NON_TRAITABLE;
  }

  private subscribeToHardwareBtn(){

    this.unsubscribeFromHardwareBtn();
    this.logger.debug('AlerteDetail subscribeToHardwareBtn');
    this.hardwareBtnSubscription = this.platform.backButton.subscribeWithPriority(102, async () => {
      this.logger.debug('AlerteDetail subscribeToHardwareBtn event fired');
      if (await this.modalController.getTop()){
        this.logger.debug('AlerteDetail Closing modal');        
        this.modalController.dismiss(); 
      }else if(this.showSearchView){
        this.logger.debug('AlerteDetail toogle search view'); 
        this.searchBarOptions.toggleSearchView(false);
      }else {
        this.logger.debug('AlerteDetail location.back()');
        this.location.back();
      }
    })
  }
  
  public unsubscribeFromHardwareBtn(){
    this.logger.debug('AlerteDetail unsubscribeFromHardwareBtn');
    if (this.hardwareBtnSubscription){
      this.hardwareBtnSubscription.unsubscribe();
    }
  }

}
