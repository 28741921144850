import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { PAGES_CONFIG } from 'src/config/pages.config';
import { PATHS_CONFIG } from 'src/config/paths.config';
import { NavExtrasService } from '../nav-extra/nav-extras.service';
import { NotificationUpdateRequest } from '../rest-notif/model/NotificationUpdateRequest';
import { RestNotifService } from '../rest-notif/rest-notif.service';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CommonAlerteAndInterventionService {

  constructor(
    private logger: NGXLogger,
    private utilService: UtilsService,
    private route: ActivatedRoute,
    private restNotifService: RestNotifService,
    private navExtras: NavExtrasService,
    private storageService: StorageService
  ) { }

  async manageIfWeCameFromAlertLink(cameFromNotif, idNotif) {
    const cameFromAlertLink = cameFromNotif || this.cameFromMail(idNotif);
    this.logger.debug('Came from alert link ' + cameFromAlertLink);
    if (cameFromAlertLink) {
      this.utilService.setActivePage(PAGES_CONFIG.ALERTE_DETAIL_PAGE);
      this.changeHistoryStatus();
    }
    this.utilService.setCameFromAlertLink(cameFromAlertLink);
  }

  manageIfWeCameFromNotif(cameFromNotif, cameFromNotifPage, idNotif) {
    if ((cameFromNotif || cameFromNotifPage) && !this.cameFromMail(idNotif)) {
      this.logger.debug('We came from Notification!');
      this.logger.debug('id notif', idNotif);
      if (!this.isNotificationAlreadyRead() && idNotif) {
        this.updateNotificationStatusToRead(idNotif).then(
          async () => { 
            this.restNotifService.getNotificationCount('manageIfWeCameFromNotif CommonAlerteAndInterventionService');
          }
        ).catch(err => this.logger.error(err));
      }
    }
  }

  private cameFromMail(idNotif) {
    return idNotif === '0';
  }

  private changeHistoryStatus() {
    this.logger.debug('Call to changeHistoryStatus()');
    // Ces actions modifient la stack history afin de gérer le "back" et afficher le contenu approprié selon les cas.
    const url = window.location.pathname;
    window.history.replaceState('','', PATHS_CONFIG.GLOBAL_MENU_PATH  + PATHS_CONFIG.TABS_PATH +  PATHS_CONFIG.MAP_PATH);
    window.history.pushState('','', PATHS_CONFIG.GLOBAL_MENU_PATH + PATHS_CONFIG.TABS_PATH + PATHS_CONFIG.NOTIFICATION_PATH);
    window.history.pushState('','', url);
  }

  private isNotificationAlreadyRead() {
    const navExtras = this.navExtras.getExtras();
    const codeStatutNotif = this.route.snapshot.paramMap.get('codeStatutNotif') || navExtras.codeStatutNotif;
    return codeStatutNotif === 2;
  }

  private updateNotificationStatusToRead(idNotif) {
    // Mise à jour du statut de la notif si l'on a cliqué sur le contenu d'une notif
    const notifUpdateRequest = new NotificationUpdateRequest();
    notifUpdateRequest.codeStatut = 2;
    notifUpdateRequest.id = idNotif;
    this.logger.debug('Updating notification statut >>> ' + JSON.stringify(notifUpdateRequest));
    return this.storageService.getUserToken().then(token => {
      return this.restNotifService.updateStatutNotif(token, notifUpdateRequest)
      .then(res => {
        this.restNotifService.getNotificationsOfThisUser(token);
        return Promise.resolve(res);
      });
    });
  }

}
