import { Component, OnInit, NgZone, Input } from '@angular/core';
import { APP_CONFIG } from '../../../config/app.config';
import { LABELS } from '../../../config/labels.config';
import { MenuController, Events, Platform } from '@ionic/angular';
import { AlertService } from '../../../app/services/alert/alert.service';
import { UtilsService } from '../../../app/services/utils/utils.service';
import { NGXLogger } from 'ngx-logger';
import { MapService } from '../../../app/services/map/map.service';
import { SearchBarService } from '../../../app/services/search-bar/search-bar.service';
import { SearchBarOptions } from './model/searchBarOptions';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PATHS_CONFIG } from '../../../config/paths.config';


declare var google;

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  private _inputLocation;

  @Input() options: SearchBarOptions;
  @Input() showFilterIndicator: boolean;
  // PlaceholderInputLocation:string = LABELS.searchbar_placeholder_location_without_focus;
  // Booleans qui informe dans quel view je suis
  // bar de recherche en etant dans le map
  @Input() showSearchBar: boolean;
  // La view recherche en tant que page
  @Input() showSearchView: boolean;

  inputLocationForSearchView: string;

  autocompleteService = new google.maps.places.AutocompleteService();
  // recuperer les places depuis google
  placesService: any;
  // Array pour stocker les resultats des lieux des predictions google api
  predictions: any = [];
  // time out pour declancher la requete vers l'autocomplete
  inputViewKeyDownTimeout: any;
  // Span pour mettre en grqs
  matchSpanDomPrefix = '<span class="ac-matched">';
  matchSpanDomSuffix = '</span>';

  set inputLocation(value) {
    if (this.options.isStandalone) {
      this._inputLocation = value;
    } else {
      this.searchBarService.inputLocation = value;
    }
  }

  get inputLocation() {
    return this.options.isStandalone ? this._inputLocation : this.searchBarService.inputLocation;
  }

  TAG: string = 'SearchBarComponent';
  constructor(private menuCtrl: MenuController,
              private zone: NgZone,
              private utilsService: UtilsService,
              private alertService: AlertService,
              private logger: NGXLogger,
              private mapService: MapService,
              public searchBarService: SearchBarService,
              private route: ActivatedRoute,
              private events: Events,
              private router: Router,
              private platform: Platform,
              private location: Location) {
    this.logger.debug('Hello SearchBarComponent Component');
  }

  ngOnInit() {
    this.logger.debug('SearchBarComponent Call to ngOnInit()');
    if (this.options && this.options.eventToUpdateInputLocationForSearchView) {
      this.events.subscribe(this.options.eventToUpdateInputLocationForSearchView, newValue => {
        this.inputLocationForSearchView = newValue;
        // call to this method needs to wait for the web app view refresh
        setTimeout(()=> {
          this.updateSearchBarViewServiceValue();
        }, 50);
      });
    }
    this.events.subscribe(APP_CONFIG.EVENT_CHG_ADR_BACK, () => {
      this.logger.log('SearchBar EVENT_CHG_ADR_BACK fired');
      this.runToggleDefault();
    });
    this.events.subscribe(APP_CONFIG.EVENT_TOGGLE_SEARCH_VIEW, () => {
      this.logger.log('SearchBar EVENT_TOGGLE_SEARCH_VIEW fired');
      this.toggleSearchView();
    });
  }

  ngOnDestroy() {
    this.logger.log('Searchbar onDestroy')
    this.events.unsubscribe(this.options.eventToUpdateInputLocationForSearchView);
    this.events.unsubscribe(APP_CONFIG.EVENT_CHG_ADR_BACK);
    this.events.unsubscribe(APP_CONFIG.EVENT_TOGGLE_SEARCH_VIEW);
  }

  // Function pour gerer le click
  onInputViewKeyDown(event?) {

    const isEnterKey = (event && event.key === 'Enter');

    if (isEnterKey && this.predictions && this.predictions.length > 0) {
      this.inputLocationForSearchView = this.predictions[0].data.description;
      this.onAutocompleteItemClick(this.predictions[0].data.place_id, this.inputLocationForSearchView);
      return;
    }

    clearTimeout(this.inputViewKeyDownTimeout);

    this.inputViewKeyDownTimeout = setTimeout(() => {

      if (this.inputLocationForSearchView && this.inputLocationForSearchView.length > 0) {
        this.autocompleteService.getPlacePredictions({ input: this.inputLocationForSearchView, componentRestrictions: { country: 'fr' } }, (predictions, status) => {
          this.updateSuggestions(predictions, status, isEnterKey);
        });
      } else {
        this.predictions = [];
      }

    }, 500);

  }

  onInputLocationFocus() {
    this.logger.debug('Call to onInputLocationFocus()');
    this.zone.run(() => {
      this.toggleSearchView();
      this.utilsService.historyPushThisHrefIfWeb(this.TAG);
      // this.placeholderInputLocation = LABELS.searchbar_placeholder_location_with_focus;
      this.options.toggleSearchView(true);
      setTimeout(() => {
        this.focusInput();
        // this.buildAutocompleteInput();
      }, 200);
    });
  }

  onInputLocationBlur() {
    // this.placeholderInputLocation = LABELS.searchbar_placeholder_location_without_focus;
    this.options.toggleSearchView();
  }

  // On l'appelle quand un noveau lieux a été seleccioné
  onInputLocationChange(place) {
    // this.placeholderInputLocation = LABELS.searchbar_placeholder_location_without_focus;
    this.inputLocation = this.inputLocationForSearchView;
    this.back();
    this.options.onPlaceChangedCb(place);
  }

  async menuToggle() {
    this.logger.debug('SearchBarComponent menuToggle()');
    await this.menuCtrl.toggle();
    // this.utilsService.historyPushMenuPathIfWeb(this.TAG);
    this.utilsService.historyPushThisHrefIfWeb(this.TAG);
  }

  back() {
    // this.clearSearchViewInput();
    this.runToggleDefault()
    .then(() => {
      if (!this.utilsService.isNativeApp()) {
        this.location.back();
      }
    });
  }

  fillInput(event, text) {
    event.preventDefault();
    event.stopPropagation();
    this.focusInput();
    this.inputLocationForSearchView = text;
    this.onInputViewKeyDown();
  }

  onAutocompleteItemClick(placeId, description) {

    this.inputLocationForSearchView = description;

    // On recupere le map pour pouvoir instancier le place service
    if (!this.placesService) {
      this.placesService = new google.maps.places.PlacesService(this.utilsService.getMap());
    }

    this.placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.onInputLocationChange(place);
      } else {
        const errMsg = this.utilsService.getPlaceDetailsRequestError(status);
        this.alertService.presentAlert(LABELS.error_place_details_title, errMsg);
      }
    });

  }

  clearSearchViewInput(forSearchView: boolean) {
    this.logger.debug('Call to clearSearchViewInput()');
    if (forSearchView) {
      this.inputLocationForSearchView = '';
    } else {
      this.inputLocation = '';
    }
    this.predictions = [];
    this.focusInput();
  }

  private runToggleDefault() {
    return new Promise(resolve => {
      setTimeout(() => {
        this.options.toggleSearchView();
        this.toggleSearchView();
        resolve();
      }, 200);
    });
  }

  
  // Function pour gestion des resultats de la prediction
  private updateSuggestions(predictions, status, showAlertOnError) {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      const errMsg = this.utilsService.getPlaceDetailsRequestError(status);
      this.logger.debug('SearchBar >>> updateSuggestions() >>> ' + errMsg);
      if (showAlertOnError) {
        this.alertService.presentAlert(LABELS.error_place_details_title, errMsg);
      }
      return;
    }

    this.predictions = [];

    this.zone.run(() => {
      for (let ii = 0, jj = predictions.length; ii < jj; ii++) {
        const predictionItem = this.buildPredictionItem(predictions[ii]);
        const prediction = {
          dom: predictionItem,
          data: predictions[ii],
        };
        this.predictions.push(prediction);
      }
    });

  }

  private focusInput() {
    const inputDom = document.getElementById(this.options.inputDomId);
    if (inputDom !== null && inputDom !== undefined) {
      inputDom.focus();
    }
  }

  private buildPredictionItem(predictionData) {
    const description = predictionData.description;
    let resDescription = '';
    if (predictionData.matched_substrings && predictionData.matched_substrings.length > 0) {

      const offsets = {};

      // build offsets object which will have offset as key and length as value
      for (let ii = 0, jj = predictionData.matched_substrings.length; ii < jj; ii++) {
        const acMatchedSubstringData = predictionData.matched_substrings[ii];
        offsets[acMatchedSubstringData.offset] = acMatchedSubstringData.length;
      }

      // loop on description character by character and build result description string encapsulating with span the matched characters
      for (let mm = 0, pp = description.length; mm < pp; mm++) {
        const char = description[mm];
        const len = offsets[mm];
        if (len) {
          resDescription += this.matchSpanDomPrefix + description.substr(mm, len) + this.matchSpanDomSuffix;
          mm += len - 1;
          continue;
        } else {
          resDescription += char;
        }
      }

    } else {
      resDescription = description;
    }

    return resDescription ;
  }

  private toggleSearchView() {
    this.showSearchBar = !this.showSearchBar;
    this.showSearchView = !this.showSearchView;
    if (this.showSearchView) {
      this.inputLocationForSearchView = this.inputLocation;
    }

    this.updateSearchBarViewServiceValue();
  }

  filterButtonClick() {
    this.logger.log('filter button click');
    if (this.options.filterButtonCb) {
      this.options.filterButtonCb() ;
    } else {
      this.goToFilterPage();
    }
  }

  private goToFilterPage() {
    this.router.navigate(['.' + PATHS_CONFIG.FILTER_PATH, { 'isCameFromList': true }], {relativeTo: this.route})
    .catch(err => this.logger.error(err));
  }

  geoloc() {
    this.mapService.getUserLocation().then(position => {
      const userPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      this.mapService.getLocationFromPosition(userPosition).then(data => {
        if (data) {
          this.onInputLocationChange(data);
        }
      }).catch(err => this.logger.error(err));
    }).catch(err => this.logger.error(err));
  }

  private updateSearchBarViewServiceValue() {
    this.logger.debug('SearchBarComponent#updateSearchBarViewServiceValue()');
    // update boolean value in searchBarService used to get the boolean value from within other Components
    this.searchBarService.showSearchView = this.showSearchView;
    this.logger.debug('this.searchBarService.showSearchView = ' + this.searchBarService.showSearchView);
  }


}
