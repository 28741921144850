import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { RoutingService } from '../routing/routing.service';
import { APP_CONFIG } from 'src/config/app.config';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class InitAuthGuardService implements CanActivate, CanDeactivate<any>  {

  constructor(
    private routingService: RoutingService,
    private logger: NGXLogger
  ) { }

  async canActivate() {
    this.logger.debug('InitAuthGuardService canActivate()');

    const localStorageValue = localStorage.getItem(APP_CONFIG.APP_RAN_AT_LEAST_ONCE_STORAGE_KEY);

    this.logger.debug('localStorage.getItem(APP_CONFIG.APP_RAN_AT_LEAST_ONCE_STORAGE_KEY)', localStorage.getItem(APP_CONFIG.APP_RAN_AT_LEAST_ONCE_STORAGE_KEY))
    
    if (localStorageValue !== null){
      return true;
    } else {
      localStorage.setItem(APP_CONFIG.APP_RAN_AT_LEAST_ONCE_STORAGE_KEY, "true");
      this.routingService.globalGoToTutoPage();
      return false;
    }
  }

  async canDeactivate() {
    return true;
  }
}
