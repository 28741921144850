export class AlerteResumeData {
    id: number;
    idIntervention: string;
    typeAlerte: string;
    codeStatut: number;
    statut: string;
    date_creation: number;
    description: string;
    adresse: string;
}
