import { Component, OnInit, Input } from '@angular/core';
import { InterventionResumeData } from '../intervention-resume/model/interventionResumeData';
import { InterventionResumeBlockOptions } from '../intervention-resume/model/interventionResumeBlockOptions';
import { InformationLineOptions } from '../information-line/model/informationLineOptions';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-intervention-resume-block',
  templateUrl: './intervention-resume-block.component.html',
  styleUrls: ['./intervention-resume-block.component.scss'],
})
export class InterventionResumeBlockComponent implements OnInit {


  @Input() interventionData: InterventionResumeData;
  @Input() options: InterventionResumeBlockOptions;
  interInformationslineOptions: InformationLineOptions[];

  constructor(private logger: NGXLogger) {
    this.logger.debug('Hello InterventionResumeBlockComponent Component');
  }

  ngOnInit() {
    this.initIntervention();
  }

  /**
   * Initialisation des options pour le component information-line
   */
  private initIntervention() {
    if (this.interventionData) {
      this.interInformationslineOptions = [
        {
          materialIconLeft: 'folder',
          text: 'N° ' + this.interventionData.id,
          cssClass: 'row-creator-affaire',
        },
      ];
      this.interInformationslineOptions.push(this.getLocInformationLine());
    }
  }

  /**
   * Récupération de la ligne avec les informations liées à la localisation
   */
  private getLocInformationLine() {
    return {
      materialIconLeft: 'map-marker',
      text: this.interventionData.loc,
      cssClass: 'row-creator-map',
    };
  }


}
